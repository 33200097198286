import React from 'react';
import OraLogo from '../../Global/OraLogo';

const MSFooter: React.FC = () => {
  return (
    <footer className="ms-footer">
      <div className="footer_main">
        <div className="footer_main_left">
          <OraLogo type="link" url={'https://www.oraai.com/'} />
          <div>
            <p>
              Welcome to AI-guided Step prep.
              <br />
              Welcome to peace of mind.
            </p>
          </div>
        </div>
        <div className="footer_main_right">
          <div className="section-label">About</div>
          <div className="footer_main_right_links">
            <div>
              <a href="https://www.oraai.com/terms">Terms of Service</a>
            </div>
            <div>
              <a href="https://www.oraai.com/privacy">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_sub">
        Synaptiq Learning Inc. | &copy; 2024 | All Rights Reserved
      </div>
    </footer>
  );
};

export default MSFooter;
