import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FlashcardResponse,
  VignetteResponses,
} from '../../types/DiagnosticData';

interface DiagnosticDataPayload {
  flashcardResponses: FlashcardResponse[];
  vignetteResponses: VignetteResponses;
}

const initialState: DiagnosticDataPayload = {
  flashcardResponses: [],
  vignetteResponses: {},
};

export const diagnosticDataSlice = createSlice({
  name: 'diagnosticData',
  initialState,
  reducers: {
    setDiagnosticData: (
      _state,
      action: PayloadAction<DiagnosticDataPayload>
    ) => {
      return action.payload;
    },
  },
});

export const { setDiagnosticData } = diagnosticDataSlice.actions;

export default diagnosticDataSlice.reducer;
