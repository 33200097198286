export const fadeInOutFlashLoader = (
  flashLoaderRef: React.RefObject<HTMLDivElement>
) => {
  const flashLoader = flashLoaderRef.current;
  if (flashLoader) {
    flashLoader.style.transition = 'opacity 0.25s';
    flashLoader.style.opacity = '1';
    setTimeout(() => {
      setTimeout(() => {
        flashLoader.style.opacity = '0';
      }, 1000);
    }, 250);
  }
};

export const fadeInFlashLoader = (
  flashLoaderRef: React.RefObject<HTMLDivElement>
) => {
  const flashLoader = flashLoaderRef.current;
  if (flashLoader) {
    flashLoader.style.transition = 'opacity 0.25s';
    flashLoader.style.opacity = '1';
  }
};

export const fadeOutFlashLoader = (
  flashLoaderRef: React.RefObject<HTMLDivElement>
) => {
  const flashLoader = flashLoaderRef.current;
  if (flashLoader) {
    flashLoader.style.transition = 'opacity 0.25s';
    flashLoader.style.opacity = '0';
  }
};
