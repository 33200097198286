import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Global/Header';
import Start from '../../components/Home/Start';
import { HeaderType, SecondaryScreen } from '../../types/Header';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTodaySession,
  setTodaySession,
} from '../../slices/todaySession/todaySessionSlice';
import { clearBlocks } from '../../slices/blocks/blocksSlice';
import { clearPastFlashcards } from '../../slices/pastFlashcards/pastFlashcardsSlice';
import { ArrowRight, BarChart2, Book, Check } from 'react-feather';
import { RootState } from '../../store/store';
import SettingsMenu from '../SettingsMenu';
import { useHotkeys } from 'react-hotkeys-hook';
import { hapticsImpactLight } from '../../utils/haptics';
import CircularProgress from '@mui/material/CircularProgress';
import { StartType } from '../../types/Study';
import StudySettings from '../../components/Global/StudySettings';
import { useRefreshStudySessionAndContent } from '../../utils/refreshStudySession';
import Account from '../Account';
import IntercomButton from '../../components/Global/IntercomButton';
// import Flame from '../../assets/icons/flame.svg';
import { interpolateGradient } from '../../utils/dynamicBackground';
import { updateCurrentUserInfo } from '../../services/auth';
import { setUserInfo } from '../../slices/auth/authSlice';
import useIdle from '../../utils/useIdle';
import ThreeOrb from '../../components/Home/ThreeOrb';
import TopicFilters from '../TopicFilters';
import { updateTopicTree } from '../../services/topicCustomization';
import { TopicTreeNode } from '../../types/TopicCustomization';
import Roadmap from '../Roadmap';
import ExamCompletion from '../ExamCompletion';
import moment, { Moment } from 'moment';
import { updateStudySession } from '../../services/studySession';
import { AlarmClock } from '../../assets/svgs/AlarmClock';
import { addOrUpdateUserExam } from '../../services/users';
import { enqueueSnackbar } from 'notistack';
import { posthog } from 'posthog-js';

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [showSecondaryScreen, setShowSecondaryScreen] = useState(false);
  const [secondaryScreenType, setSecondaryScreenType] =
    useState<SecondaryScreen | null>(SecondaryScreen.StudySettings);
  const [transitionStarted, setTransitionStarted] = useState(false);
  const [isSwitchingExam, setIsSwitchingExam] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [isNoContent, setIsNoContent] = useState(false);
  const [screenSaverDisplay, setScreenSaverDisplay] = useState('none');
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [themeTransitionValue, setThemeTransitionValue] = useState(
    currentUser?.darkMode ? 1 : 0
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isLoadingStudySessionAndContent,
    isLoadedStudySessionAndContent,
    setIsLoadingStudySessionAndContent,
    setIsLoadedStudySessionAndContent,
    refreshStudySessionAndContent,
  } = useRefreshStudySessionAndContent();

  const [activeTopicsChanged, setActiveTopicsChanged] = useState(false);
  const flashLoaderRef = useRef<HTMLDivElement>(null);

  const { isIdle } = useIdle(
    60000,
    isLoadedStudySessionAndContent && !showSecondaryScreen
  );

  useHotkeys('t', () => updateTheme(!currentUser?.darkMode), { keyup: true });

  useEffect(() => {
    // Users shouldn't be able to 'undo' flashcards from a previous session
    dispatch(clearPastFlashcards());

    // Clear todaySession if it's not for today
    // TODO: Factor in timezone / offset (?)
    if (todaySession && todaySession.date && !isToday(todaySession.date)) {
      dispatch(clearTodaySession());
      dispatch(clearBlocks());
    }

    // loadStreak();

    if (currentUser?.isOnboarded === false) {
      navigate('/onboarding');
    } else {
      // Loads today's study session and content (flashcards, blocks, vignettes)
      refreshStudySessionAndContent();
    }

    const params = new URLSearchParams(window.location.search);
    if (params.get('delete-request') === 'true') {
      showAccountSettings();
    }
  }, []);

  // Show exam confirmation screen
  useEffect(() => {
    if (primaryExam?.examDate) {
      const isPast = moment(primaryExam?.examDate).isBefore(moment(), 'day');
      if (
        isPast &&
        !showSecondaryScreen &&
        !primaryExam.ignoredExamDate &&
        !primaryExam.hasCompletedRealExam
      ) {
        setShowSecondaryScreen(true);
        setSecondaryScreenType(SecondaryScreen.ExamCompletion);
      }
    }
  }, [primaryExam, showSecondaryScreen]);

  useEffect(() => {
    if (isIdle) {
      setScreenSaverDisplay('block');
    } else {
      setTimeout(() => {
        setScreenSaverDisplay('none');
      }, 1000);
    }
  }, [isIdle]);

  useEffect(() => {
    let value = themeTransitionValue;
    let interval: NodeJS.Timeout;
    if (!isLoadedStudySessionAndContent) return;
    if (currentUser?.darkMode) {
      value = 0;
      interval = setInterval(() => {
        value += 0.01;
        if (value >= 1) {
          value = 1;
          clearInterval(interval);
        }
        setThemeTransitionValue(value);
      }, 1);
    } else {
      value = 1;
      interval = setInterval(() => {
        value -= 0.01;
        if (value <= 0) {
          value = 0;
          clearInterval(interval);
        }
        setThemeTransitionValue(value);
      }, 1);
    }

    return () => clearInterval(interval);
  }, [currentUser?.darkMode]);

  useEffect(() => {
    setIsNoContent(false); // Reset isNoContent
    if (todaySession && todaySession.id && primaryExam) {
      if (todaySession.isComplete) return;
      console.log('Checking if No Content Available for Today');
      if (primaryExam.isUsingFlashcards && !primaryExam.isUsingQbank) {
        setIsNoContent(todaySession.flashcardCount === 0);
      }
      if (primaryExam.isUsingQbank && !primaryExam.isUsingFlashcards) {
        setIsNoContent(todaySession.vignetteCount === 0);
      }
      if (primaryExam.isUsingFlashcards && primaryExam.isUsingQbank) {
        setIsNoContent(
          todaySession.flashcardCount === 0 && todaySession.vignetteCount === 0
        );
      }
    }
  }, [todaySession, primaryExam]);

  const isToday = (dateString: string) => {
    const eventDate = new Date(dateString);
    const today = new Date();

    return (
      eventDate.getUTCFullYear() === today.getUTCFullYear() &&
      eventDate.getUTCMonth() === today.getUTCMonth() &&
      eventDate.getUTCDate() === today.getUTCDate()
    );
  };

  const initiateTransitionToStudy = () => {
    if (!isLoadedStudySessionAndContent) return;
    const examSelector = document.querySelector(
      '.exam-selector'
    ) as HTMLElement;
    if (examSelector) {
      examSelector.style.display = 'none';
    }
    posthog?.capture('study_session_start');
    setTransitionStarted(true);
    setTimeout(() => {
      navigate('/study');
    }, 2000);
  };

  const updateTheme = async (darkMode: boolean) => {
    const updateUser = await updateCurrentUserInfo({ darkMode: darkMode });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const toggleSecondaryScreen = () => {
    if (showSecondaryScreen) {
      setShowSecondaryScreen(false);
    } else {
      setShowSecondaryScreen(true);
    }
  };

  const showAccountSettings = () => {
    setSecondaryScreenType(SecondaryScreen.Account);
    setShowSecondaryScreen(true);
  };

  const showRoadmap = () => {
    setSecondaryScreenType(SecondaryScreen.Roadmap);
    setShowSecondaryScreen(true);
  };

  const closeSecondaryScreen = async () => {
    setShowSecondaryScreen(false);
    setSecondaryScreenType(null);
  };

  const handleSaveTopicUpdates = async (
    topicFilters: TopicTreeNode[],
    regenerate: boolean
  ) => {
    closeSecondaryScreen();
    if (activeTopicsChanged) {
      setIsLoadingStudySessionAndContent(true);
      setIsLoadedStudySessionAndContent(false);
      const flatTopics = flattenTopicTree(topicFilters);
      await updateTopicTree(flatTopics, regenerate);
      await refreshStudySessionAndContent();
      setIsLoadingStudySessionAndContent(false);
      setIsLoadedStudySessionAndContent(true);
      setActiveTopicsChanged(false);
    }
  };

  const flattenTopicTree = (topicFilters: TopicTreeNode[]) => {
    // @ts-expect-error ignore
    let flatTopics = [];
    topicFilters.forEach((topic) => {
      const topicObj = {
        id: topic.id,
        name: topic.name,
        path: topic.path,
        level: topic.level,
        active: topic.active,
      };
      flatTopics.push(topicObj);
      if (topic.children) {
        // @ts-expect-error ignore
        flatTopics = flatTopics.concat(flattenTopicTree(topic.children));
      }
    });
    // @ts-expect-error ignore
    return flatTopics;
  };

  const handleDateChangeOrConfirmation = async (
    examDate: Moment | null,
    ignored: boolean
  ) => {
    if (!currentUser || !primaryExam) return;

    // Refresh study session and content
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);

    const response = await addOrUpdateUserExam(
      currentUser.id,
      primaryExam.id,
      examDate ? examDate.toISOString() : null,
      primaryExam.selectedFlashcardCount,
      primaryExam.selectedVignetteCount,
      primaryExam.isUsingFlashcards,
      primaryExam.isUsingQbank,
      false, // Has Not Completed Real Exam (In No Path)
      primaryExam.realExamScore || null,
      ignored,
      true
    );
    const updatedUser = { ...currentUser };
    updatedUser.exams = response;
    dispatch(setUserInfo(updatedUser));

    if (!ignored) {
      await refreshStudySessionAndContent();
      enqueueSnackbar('Exam updated successfully.', {
        autoHideDuration: 3000,
        style: { width: 'fit-content' },
      });
    }

    closeSecondaryScreen();
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);

    if (document.activeElement && document.activeElement !== document.body) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  const handleUnsnoozeSession = async () => {
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const updatedSession = { ...todaySession, isSnoozed: false };
    const response = await updateStudySession(todaySession.id, updatedSession);
    dispatch(setTodaySession(response));
    await refreshStudySessionAndContent();
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);
  };

  return (
    <div
      className="index-container"
      style={{ background: interpolateGradient(themeTransitionValue) }}
    >
      <div
        className={`home-loader${isLoadedStudySessionAndContent ? ' is-loaded' : ''}${isLoadingStudySessionAndContent ? ' start-loading' : ''}`}
        style={showSecondaryScreen ? { opacity: 0 } : {}}
      >
        <div>
          <h2 style={{ marginBottom: 0 }}>
            Hi, {currentUser?.name ? currentUser?.name : 'there'}.
          </h2>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <CircularProgress
              sx={{
                color: 'var(--type-body-inverse)',
                height: '1rem !important',
                width: '1rem !important',
              }}
            />
            <p>Optimizing your session...</p>
          </div>
        </div>
      </div>
      <div
        className={`home ${transitionStarted ? 'transitioning' : ''} ${isLoadedStudySessionAndContent ? 'is-loaded' : ''}`}
      >
        <div className="home_body">
          <Header
            type={HeaderType.home}
            isDiagnostic={false}
            setIsSwitchingExam={setIsSwitchingExam}
            secondaryScreenType={secondaryScreenType}
            secondaryScreenIsOpen={showSecondaryScreen}
            closeSecondaryScreen={closeSecondaryScreen}
            openStudySettings={() => {
              toggleSecondaryScreen();
              setSecondaryScreenType(SecondaryScreen.StudySettings);
            }}
            openTopicFilters={() => {
              toggleSecondaryScreen();
              setSecondaryScreenType(SecondaryScreen.TopicFilters);
            }}
            showSaveConfirmation={showSaveConfirmation}
            setShowSaveConfirmation={setShowSaveConfirmation}
            unsavedChanges={unsavedChanges}
            setMobileMenuIsOpen={setMobileMenuIsOpen}
            handleSaveTopicUpdates={handleSaveTopicUpdates}
            activeTopicsChanged={activeTopicsChanged}
          />
          {!showSecondaryScreen && (
            <>
              <div className="home_body_content">
                {!todaySession.isComplete &&
                  !isNoContent &&
                  !todaySession.isSnoozed && (
                    <Start
                      startTransition={initiateTransitionToStudy}
                      isStarted={todaySession.isStarted}
                      type={StartType.Home}
                      isSwitchingExam={isSwitchingExam}
                      isLoadedStudySessionAndContent={
                        isLoadedStudySessionAndContent
                      }
                      isLoadingStudySessionAndContent={
                        isLoadingStudySessionAndContent
                      }
                    />
                  )}
                {(todaySession.isComplete ||
                  isNoContent ||
                  todaySession.isSnoozed) && (
                  <div className="is-done">
                    <Check />
                    <h1
                      style={{
                        color: 'var(--type-heading-inverse)',
                        textAlign: 'center',
                      }}
                    >
                      {todaySession.isSnoozed
                        ? 'Enjoy Your Day'
                        : "You're Done for Today"}
                    </h1>
                    {!isNoContent && !todaySession.isSnoozed && (
                      <button
                        role="link"
                        className="button button--link button--link--glass"
                        onClick={() => {
                          navigate('/study');
                          hapticsImpactLight();
                        }}
                      >
                        Today's Stats
                        <ArrowRight />
                      </button>
                    )}
                    {!isNoContent && todaySession.isSnoozed && (
                      <button
                        className="button button--link button--link--glass"
                        onClick={() => {
                          handleUnsnoozeSession();
                          hapticsImpactLight();
                        }}
                      >
                        <AlarmClock />
                        Unsnooze Today's Session
                      </button>
                    )}
                    {isNoContent && (
                      <p style={{ textAlign: 'center', padding: '0 2rem' }}>
                        You don't have any due content today. Enjoy your day
                        off!
                      </p>
                    )}
                  </div>
                )}
              </div>
              <nav className="floating-nav">
                <button
                  className="button button--glass button--performance"
                  onClick={() => {
                    hapticsImpactLight();
                    navigate('/performance');
                  }}
                >
                  <BarChart2 />
                  <span>Performance</span>
                </button>
                <button
                  role="link"
                  className="button button--glass button--library"
                  onClick={() => {
                    hapticsImpactLight();
                    navigate('/library');
                  }}
                >
                  <Book />
                  <span>Library</span>
                </button>
              </nav>
              {/* {streak > 0 && (
                <button
                  className="streak"
                  onClick={() => {
                    hapticsImpactLight();
                    setIsDashboard(true);
                  }}
                >
                  <img src={Flame} alt="flame" />
                  <span>{streak}</span>
                  <span className="label">Day Streak</span>
                </button>
              )} */}
            </>
          )}
          {showSecondaryScreen && (
            <div className="secondary-screen">
              {secondaryScreenType === SecondaryScreen.StudySettings && (
                <StudySettings
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  showSaveConfirmation={showSaveConfirmation}
                  setShowSaveConfirmation={setShowSaveConfirmation}
                  closeSecondaryScreen={closeSecondaryScreen}
                  setIsLoadingStudySessionAndContent={
                    setIsLoadingStudySessionAndContent
                  }
                  setIsLoadedStudySessionAndContent={
                    setIsLoadedStudySessionAndContent
                  }
                />
              )}
              {secondaryScreenType === SecondaryScreen.Account && <Account />}
              {secondaryScreenType === SecondaryScreen.TopicFilters && (
                <TopicFilters setActiveTopicsChanged={setActiveTopicsChanged} />
              )}
              {secondaryScreenType === SecondaryScreen.Roadmap && <Roadmap />}
              {secondaryScreenType === SecondaryScreen.ExamCompletion && (
                <ExamCompletion
                  closeSecondaryScreen={closeSecondaryScreen}
                  handleDateChangeOrConfirmation={
                    handleDateChangeOrConfirmation
                  }
                />
              )}
            </div>
          )}
        </div>
        {!showSecondaryScreen && (
          <SettingsMenu
            openAccountSettings={showAccountSettings}
            mobileMenuIsOpen={mobileMenuIsOpen}
            setMobileMenuIsOpen={setMobileMenuIsOpen}
            openExamSettings={() => {
              toggleSecondaryScreen();
              setSecondaryScreenType(SecondaryScreen.StudySettings);
            }}
            openRoadmap={showRoadmap}
          />
        )}
      </div>
      <div
        className={`screensaver ${isIdle ? 'in' : 'out'}`}
        style={{ display: screenSaverDisplay }}
      >
        <ThreeOrb isScreensaver={true} />
      </div>
      <div id="side-drawer-root"></div>
      <div className="flash-loader" ref={flashLoaderRef}></div>
      <IntercomButton showSecondaryScreen={showSecondaryScreen} />
    </div>
  );
};

export default Home;
