import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { RootState } from '../../../../store/store';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import parse from 'html-react-parser';
import { Check } from 'react-feather';
import { SortableItem } from './SortableItem';
import {
  calculateStudyProgress,
  calculateStudyTimeRemaining,
} from '../../../../utils/calculateStudyTime';
import { updateCurrentUserInfo } from '../../../../services/auth';
import { StudyPhase } from '../../../../types/Study';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import { updateStudySession } from '../../../../services/studySession';
import { setTodaySession } from '../../../../slices/todaySession/todaySessionSlice';

interface SessionOverviewProps {
  isSwitchingExam?: boolean;
}

const SessionOverview: React.FC<SessionOverviewProps> = ({
  isSwitchingExam,
}) => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const blocks = useSelector((state: RootState) => state.blocks);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const preferredPhase = useSelector(
    (state: RootState) => state.auth.userInfo?.preferredStartPhase
  );
  const [currentProgress, setCurrentProgress] = useState(0);
  const [items, setItems] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const dispatch = useDispatch();
  const previousFirstItemRef = useRef<string | null>(null);

  useEffect(() => {
    setCurrentProgress(calculateStudyProgress(todaySession, blocks));

    const updatedItems: string[] = [];

    if (primaryExam) {
      if (primaryExam.isUsingFlashcards) {
        updatedItems.push('flashcard');
      }
      if (primaryExam.isUsingQbank) {
        updatedItems.push('qbank');
      }
    }

    // Move preferred phase to the top if it exists
    if (preferredPhase) {
      const index = updatedItems.indexOf(preferredPhase.toLowerCase());
      if (index > 0) {
        updatedItems.splice(index, 1);
        updatedItems.unshift(preferredPhase.toLowerCase());
      }
    }

    setItems(updatedItems);
  }, [todaySession, blocks, primaryExam, preferredPhase]);

  useEffect(() => {
    if (items.length > 0) {
      const firstItem = items[0];
      if (previousFirstItemRef.current !== firstItem) {
        previousFirstItemRef.current = firstItem;
        updateUserPreferredStartingPhase(
          StudyPhase[firstItem as keyof typeof StudyPhase]
        );
        updateStudySessionPhase(
          StudyPhase[firstItem as keyof typeof StudyPhase]
        );
      }
    }
  }, [items]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setIsDragging(false);

    if (over && active.id !== over.id) {
      setItems((prevItems) => {
        const oldIndex = prevItems.indexOf(String(active.id));
        const newIndex = prevItems.indexOf(String(over.id));

        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }
  };

  const updateUserPreferredStartingPhase = async (phase: StudyPhase) => {
    const updateUser = await updateCurrentUserInfo({
      preferredStartPhase: phase,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const updateStudySessionPhase = async (phase: StudyPhase) => {
    if (
      todaySession &&
      !todaySession.isStarted &&
      todaySession.phase !== phase
    ) {
      const updatedSession = { ...todaySession, phase: phase };
      const response = await updateStudySession(
        todaySession.id,
        updatedSession
      );
      dispatch(setTodaySession(response));
    }
  };

  const renderFlashcardDisplay = () => (
    <>
      {todaySession?.completedFlashcardCount ===
        todaySession?.flashcardCount && <Check />}
      <div className="label">Flashcards</div>
      {isSwitchingExam ? (
        <Skeleton
          sx={{ bgcolor: 'rgba(255,255,255,.05)', borderRadius: '.25rem' }}
          variant="rectangular"
          animation="wave"
        >
          <div>000</div>
        </Skeleton>
      ) : (
        <div className="counts">
          <span>{todaySession?.completedFlashcardCount} of</span>{' '}
          {todaySession?.flashcardCount}
        </div>
      )}
    </>
  );

  const renderQBankDisplay = () => {
    let totalVignetteCount = 0;
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].isSubmitted) {
        totalVignetteCount += blocks[i].vignetteCount;
      } else {
        totalVignetteCount += Object.keys(blocks[i].answers).length;
      }
    }
    return (
      <>
        {totalVignetteCount === todaySession?.vignetteCount && <Check />}
        <div className="label">QBank</div>
        {isSwitchingExam ? (
          <Skeleton
            sx={{ bgcolor: 'rgba(255,255,255,.05)', borderRadius: '.25rem' }}
            variant="rectangular"
            animation="wave"
          >
            <div>000</div>
          </Skeleton>
        ) : (
          <div className="counts">
            <span>{totalVignetteCount} of</span> {todaySession?.vignetteCount}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="session">
      <div className="session_top">
        <h3>Today's Session</h3>
      </div>
      <div className={`session_schedule ${isDragging ? 'is-dragging' : ''}`}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((item, index) => {
              return (
                <SortableItem
                  key={item}
                  id={item}
                  index={index}
                  only={items.length === 1}
                >
                  {item === 'flashcard'
                    ? renderFlashcardDisplay()
                    : renderQBankDisplay()}
                </SortableItem>
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
      <div className="session_progress">
        <div className="session_progress_bar">
          <LinearProgress
            className="progress-bar"
            sx={{ width: '100%', minWidth: '13.8rem' }}
            variant="determinate"
            color="success"
            value={currentProgress}
          />
        </div>
        <div className="time-estimate" style={{ paddingTop: '.25rem' }}>
          {parse(calculateStudyTimeRemaining(todaySession, blocks))}
          <span className="helper">Remaining</span>
        </div>
      </div>
    </div>
  );
};

export default SessionOverview;
