import DonutChart from 'react-donut-chart';

interface QBankDonutProps {
  percentage: number | string;
  data: {
    label: string;
    value: number;
  }[];
}

const QBankDonut: React.FC<QBankDonutProps> = ({ percentage, data }) => {
  return (
    <div className="donut">
      <DonutChart
        height={64}
        width={64}
        data={data}
        colors={['var(--green)', 'var(--orange)', 'var(--type-body-heading)']}
        strokeColor={'transparent'}
        selectedOffset={0}
        legend={false}
        interactive={false}
      />
      <div className="donut_label">{percentage}%</div>
    </div>
  );
};

export default QBankDonut;
