import TextareaAutosize from '@mui/material/TextareaAutosize';
import React from 'react';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { BlockType } from '../../../../types/Study';
import { X } from 'react-feather';

interface NotesProps {
  vignetteNotes: string;
  setVignetteNotes: (notes: string) => void;
  updateVignetteNotes: () => void;
  clearVignetteNotes: () => void;
  isReview: boolean;
  activeBlockType?: BlockType;
  isPastSessionReview: boolean;
  toggleNotes: () => void;
}

const Notes: React.FC<NotesProps> = ({
  vignetteNotes,
  setVignetteNotes,
  updateVignetteNotes,
  clearVignetteNotes,
  isReview,
  activeBlockType,
  isPastSessionReview,
  toggleNotes,
}) => {
  return (
    <div className="notes">
      <button
        className="mobile-function-close"
        onClick={() => {
          toggleNotes();
          hapticsImpactLight();
        }}
      >
        <X />
      </button>
      <TextareaAutosize
        minRows={5}
        style={{ width: '100%', borderRadius: '2px', borderColor: '#000' }}
        value={vignetteNotes}
        onChange={(e) => setVignetteNotes(e.target.value)}
        onClick={hapticsImpactLight}
      />
      {!isReview && (
        <div
          style={{ display: 'flex', alignItems: 'center', paddingTop: '6px' }}
        >
          {!isPastSessionReview && (
            <button
              className={activeBlockType === BlockType.tutor ? 'button' : ''}
              onClick={() => {
                updateVignetteNotes();
                hapticsImpactLight();
              }}
            >
              Save {activeBlockType === BlockType.tutor ? '&' : 'and'} Close
            </button>
          )}
          {!isPastSessionReview && (
            <button
              className={activeBlockType === BlockType.tutor ? 'button' : ''}
              onClick={() => {
                clearVignetteNotes();
                hapticsImpactLight();
              }}
            >
              Delete Notes
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Notes;
