import parse, { HTMLReactParserOptions } from 'html-react-parser';

interface CustomImageProps {
  src?: string;
  caption?: string;
  credit?: string;
  annotatedSrc?: string;
}

const CustomImage: React.FC<CustomImageProps> = ({
  src,
  caption,
  credit,
  annotatedSrc,
}) => {
  const processLinks = (htmlString: string): React.ReactNode => {
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (domNode.type === 'tag' && domNode.name === 'a' && domNode.attribs) {
          if (!domNode.attribs.target) {
            domNode.attribs.target = '_blank';
          }
        }
      },
    };
    return parse(htmlString, options);
  };

  return (
    <div className="custom-image-container">
      <img src={src} alt={caption} data-image-src-annotated={annotatedSrc} />
      <div className="caption-credit">
        {caption && <div className="caption">{processLinks(caption)}</div>}
        {credit && <div className="credit">{processLinks(credit)}</div>}
      </div>
    </div>
  );
};

export default CustomImage;
