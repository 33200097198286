import React, { useState } from 'react';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { X } from 'react-feather';

type CalcState = {
  displayValue: string;
  memoryValue: string;
  operator: string | null;
  firstValue: string;
  waitingForSecondValue: boolean;
};

interface CalculatorProps {
  closeCalculator: () => void;
}

const Calculator: React.FC<CalculatorProps> = ({ closeCalculator }) => {
  const [calcState, setCalcState] = useState<CalcState>({
    displayValue: '0',
    memoryValue: '0',
    operator: null,
    firstValue: '',
    waitingForSecondValue: false,
  });

  const inputDigit = (digit: string) => {
    const { displayValue, waitingForSecondValue } = calcState;

    if (waitingForSecondValue) {
      setCalcState({
        ...calcState,
        displayValue: digit,
        waitingForSecondValue: false,
      });
    } else {
      setCalcState({
        ...calcState,
        displayValue: displayValue === '0' ? digit : displayValue + digit,
      });
    }
  };

  const inputDecimal = () => {
    if (!calcState.displayValue.includes('.')) {
      setCalcState({
        ...calcState,
        displayValue: calcState.displayValue + '.',
        waitingForSecondValue: false,
      });
    }
  };

  const clearAll = () => {
    setCalcState((prevState) => ({
      displayValue: '0',
      memoryValue: prevState.memoryValue,
      operator: null,
      firstValue: '',
      waitingForSecondValue: false,
    }));
  };

  const performOperation = (nextOperator: string) => {
    const { firstValue, displayValue, operator } = calcState;
    const inputValue = parseFloat(displayValue);

    if (firstValue === '' && !isNaN(inputValue)) {
      setCalcState({
        ...calcState,
        firstValue: displayValue,
        operator: nextOperator,
        waitingForSecondValue: true,
      });
    } else if (operator) {
      const currentValue = firstValue !== '' ? parseFloat(firstValue) : 0;
      const newValue = CalculatorOperations[operator](currentValue, inputValue);

      setCalcState({
        ...calcState,
        firstValue: String(newValue),
        displayValue: String(newValue),
        operator: nextOperator,
        waitingForSecondValue: true,
      });
    }
  };

  // Keybinds (Not Yet Implemented)
  // const handleKeyDown = (event: React.KeyboardEvent) => {
  //   if (event.key.match(/[0-9]/)) {
  //     inputDigit(event.key);
  //   } else if (event.key === '.') {
  //     inputDecimal();
  //   } else if (event.key.match(/[+\-*/]/)) {
  //     performOperation(event.key);
  //   } else if (event.key === 'Enter' || event.key === '=') {
  //     performOperation('=');
  //   } else if (event.key === 'Backspace') {
  //     clearAll();
  //   }
  // };

  const toggleSign = () => {
    const { displayValue } = calcState;
    const newValue = parseFloat(displayValue) * -1;
    setCalcState({
      ...calcState,
      displayValue: String(newValue),
    });
  };

  const sqrt = () => {
    const { displayValue } = calcState;
    const newValue = Math.sqrt(parseFloat(displayValue));
    setCalcState({
      ...calcState,
      displayValue: String(newValue),
    });
  };

  const inverse = () => {
    const { displayValue } = calcState;
    const newValue = 1 / parseFloat(displayValue);
    setCalcState({
      ...calcState,
      displayValue: String(newValue),
    });
  };

  const memoryAdd = () => {
    const { displayValue } = calcState;
    const newValue =
      parseFloat(displayValue) + parseFloat(calcState.memoryValue);
    setCalcState({
      ...calcState,
      memoryValue: String(newValue),
    });
  };

  const memoryRecall = () => {
    setCalcState({
      ...calcState,
      displayValue: calcState.memoryValue,
    });
  };

  const memoryClear = () => {
    setCalcState({
      ...calcState,
      memoryValue: '0',
    });
  };

  // Define the calculator's operations
  const CalculatorOperations: {
    [key: string]: (firstValue: number, secondValue: number) => number;
  } = {
    '/': (firstValue, secondValue) => firstValue / secondValue,
    '*': (firstValue, secondValue) => firstValue * secondValue,
    '+': (firstValue, secondValue) => firstValue + secondValue,
    '-': (firstValue, secondValue) => firstValue - secondValue,
    '=': (_firstValue, secondValue) => secondValue,
  };

  return (
    <div className="calculator">
      <button
        className="mobile-function-close"
        onClick={() => {
          closeCalculator();
          hapticsImpactLight();
        }}
      >
        <X />
      </button>
      <div
        className={`calculator-display ${calcState.memoryValue !== '0' ? 'show-m' : ''}`}
      >
        {calcState.displayValue}
      </div>
      <div className="calculator-keypad">
        <div>
          <button
            className="b-purple"
            onClick={() => {
              memoryAdd();
              hapticsImpactLight();
            }}
          >
            M+
          </button>
          <button
            className="b-purple"
            onClick={() => {
              memoryRecall();
              hapticsImpactLight();
            }}
          >
            MR
          </button>
          <button
            className="b-purple"
            onClick={() => {
              memoryClear();
              hapticsImpactLight();
            }}
          >
            MC
          </button>
          <button
            className="b-yellow"
            onClick={() => {
              clearAll();
              hapticsImpactLight();
            }}
          >
            C
          </button>
        </div>
        <div>
          <button
            className="b-blue"
            onClick={() => {
              toggleSign();
              hapticsImpactLight();
            }}
          >
            ±
          </button>
          <button
            className="b-blue"
            onClick={() => {
              sqrt();
              hapticsImpactLight();
            }}
          >
            √<span>x</span>
          </button>
          <button
            className="b-blue"
            onClick={() => {
              inverse();
              hapticsImpactLight();
            }}
          >
            1/x
          </button>
          <button
            className="b-blue"
            onClick={() => {
              performOperation('/');
              hapticsImpactLight();
            }}
          >
            ÷
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              inputDigit('7');
              hapticsImpactLight();
            }}
          >
            7
          </button>
          <button
            onClick={() => {
              inputDigit('8');
              hapticsImpactLight();
            }}
          >
            8
          </button>
          <button
            onClick={() => {
              inputDigit('9');
              hapticsImpactLight();
            }}
          >
            9
          </button>
          <button
            className="b-blue"
            onClick={() => {
              performOperation('*');
              hapticsImpactLight();
            }}
          >
            *
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              inputDigit('4');
              hapticsImpactLight();
            }}
          >
            4
          </button>
          <button
            onClick={() => {
              inputDigit('5');
              hapticsImpactLight();
            }}
          >
            5
          </button>
          <button
            onClick={() => {
              inputDigit('6');
              hapticsImpactLight();
            }}
          >
            6
          </button>
          <button
            className="b-blue"
            onClick={() => {
              performOperation('-');
              hapticsImpactLight();
            }}
          >
            -
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              inputDigit('1');
              hapticsImpactLight();
            }}
          >
            1
          </button>
          <button
            onClick={() => {
              inputDigit('2');
              hapticsImpactLight();
            }}
          >
            2
          </button>
          <button
            onClick={() => {
              inputDigit('3');
              hapticsImpactLight();
            }}
          >
            3
          </button>
          <button
            className="b-blue"
            onClick={() => {
              performOperation('+');
              hapticsImpactLight();
            }}
          >
            +
          </button>
        </div>
        <div className="last">
          <button
            onClick={() => {
              inputDigit('0');
              hapticsImpactLight();
            }}
          >
            0
          </button>
          <button
            onClick={() => {
              inputDecimal();
              hapticsImpactLight();
            }}
          >
            .
          </button>
          <button
            className="b-yellow"
            onClick={() => {
              performOperation('=');
              hapticsImpactLight();
            }}
          >
            =
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
