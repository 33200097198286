import { User } from '../types/User';

export const isSuperAdmin = (currentUser: User | null) => {
  if (!currentUser) return false;
  if (
    currentUser.email === 'jacob@synaptiq.co' ||
    currentUser.email === 'kevin@synaptiq.co'
  ) {
    return true;
  }
  return false;
};
