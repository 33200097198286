import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../slices/auth/authSlice';
import { AlertTriangle, ArrowRight, User, X } from 'react-feather';
import { useEffect, useRef, useState } from 'react';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import Modal from '@mui/material/Modal';
import { hapticsImpactLight } from '../../utils/haptics';
// import { supportedTimezones } from '../../utils/supportedTimezones';
// import NotificationFeed from '../../components/Knock/NotificationFeed';
// import { getUnreadNotificationCount } from '../../services/knock';
import { initUserForStudying, resetStudyProgress } from '../../services/study';
import { useRefreshStudySessionAndContent } from '../../utils/refreshStudySession';
import { deleteUser } from '../../services/users';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface SettingsMenuProps {
  mobileMenuIsOpen: boolean;
  setMobileMenuIsOpen: (isOpen: boolean) => void;
  openAccountSettings: () => void;
  openExamSettings: () => void;
  openRoadmap: () => void;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({
  mobileMenuIsOpen,
  setMobileMenuIsOpen,
  openAccountSettings,
  openExamSettings,
  openRoadmap,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [activeSection, setActiveSection] = useState('');
  const [dangerZoneModalIsOpen, setDangerZoneModalIsOpen] = useState(false);
  const [dangerZoneType] = useState('');
  // const [unreadCount, setUnreadCount] = useState(0);
  // const [notificationsIsVisible, setNofiticationsIsVisible] = useState(false);
  const notifButtonRef = useRef(null);

  const { refreshStudySessionAndContent } = useRefreshStudySessionAndContent();
  const libraryFlagEnabled = useFeatureFlagEnabled('library');

  useEffect(() => {
    if (activeSection === '') {
      const firstFocusableElement = document.querySelector(
        '.settings-menu_body_menu button'
      ) as HTMLButtonElement;
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    } else {
      const firstFocusableElement = document.querySelector(
        '.settings-menu_body_sections section button'
      ) as HTMLButtonElement;
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [activeSection]);

  // useEffect(() => {
  //   if (currentUser) {
  //     getUnreadNotificationCount(currentUser.id).then((count) => {
  //       setUnreadCount(count);
  //     });
  //   }
  // }, [currentUser, notificationsIsVisible]);

  const handleResetProgress = async () => {
    try {
      const response = await resetStudyProgress();
      if (response.success) {
        await initUserForStudying();

        // Refresh today's study session and content
        await refreshStudySessionAndContent();

        enqueueSnackbar(
          'User progress reset successfully. Please reload the page.',
          {
            autoHideDuration: 3000,
          }
        );
      } else {
        enqueueSnackbar('Error resetting user progress.');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error resetting user progress.');
    }
  };

  const handleDangerZoneAction = async (type: string) => {
    if (type === 'delete' && dangerZoneType === 'delete') {
      if (currentUser?.id) {
        const response = await deleteUser(currentUser?.id);
        if (response?.status === 204) {
          handleSignOut();
        } else {
          enqueueSnackbar(
            'Error deleting account. Please reload the page and try again.'
          );
        }
      }
    }
    setDangerZoneModalIsOpen(false);
  };

  const handleSignOut = () => {
    dispatch(logout());
    navigate('/auth');
  };

  return (
    <div
      className={`settings-menu ${mobileMenuIsOpen ? 'open' : ''} ${activeSection !== '' ? 'open-sub' : ''}`}
    >
      <div
        className={`icon ${mobileMenuIsOpen ? 'open' : ''}`}
        ref={notifButtonRef}
      >
        <User />
        {/* {unreadCount > 0 && (
          <span className="noti-badge">
            <span>{`${unreadCount}`}</span>
          </span>
        )} */}
      </div>
      <div className="settings-menu_menu">
        <div className="settings-menu_menu_header">
          <h2>Settings</h2>
          <button
            onClick={() => {
              setMobileMenuIsOpen(false);
              hapticsImpactLight();
            }}
          >
            <X />
          </button>
        </div>
        {/* <button
          className={`settings-menu_menu_item notifications`}
          onClick={() => {
            hapticsImpactLight();
            setNofiticationsIsVisible(!notificationsIsVisible);
          }}
        >
          {unreadCount > 0 && (
            <span className="noti-badge m-r-0-50">
              <span>{`${unreadCount}`}</span>
            </span>
          )}
          Notifications
        </button> */}
        <button
          className={`settings-menu_menu_item settings-menu_menu_item--exam-settings ${activeSection === 'exam-settings' ? 'active' : ''}`}
          onClick={() => {
            openExamSettings();
            hapticsImpactLight();
          }}
        >
          <span>Exam Settings</span>
          <ArrowRight />
        </button>
        {(libraryFlagEnabled || process.env.NODE_ENV !== 'production') && (
          <button
            className={`settings-menu_menu_item settings-menu_menu_item--library ${activeSection === 'library' ? 'active' : ''}`}
            onClick={() => {
              navigate('/library');
              hapticsImpactLight();
            }}
          >
            <span>Library</span>
            <ArrowRight />
          </button>
        )}
        <button
          className={`settings-menu_menu_item ${activeSection === 'account' ? 'active' : ''}`}
          onClick={() => {
            openAccountSettings();
            hapticsImpactLight();
          }}
        >
          <span>Account</span>
          <ArrowRight />
        </button>
        <button
          className={`settings-menu_menu_item ${activeSection === 'roadmap' ? 'active' : ''}`}
          onClick={() => {
            openRoadmap();
            hapticsImpactLight();
          }}
        >
          <span>Roadmap</span>
          <ArrowRight />
        </button>
        {currentUser?.role === 'admin' && (
          <Link
            to={'/admin'}
            className={`settings-menu_menu_item ${activeSection === 'admin' ? 'active' : ''}`}
            style={{ textDecoration: 'none' }}
            onClick={hapticsImpactLight}
          >
            <span>Admin Dashboard</span>
          </Link>
        )}
        <button
          className={`settings-menu_menu_item ${activeSection === 'account' ? 'active' : ''}`}
          onClick={() => {
            window.Intercom('showNewMessage');
            hapticsImpactLight();
          }}
        >
          Help
        </button>
        {currentUser?.role === 'admin' && (
          <button
            className="settings-menu_menu_item"
            onClick={() => {
              handleResetProgress();
            }}
          >
            <span>Reset Progress</span>
          </button>
        )}
        <button
          className="settings-menu_menu_item"
          onClick={() => {
            handleSignOut();
            hapticsImpactLight();
          }}
        >
          <span>Log Out</span>
        </button>
      </div>
      <div
        className="settings-menu_sections"
        style={activeSection !== '' ? { transform: 'translateX(0)' } : {}}
      >
        <Modal
          open={dangerZoneModalIsOpen}
          onClose={() => setDangerZoneModalIsOpen(false)}
        >
          <div className="modal modal--danger-zone">
            <div className="modal_header">
              {dangerZoneType === 'reset' && <h2>Reset Scheduling</h2>}
              {dangerZoneType === 'delete' && <h2>Delete Account</h2>}
            </div>
            <div className="modal_content">
              {dangerZoneType === 'reset' && (
                <p>
                  <AlertTriangle
                    style={{
                      color: 'var(--error',
                      height: '1rem',
                      transform: 'translateY(2px)',
                    }}
                  />
                  <strong style={{ color: 'var(--error' }}>
                    PROCEED WITH CAUTION
                  </strong>
                  <br />
                  <br />
                  This action wipes all your scheduling data. This resets your
                  study progress to zero. This cannot be undone.
                </p>
              )}
              {dangerZoneType === 'delete' && (
                <p>
                  <AlertTriangle
                    style={{
                      color: 'var(--error',
                      height: '1rem',
                      transform: 'translateY(2px)',
                    }}
                  />
                  <strong style={{ color: 'var(--error' }}>
                    PROCEED WITH CAUTION
                  </strong>
                  <br />
                  <br />
                  This action fully deletes your account including your
                  scheduling data. You will be logged out. This cannot be
                  undone.
                </p>
              )}
            </div>
            <div className="modal_actions">
              <button
                className="button button--secondary"
                onClick={() => {
                  setDangerZoneModalIsOpen(false);
                  hapticsImpactLight();
                }}
              >
                Cancel
              </button>
              <button
                className="button button--error"
                onClick={() => {
                  handleDangerZoneAction('delete');
                  hapticsImpactLight();
                }}
              >
                {dangerZoneType === 'reset'
                  ? 'Reset Scheduling'
                  : 'Delete Account'}
              </button>
            </div>
          </div>
        </Modal>
      </div>
      {activeSection !== '' && (
        <button
          className="settings-menu_overlay"
          onClick={() => {
            setActiveSection('');
            hapticsImpactLight();
          }}
        ></button>
      )}
      {/* <NotificationFeed
        isVisible={notificationsIsVisible}
        setIsVisible={setNofiticationsIsVisible}
        notifButtonRef={notifButtonRef}
      /> */}
    </div>
  );
};

export default SettingsMenu;
