import { X } from 'react-feather';
import { hapticsImpactLight } from '../../utils/haptics';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useEffect, useState } from 'react';
import React from 'react';

interface SubPageProps {
  title: string;
  examName: string;
  close: () => void;
  fullScreen?: boolean;
}

const SubPage: React.FC<SubPageProps & { children: React.ReactNode }> = ({
  children,
  title,
  examName,
  close,
  fullScreen,
}) => {
  const [contentPadding, setContentPadding] = useState(0);
  const SubPageHeaderRef = React.useRef<HTMLDivElement>(null);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (windowWidth > 560) {
      setContentPadding(0);
    } else {
      setContentPadding(
        SubPageHeaderRef.current?.getBoundingClientRect().height || 0
      );
    }
  }, [windowWidth]);

  return (
    <div className={`sub-page ${fullScreen ? 'full-screen' : ''}`}>
      <button
        className="button button--close button--close--desktop"
        onClick={() => {
          close();
          hapticsImpactLight();
        }}
      >
        <X />
      </button>
      <div className="sub-page_header" ref={SubPageHeaderRef}>
        <div className="exam-title">
          <span>{examName}</span>
          <h1>{title}</h1>
        </div>
        <button
          className="button button--close"
          onClick={() => {
            close();
            hapticsImpactLight();
          }}
        >
          <X />
        </button>
      </div>
      <div className="sub-page_content" style={{ paddingTop: contentPadding }}>
        {children}
      </div>
    </div>
  );
};

export default SubPage;
