import React, { useState, useEffect } from 'react';
import type { RootState } from '../../store/store';
import { getAllUsers, deleteUser, updateUser } from '../../services/users';
import { User, UserRole } from '../../types/User';
import Header from '../../components/Global/Header';
import { Crosshair, Bell, Trash, User as UserIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { HeaderType } from '../../types/Header';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {
  sendNotificationToAllUsers,
  sendNotificationToUser,
} from '../../services/knock';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextField, Tooltip } from '@mui/material';
import { isSuperAdmin } from '../../utils/isSuperAdmin';
import { getUserInfo, impersonateUser } from '../../services/auth';
import {
  clearUserInfo,
  setUserInfo,
  updateAuthToken,
  updateRefreshToken,
} from '../../slices/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const Admin: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pendingDeletionID, setPendingDeletionID] = useState('');
  const [pendingNotificationEmail, setPendingNotificationEmail] = useState('');
  const [pendingNotificationID, setPendingNotificationID] = useState('');
  const [systemNotificationContent, setSystemNotificationContent] =
    useState('');
  const [singleNotificationContent, setSingleNotificationContent] =
    useState('');
  const [notificationLink, setNotificationLink] = useState('');
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);
  const [hideSynaptiq, setHideSynaptiq] = useState(true);
  const [showSystemNotificationModal, setShowSystemNotificationModal] =
    useState(false);
  const [showSingleNotificationModal, setShowSingleNotificationModal] =
    useState(false);
  const [pendingUpdateRoleID, setPendingUpdateRoleID] = useState('');
  const [pendingUpdateRoleSelection, setPendingUpdateRoleSelection] =
    useState<UserRole | null>(null);
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const userRoles = Object.values(UserRole);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [hideSynaptiq]);

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getAllUsers();
      const filteredUsers = filterUsers(fetchedUsers);
      filteredUsers.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setUsers(filteredUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const initiateDeleteRequest = (userId: string) => {
    setPendingDeletionID(userId);
    setShowDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    await deleteUser(pendingDeletionID);
    const fetchedUsers = await getAllUsers();
    const filteredUsers = filterUsers(fetchedUsers);
    filteredUsers.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    setUsers(filteredUsers);
    hideDeleteModal();
  };

  const hideDeleteModal = () => {
    setShowDeleteModal(false);
    setPendingDeletionID('');
  };

  const initiateUpdateRole = (userId: string) => {
    setPendingUpdateRoleID(userId);
    setShowUpdateRoleModal(true);
  };

  const impersonate = async (userId: string) => {
    try {
      const response = await impersonateUser(userId);
      if (response && response.token && response.refreshToken) {
        handleImpersonateSuccess(response.token, response.refreshToken);
      }
    } catch (error) {
      console.error('Error impersonating user:', error);
    }
  };

  const handleImpersonateSuccess = async (
    authToken: string,
    refreshToken: string | null = null
  ) => {
    dispatch(updateAuthToken(authToken));
    if (refreshToken) dispatch(updateRefreshToken(refreshToken));
    dispatch(clearUserInfo());
    const userInfo = await getUserInfo();
    if (userInfo) {
      dispatch(setUserInfo(userInfo));
    }
    navigate('/');
  };

  const filterUsers = (users: User[]) => {
    if (!hideSynaptiq) return users;
    return users.filter(
      (user) =>
        user.role !== 'admin' &&
        !user.email!.includes('rptest') &&
        !user.email!.includes('jctest') &&
        !user.email!.includes('kbtest') &&
        !user.email!.includes('caccamo') &&
        !user.email!.includes('oraai.com') &&
        !user.email!.includes('synaptiq.co') &&
        !user.email!.includes('kaycbas') &&
        !user.email!.includes('ryanrlphelps@gmail.com') &&
        !user.email!.includes('cphelps@losaltoslandscaping.com') &&
        !user.email!.includes('ryanllewellynphelps@gmail.com') &&
        !user.email!.includes('will.richardson.1994@gmail.com') &&
        user.id !== currentUser?.id
    );
  };

  const handleUpdateRole = async () => {
    if (pendingUpdateRoleSelection) {
      await updateUser(pendingUpdateRoleID, {
        role: pendingUpdateRoleSelection,
      });
      hideUpdateRoleModal();
    }
  };

  const hideUpdateRoleModal = () => {
    setShowUpdateRoleModal(false);
    setPendingUpdateRoleSelection(null);
    setPendingUpdateRoleID('');
  };

  const handleRoleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPendingUpdateRoleSelection(event.target.value as UserRole);
  };

  // Notificaitons
  const sendNotification = async () => {
    await sendNotificationToAllUsers(systemNotificationContent);
    setShowSystemNotificationModal(false);
    setSystemNotificationContent('');
  };

  const initiateSingleNotification = (userId: string, email: string) => {
    setPendingNotificationID(userId);
    setPendingNotificationEmail(email);
    setShowSingleNotificationModal(true);
  };

  const sendSingleNotification = async () => {
    await sendNotificationToUser(
      pendingNotificationID,
      singleNotificationContent,
      notificationLink
    );
    setShowSingleNotificationModal(false);
    setPendingNotificationID('');
    setPendingNotificationEmail('');
    setSingleNotificationContent('');
    setNotificationLink('');
  };

  return (
    <div className="admin">
      <div className="admin_body">
        <Header type={HeaderType.default} isDiagnostic={false} />
        <div className="admin_body_content">
          <div>
            <div>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h1 className="m-t-0 m-b-1">Admin Dashboard</h1>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={hideSynaptiq}
                          onChange={() => setHideSynaptiq(!hideSynaptiq)}
                        />
                      }
                      label="Hide Synaptiq Users"
                    />
                  </FormGroup>
                </div>
                {/* <button
                  style={{ height: 'fit-content' }}
                  className="button"
                  onClick={() => setShowSystemNotificationModal(true)}
                >
                  Send Notification to All Users
                </button> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '1.5rem',
                  alignItems: 'baseline',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '.25rem',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <strong style={{ fontSize: '1.25rem' }}>
                    {users.length}
                  </strong>
                  <div className="helper">Total Users</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '.25rem',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <strong style={{ fontSize: '1.25rem' }}>
                    {users.filter((user) => user.oauth).length}
                  </strong>
                  <div className="helper">OAuth Users</div>
                </div>
              </div>
              <table className="users-list">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>ID</th>
                    <th>OAuth</th>
                    <th>Role</th>
                    <th>Created At</th>
                    {currentUser?.role === 'admin' && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {users?.map((user: User) => (
                    <tr className="user-row" key={user.id}>
                      <td>
                        {user.name ? user.name : 'N/A'}
                        {currentUser?.id === user.id ? ' (You)' : ''}
                      </td>
                      <td>{user.email}</td>
                      <td>{user.id}</td>
                      <td>{user.oauth ? user.oauthStrategy : 'Not OAuth'}</td>
                      <td>{user.role}</td>
                      <td>
                        {new Date(user.createdAt).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </td>
                      {currentUser?.role === 'admin' && (
                        <td>
                          <Tooltip
                            title={'Impersonate User'}
                            enterTouchDelay={0}
                          >
                            <button
                              className="button button--icon-only"
                              style={{ display: 'inline-flex' }}
                              onClick={() => impersonate(user.id)}
                            >
                              <Crosshair />
                            </button>
                          </Tooltip>
                          <button
                            className="button button--icon-only"
                            style={{ display: 'inline-flex' }}
                            onClick={() =>
                              initiateSingleNotification(
                                user.id,
                                user.email as string
                              )
                            }
                          >
                            <Bell />
                          </button>
                          <button
                            className="button button--icon-only"
                            style={{ display: 'inline-flex' }}
                            onClick={() => initiateUpdateRole(user.id)}
                          >
                            <UserIcon />
                          </button>
                          <button
                            className="button button--icon-only"
                            style={{ display: 'inline-flex' }}
                            onClick={() => initiateDeleteRequest(user.id)}
                          >
                            <Trash />
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal open={showDeleteModal} onClose={hideDeleteModal}>
        <div className="modal modal--delete-user">
          <div className="modal_header">
            <h2>Delete User</h2>
          </div>
          <div className="modal_content">
            <p>Are you sure you want to delete this user?</p>
          </div>
          <div className="modal_actions">
            <button className="button button--error" onClick={handleDeleteUser}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showSystemNotificationModal}
        onClose={() => setShowSystemNotificationModal(false)}
      >
        <div className="modal modal--system-notification">
          <div className="modal_header">
            <h2>Send Notification to All Users</h2>
          </div>
          <div className="modal_content">
            <p className="m-t-0">
              Send a plain text notification to all users.
            </p>
            <TextareaAutosize
              aria-label="notification message"
              value={systemNotificationContent}
              onChange={(e) => setSystemNotificationContent(e.target.value)}
              minRows={3}
              maxRows={10}
              placeholder="Type system-wide notification here..."
              style={{ width: '100%' }}
            />
          </div>
          <div className="modal_actions">
            <button
              className="button button--secondary"
              onClick={() => setShowSystemNotificationModal(false)}
            >
              Cancel
            </button>
            <button className="button" onClick={sendNotification}>
              Send
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showSingleNotificationModal}
        onClose={() => setShowSingleNotificationModal(false)}
      >
        <div className="modal modal--system-notification">
          <div className="modal_header">
            <h2>Send Notification</h2>
          </div>
          <div className="modal_content">
            <p className="m-t-0">
              <strong>Recipient: </strong>
              {pendingNotificationEmail}
              <br />
              <br />
              Send a plain text notification to a single user.
            </p>
            <TextareaAutosize
              aria-label="notification message"
              value={singleNotificationContent}
              onChange={(e) => setSingleNotificationContent(e.target.value)}
              minRows={3}
              maxRows={10}
              placeholder="Type user notification here..."
              style={{ width: '100%' }}
            />
            {isSuperAdmin(currentUser) && (
              <TextField
                sx={{ marginTop: '1rem' }}
                label="Link"
                value={notificationLink}
                onChange={(e) => setNotificationLink(e.target.value)}
                style={{ width: '100%' }}
              />
            )}
          </div>
          <div className="modal_actions">
            <button
              className="button button--secondary"
              onClick={() => {
                setShowSingleNotificationModal(false);
                setPendingNotificationEmail('');
                setPendingNotificationID('');
                setSingleNotificationContent('');
              }}
            >
              Cancel
            </button>
            <button className="button" onClick={sendSingleNotification}>
              Send
            </button>
          </div>
        </div>
      </Modal>
      <Modal open={showUpdateRoleModal} onClose={hideUpdateRoleModal}>
        <div className="modal modal--update-role">
          <div className="modal_header">
            <h2>Update User Role</h2>
          </div>
          <div className="modal_content">
            <select
              value={pendingUpdateRoleSelection ?? ''}
              onChange={handleRoleSelectChange}
            >
              <option value="">Select a role</option>
              {userRoles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>
          <div className="modal_actions">
            <button className="button" onClick={handleUpdateRole}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Admin;
