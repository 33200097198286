import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Drag } from '../../../../../assets/svgs/Drag';

interface SortableItemProps {
  id: string;
  children: React.ReactNode;
  index: number;
  only: boolean;
}

export const SortableItem: React.FC<SortableItemProps> = ({
  id,
  children,
  index,
  only,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={`item${' item--' + index}${only ? ' item--only' : ''}`}
      style={style}
      {...attributes}
      {...listeners}
    >
      {children}
      <div className="drag-handle">
        <Drag />
      </div>
    </div>
  );
};
