import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { fetchTopicPriorities } from '../../services/stats';
import { ArrowRight } from 'react-feather';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import SubPage from '../../components/SubPage';

const Priorities: React.FC = () => {
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const [topicPriorities, setTopicPriorities] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (primaryExam) {
      getPriorityTopics();
    }
  }, [primaryExam]);

  const getPriorityTopics = async () => {
    fetchTopicPriorities().then((response) => {
      setTopicPriorities(response);
    });
  };

  return (
    <div className="priorities">
      <SubPage
        title="Topic priorities"
        examName={primaryExam?.name || ''}
        close={() => navigate('/performance')}
      >
        <>
          <p className="m-t-0 m-b-2">
            Topic priority is a measure of how important a topic is for you to
            study. It combines topic yield with your individual performance.
            Topic priorities are constantly recalculated as Ora learns your
            strengths and weaknesses.
          </p>
          <div className="priority-topics">
            <div className="priority-topics_wrapper">
              {topicPriorities.length > 0 &&
                topicPriorities.map(
                  ({ priority, path, name, articleId }, index) => {
                    return (
                      <div
                        key={'tile-' + name + '-' + path + '-' + priority}
                        className="priority-topics_wrapper_row"
                      >
                        <div className="left">
                          <div
                            className={`number ${index + 1 > 99 ? 'three-digits' : ''}`}
                          >
                            {index + 1}
                          </div>
                        </div>
                        <div className="right">
                          <div className="l1">{path}</div>
                          <div className="l2">
                            {articleId ? (
                              <a
                                className="article-link"
                                href={`/library/${articleId}`}
                              >
                                {name}
                                <ArrowRight />
                              </a>
                            ) : (
                              <>{name}</>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              {!topicPriorities.length && (
                <>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <Skeleton
                      key={index}
                      sx={{
                        bgcolor: 'rgba(255,255,255,.05)',
                        borderRadius: '1rem',
                      }}
                      variant="rectangular"
                      animation="wave"
                      width={'100%'}
                      height={32}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      </SubPage>
    </div>
  );
};

export default Priorities;
