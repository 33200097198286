import Modal from '@mui/material/Modal';
import { ArrowLeft } from 'react-feather';

interface RegenerateModalProps {
  showRegenModal: boolean;
  setShowRegenModal: (show: boolean) => void;
  submitChanges: (regenerate: boolean) => void;
}

const RegenerateModal: React.FC<RegenerateModalProps> = ({
  showRegenModal,
  setShowRegenModal,
  submitChanges,
}) => {
  return (
    <Modal open={showRegenModal} onClose={() => setShowRegenModal(false)}>
      <div className="modal modal--glass modal--regenerate">
        <div className="modal_header">
          <h2>Regenerate Today's Session?</h2>
        </div>
        <div className="modal_content">
          <p>
            You've already started today's session. You can regenerate today's
            session with these changes, or apply them tomorrow.
          </p>
        </div>
        <div className="modal_actions">
          <button
            className="button button--glass button--back"
            onClick={() => setShowRegenModal(false)}
          >
            <ArrowLeft />
            <span>Back</span>
          </button>
          <div>
            <button
              className="button button--glass--link"
              onClick={() => {
                submitChanges(false);
                setShowRegenModal(false);
              }}
            >
              Apply Tomorrow
            </button>
            <button
              className="button button--glass"
              onClick={() => {
                submitChanges(true);
                setShowRegenModal(false);
              }}
            >
              Regenerate
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RegenerateModal;
