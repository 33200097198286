/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import parse from 'html-react-parser';
import {
  Article,
  ArticleImage,
  ArticleReference,
  ArticleSection,
} from '../../types/Article';
import { AlertTriangle, ExternalLink } from 'react-feather';
import ArticleMenu from '../../components/Library/ArticleMenu';
import ReportArticle from '../../components/Library/ReportArticle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Skeleton from '@mui/material/Skeleton';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface ArticlePageProps {
  activeArticle: Article | null;
  articleLoading: boolean;
}

const ArticlePage: React.FC<ArticlePageProps> = ({
  activeArticle,
  articleLoading,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [reportArticleModalIsOpen, setReportArticleModalIsOpen] =
    useState(false);
  const [headings, setHeadings] = useState<{ title: string; id: string }[]>([]);
  const [jumpLoading, setJumpLoading] = useState(false);

  useEffect(() => {
    if (activeArticle) {
      setJumpLoading(true);
      const h2Elements = Array.from(
        document.querySelectorAll('.article_body_center_card_content h2')
      );
      const newHeadings = h2Elements.map((el) => ({
        title: el.textContent || '',
        id: el.id,
      }));
      setHeadings(newHeadings);
      setJumpLoading(false);
    }
  }, [activeArticle]);

  const scrollToSection = (id: string) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderArticleSections = useCallback((sections: ArticleSection[]) => {
    sections.sort((a, b) => a.ord - b.ord);
    return (
      <>
        {sections.map((section, sectionIndex) => {
          const updatedText = parse(section.text, {
            replace: (domNode: any) => {
              if (
                'type' in domNode &&
                domNode.type === 'tag' &&
                'name' in domNode &&
                domNode.name === 'h2'
              ) {
                const element = domNode as {
                  attribs: { [key: string]: string };
                };
                element.attribs.id = section.id;
              } else if (domNode.attribs && domNode.attribs.href) {
                return (
                  <Link
                    to={domNode.attribs.href}
                    key={
                      domNode.attribs.href +
                      '-' +
                      sectionIndex +
                      '-' +
                      Math.floor(Math.random() * 10000) +
                      1
                    }
                    className="internal-link"
                  >
                    {domNode.children[0]?.data}
                  </Link>
                );
              }
            },
          });

          return (
            <section
              id={'spy-' + sectionIndex}
              className="article-section"
              key={sectionIndex}
            >
              {updatedText}
              {renderArticleSectionImages(section.images)}
              <div className="reference-container">
                {renderArticleSectionReferences(section.references)}
              </div>
            </section>
          );
        })}
      </>
    );
  }, []);

  const renderArticleSectionImages = useCallback((images: ArticleImage[]) => {
    if (!images || !images.length) return null;
    return (
      <div className="article-section-images">
        {images.map((image, i) => {
          return (
            <div key={i} className="article-image">
              <img src={image.src} alt={image.alt} />
              <div className="caption-credit">
                {image.caption && (
                  <div className="caption">{image.caption}</div>
                )}
                {image.credit && <div className="credit">{image.credit}</div>}
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  const renderArticleSectionReferences = useCallback(
    (references: ArticleReference[]) => {
      if (references.length <= 3) {
        return (
          <div className="article-section-references">
            {references.map((reference, i) => (
              <a
                href={reference.url}
                target="_blank"
                rel="noreferrer"
                key={i}
                className="reference"
              >
                <div className="reference_link">
                  <ExternalLink />
                </div>
                <div className="spacer">|</div>
                <div className="reference_top">
                  {reference.title !== 'No title found' ? (
                    <div className="title">{parse(reference.title)}</div>
                  ) : (
                    <div className="title"></div>
                  )}
                </div>
                <div className="reference_bottom">
                  <span className="journal">{parse(reference.journal)}</span> |{' '}
                  {reference.year}
                </div>
              </a>
            ))}
          </div>
        );
      } else {
        return (
          <div className="article-section-references">
            <div className="preview-row">
              {references.slice(0, 3).map((reference, i) => (
                <a
                  href={reference.url}
                  target="_blank"
                  rel="noreferrer"
                  key={i + '-' + reference.id}
                  className="reference"
                >
                  <div className="reference_link">
                    <ExternalLink />
                  </div>
                  <div className="spacer">|</div>
                  <div className="reference_top">
                    {reference.title !== 'No title found' ? (
                      <div className="title">{parse(reference.title)}</div>
                    ) : (
                      <div className="title"></div>
                    )}
                  </div>
                  <div className="reference_bottom">
                    <span className="journal">{parse(reference.journal)}</span>{' '}
                    | {reference.year}
                  </div>
                </a>
              ))}
            </div>
            <Accordion className="reference-accordion">
              <AccordionSummary
                aria-controls="references-content"
                id="references-header"
              >
                <div className="button--see-all collapsed">
                  Show All References
                </div>
                <div className="button--see-all expanded">
                  Collapse References
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="article-section-references">
                  {references.slice(3).map((reference, i) => (
                    <a
                      href={reference.url}
                      target="_blank"
                      rel="noreferrer"
                      key={i + '-' + reference.id}
                      className="reference"
                    >
                      <div className="reference_link">
                        <ExternalLink />
                      </div>
                      <div className="spacer">|</div>
                      <div className="reference_top">
                        {reference.title !== 'No title found' ? (
                          <div className="title">{parse(reference.title)}</div>
                        ) : (
                          <div className="title"></div>
                        )}
                      </div>
                      <div className="reference_bottom">
                        <span className="journal">
                          {parse(reference.journal)}
                        </span>{' '}
                        | {reference.year}
                      </div>
                    </a>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      }
    },
    []
  );

  const renderLoadingFrames = () => {
    return (
      <>
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'70%'}
          height={60}
        />
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={200}
        />
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={200}
        />
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={200}
        />
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={200}
        />
      </>
    );
  };

  const renderLastUpdate = () => {
    if (!activeArticle || !activeArticle.sections.length) return null;
    let date = moment(activeArticle.sections[0].createdAt);
    activeArticle.sections.forEach((section) => {
      if (section.updatedAt) {
        const sectionDate = moment(section.updatedAt);
        if (sectionDate.isAfter(date)) {
          date = sectionDate;
        }
      }
    });
    return <p>Last Updated: {date.format('MMMM DD, YYYY')}</p>;
  };

  return (
    <>
      {activeArticle && (
        <div className="article">
          <div className="article_body">
            <div
              className={`article_body_center ${currentUser ? '' : 'is-ms'}`}
            >
              <div className={`article_body_center_card`}>
                <div className="article_body_center_card_content">
                  <h1>{activeArticle.title}</h1>
                  {renderArticleSections(activeArticle.sections)}
                </div>
                <div className="article_body_center_card_footer">
                  {renderLastUpdate()}
                  {currentUser && (
                    <button
                      className="button button--secondary button--report"
                      onClick={() => setReportArticleModalIsOpen(true)}
                    >
                      <AlertTriangle />
                      Report Article
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="article_body_right">
              <ArticleMenu
                articleId={activeArticle.id}
                headings={headings}
                onClick={scrollToSection}
                loading={jumpLoading}
              />
            </div>
          </div>
          <ReportArticle
            activeArticle={activeArticle}
            reportArticleModalIsOpen={reportArticleModalIsOpen}
            setReportArticleModalIsOpen={setReportArticleModalIsOpen}
          />
        </div>
      )}
      {(!activeArticle || articleLoading) && renderLoadingFrames()}
    </>
  );
};

export default ArticlePage;
