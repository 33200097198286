import { useEffect, useState } from 'react';
import { Flashcard } from '../../../../types/Flashcard';
import { markPriorityFlashcards } from '../../../../services/flashcards';
import { enqueueSnackbar } from 'notistack';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import parse from 'html-react-parser';
import { Copy, Info } from 'react-feather';
import Skeleton from '@mui/material/Skeleton';
import OraSwitch from '../../../CustomMUI/Switch';

interface LinkedFlashcardsProps {
  linkedFlashcards: Flashcard[];
  activeVignetteId: string;
  loadingLinkedFlashcards: boolean;
  getLinkedFlashcards: (vignetteId: string) => void;
}

const LinkedFlashcards: React.FC<LinkedFlashcardsProps> = ({
  linkedFlashcards,
  activeVignetteId,
  loadingLinkedFlashcards,
  getLinkedFlashcards,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const primaryExam = useSelector((state: RootState) =>
    state.auth.userInfo?.exams.find((b) => b.primary === true)
  );

  const [flashcardPriorities, setFlashcardPriorities] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (linkedFlashcards.length) {
      const initialState: { [key: string]: boolean } = {};
      linkedFlashcards.forEach((flashcard) => {
        initialState[flashcard.id] = flashcard.isPriority ?? false;
      });
      setFlashcardPriorities(initialState);
    }
  }, [linkedFlashcards]);

  const allAssigned = Object.values(flashcardPriorities).every(
    (priority) => priority === true
  );
  const noneAssigned = Object.values(flashcardPriorities).every(
    (priority) => priority === false
  );

  const handleToggle = (flashcardId: string) => {
    const newPriority = !flashcardPriorities[flashcardId];
    setFlashcardPriorities((prevState) => ({
      ...prevState,
      [flashcardId]: newPriority,
    }));
    togglePriorityCard(flashcardId, newPriority);
  };

  const togglePriorityCard = async (
    flashcardId: string,
    newPriorityValue: boolean
  ) => {
    const response = await markPriorityFlashcards([
      { flashcardId: flashcardId, isPriority: newPriorityValue },
    ]);
    if (response && response.success) {
      enqueueSnackbar(
        newPriorityValue
          ? 'Flashcard assigned for tomorrow.'
          : 'Flashcard unassigned for tomorrow.',
        { autoHideDuration: 3000 }
      );
      await getLinkedFlashcards(activeVignetteId);
    }
  };

  const handleToggleAll = async () => {
    const newAssignAll = noneAssigned; // Assign all if none are assigned, unassign all if some/all are assigned.
    const updatedFlashcards = linkedFlashcards.map((flashcard) => ({
      flashcardId: flashcard.id,
      isPriority: newAssignAll,
    }));

    const response = await markPriorityFlashcards(updatedFlashcards);

    if (response && response.success) {
      const newPriorities: { [key: string]: boolean } = {};
      linkedFlashcards.forEach((flashcard) => {
        newPriorities[flashcard.id] = newAssignAll;
      });

      setFlashcardPriorities(newPriorities);
      enqueueSnackbar(
        newAssignAll
          ? 'All flashcards assigned for tomorrow.'
          : 'All flashcards unassigned for tomorrow.',
        { autoHideDuration: 3000 }
      );
      await getLinkedFlashcards(activeVignetteId);
    }
  };

  const renderSkeletonCards = () => {
    return [...Array(5)].map((_, index) => (
      <div key={index}>
        <Skeleton
          sx={{
            bgcolor: 'rgba(255,255,255,.05)',
            borderRadius: '1rem',
            margin: '.5rem 0',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={105}
        />
      </div>
    ));
  };

  const renderLinkedFlashcards = () => {
    if (!primaryExam?.isUsingFlashcards) return null;
    if (!linkedFlashcards || !linkedFlashcards.length) return null;
    return (
      <div className="linked-flashcards">
        <div className="linked-flashcards_header">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '.75rem',
              width: '100%',
            }}
          >
            <h3>
              <span>Linked Flashcards</span>
              <Tooltip
                title={
                  'When a question is answered incorrectly, related flashcards will be automatically assigned tomorrow to reinforce the topics. You can make changes to the assignment below.'
                }
                enterTouchDelay={0}
              >
                <Info />
              </Tooltip>
            </h3>
            <div>
              <OraSwitch checked={allAssigned} onChange={handleToggleAll} />
            </div>
          </div>
        </div>
        <div className="linked-flashcards_body">
          {loadingLinkedFlashcards && renderSkeletonCards()}
          {!loadingLinkedFlashcards &&
            linkedFlashcards.map((flashcard, index) => {
              if (!flashcard.contents.length || !flashcard.contents[0].front)
                return null;
              return (
                <div
                  className={`linked-card ${
                    flashcardPriorities[flashcard.id] ? 'assigned' : ''
                  }`}
                  key={'flashcard-' + index}
                >
                  <div className="linked-card_front">
                    {parse(flashcard.contents[0].front)}
                  </div>
                  <div className="linked-card_back">
                    {parse(flashcard.contents[0].back)}
                  </div>
                  {currentUser?.role === 'admin' && (
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(flashcard.id);
                      }}
                      className="m-t-1 admin-id"
                    >
                      <Copy />
                      {flashcard.id}
                    </button>
                  )}
                  <Tooltip
                    title={
                      flashcardPriorities[flashcard.id]
                        ? 'Unassign Flashcard for Tomorrow'
                        : 'Assign Flashcard for Tomorrow'
                    }
                    enterTouchDelay={0}
                  >
                    <OraSwitch
                      checked={!!flashcardPriorities[flashcard.id]}
                      onChange={() => handleToggle(flashcard.id)}
                    />
                  </Tooltip>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return renderLinkedFlashcards();
};

export default LinkedFlashcards;
