import axiosInstance from './axiosCreator';
import { StudySession } from '../types/Study';

export const updateStudySession = async (
  sessionId: string,
  sessionInfo: StudySession
) => {
  try {
    const response = await axiosInstance.put(
      `/studySession/${sessionId}`,
      sessionInfo
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get flashcardResponses for a studySession
export const fetchFlashcardResponses = async (sessionId: string) => {
  try {
    const response = await axiosInstance.get(
      `/studySession/${sessionId}/flashcardResponses`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get all study sessions for current user
export const fetchStudySessions = async () => {
  try {
    const response = await axiosInstance.get('/studySession');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get the first session of an exam for current user
export const fetchFirstStudySession = async (examId: string) => {
  try {
    const response = await axiosInstance.get(
      `/studySession/${examId}/firstExamSession`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get all sessions of an exam for current user
export const fetchAllExamSessions = async (examId: string) => {
  try {
    const response = await axiosInstance.get(
      `/studySession/${examId}/sessions`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get all sessions of an exam with blocks for current user
export const fetchAllExamSessionsAndBlocks = async (examId: string) => {
  try {
    const response = await axiosInstance.get(
      `/studySession/${examId}/sessionAndBlocks`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get all study sessions for current user
export const fetchStudyStreak = async (examId: string) => {
  try {
    const response = await axiosInstance.get(`/studySession/${examId}/streak`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
