import axiosInstance from './axiosCreator';

interface FlashcardUpdateData {
  front: string;
  back: string;
  explanation: string;
  isQAed: boolean;
}

export const getVignettesFromFlashcard = async (flashcardId: string) => {
  try {
    const response = await axiosInstance.get(
      `/flashcards/${flashcardId}/vignettes`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Fetch Flashcard Content Completion
export const fetchFlashcardCompletion = async (
  userId: string,
  examId: string
) => {
  try {
    const response = await axiosInstance.post(
      `/flashcards/flashcardContentCompletion`,
      {
        userId: userId,
        examId: examId,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Mark a flashcard as priority or not
export const markPriorityFlashcards = async (
  flashcards: Array<{ flashcardId: string; isPriority: boolean }>
) => {
  try {
    const response = await axiosInstance.post(
      `/flashcards/priority`,
      flashcards
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Update flashcard variant
export const updateFlashcardVariant = async (
  variantId: string,
  data: FlashcardUpdateData
) => {
  try {
    const response = await axiosInstance.put(`/flashcards/${variantId}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get all suspended flashcards
export const getSuspendedFlashcards = async () => {
  try {
    const response = await axiosInstance.get(`/flashcards/suspended`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Suspend a flashcard
export const suspendFlashcard = async (flashcardId: string) => {
  try {
    const response = await axiosInstance.post(
      `/flashcards/${flashcardId}/suspend`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Un-suspend a flashcard
export const unsuspendFlashcard = async (flashcardId: string) => {
  try {
    const response = await axiosInstance.post(
      `/flashcards/${flashcardId}/unsuspend`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
