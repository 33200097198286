import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Flashcard } from '../../types/Flashcard';

interface PastFlashcardInfo {
  flashcard: Flashcard;
  type: string;
}

const initialState: PastFlashcardInfo[] = [];

export const pastFlashcardsSlice = createSlice({
  name: 'pastFlashcards',
  initialState,
  reducers: {
    addPastFlashcard: (state, action: PayloadAction<PastFlashcardInfo>) => {
      state.unshift(action.payload);
      // Remove duplicates based on flashcard ID
      state.filter(
        (pastFlashcard, index, self) =>
          index ===
          self.findIndex((t) => t.flashcard.id === pastFlashcard.flashcard.id)
      );
      // Limit the number of past flashcards to 20
      state.splice(20);
    },
    shiftPastFlashcard: (state) => {
      state.shift();
    },
    removeFromPast: (state, action: PayloadAction<string>) => {
      state = state.filter(
        (flashcardObj) => flashcardObj.flashcard.id !== action.payload
      );
    },
    clearPastFlashcards: (state) => {
      state.splice(0);
    },
  },
});

export const {
  addPastFlashcard,
  shiftPastFlashcard,
  removeFromPast,
  clearPastFlashcards,
} = pastFlashcardsSlice.actions;

export default pastFlashcardsSlice.reducer;
