import React from 'react';
import { ArrowRight } from 'react-feather';
import { hapticsImpactLight } from '../../utils/haptics';
import { TriangleRight } from '../../assets/svgs/TriangleRight';
import { TriangleDown } from '../../assets/svgs/TriangleDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const Roadmap: React.FC = () => {
  return (
    <div className="roadmap">
      <h1 className="m-t-0">Ora Development Roadmap</h1>
      <p>Take a look at the future of Ora.</p>
      <div className="table-wrapper">
        <div className="table-column">
          <div className="table-column_header">Upcoming</div>
          <div className="table-column_body">
            <div className="ticket">
              <div className="ticket_title">Extend Daily Session</div>
              <div className="ticket_description">
                Study beyond your daily assignment.
              </div>
            </div>
            <button
              className="ticket ticket--feedback"
              onClick={() => {
                window.Intercom('showNewMessage');
                hapticsImpactLight();
              }}
            >
              <div className="ticket_title">Your Feature Here</div>
              <div className="ticket_description">
                Tell us what you want to see in Ora.
              </div>
            </button>
          </div>
        </div>
        <div className="table-column">
          <div className="table-column_header">In Progress</div>
          <div className="table-column_body">
            <div className="ticket">
              <div className="ticket_title">Study Reminders</div>
              <div className="ticket_description">
                Get notified when it's time to study.
              </div>
            </div>
            <div className="ticket">
              <div className="ticket_title">Android App</div>
              <div className="ticket_description">
                Use Ora on your Android devices.
              </div>
            </div>
          </div>
        </div>
        <div className="table-column">
          <div className="table-column_header">Done</div>
          <div className="table-column_body table-column_body--done">
            <div className="ticket">
              <div className="ticket_title">Suspend Flashcard</div>
              <div className="ticket_description">
                Remove a flashcard from your due content.
              </div>
            </div>
            <div className="ticket">
              <div className="ticket_title">Content Library</div>
              <div className="ticket_description">
                View all your study material in one place.
              </div>
            </div>
            <div className="ticket">
              <div className="ticket_title">Snooze Daily Session</div>
              <div className="ticket_description">
                Snooze your session until tomorrow. Keep your streak.
              </div>
            </div>
            <div className="ticket">
              <div className="ticket_title">Review Past QBank Blocks</div>
              <div className="ticket_description">
                Review previous QBank blocks.
              </div>
            </div>
            <div className="ticket">
              <div className="ticket_title">Question Bank Highlighting</div>
              <div className="ticket_description">
                Highlight Qbank questions to mark important information.
              </div>
            </div>
            <Accordion>
              <AccordionSummary
                aria-controls="more-roadmap-content"
                id="more-roadmap-header"
                sx={{ padding: 0 }}
              >
                <div className="row">
                  <div className="icon">
                    <TriangleRight classes="expand-icon expand-icon--closed" />
                    <TriangleDown classes="expand-icon expand-icon--open" />
                  </div>
                  <span className="button button--link--glass p-l-0">
                    Show More
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="table-column-accordion">
                  <div className="ticket">
                    <div className="ticket_title">Topic Customization</div>
                    <div className="ticket_description">
                      Filter exams to study specific topics.
                    </div>
                  </div>
                  <div className="ticket">
                    <div className="ticket_title">Improved Dashboard</div>
                    <div className="ticket_description">
                      A better look into your progress and performance.
                    </div>
                  </div>
                  <div className="ticket">
                    <div className="ticket_title">
                      Scheduling Algorithm Improvements
                    </div>
                    <div className="ticket_description">
                      More accurate scheduling for your study sessions.
                    </div>
                  </div>
                  <div className="ticket">
                    <div className="ticket_title">iOS App</div>
                    <div className="ticket_description">
                      Releasing on July 22nd, 2024.
                    </div>
                  </div>
                  <div className="ticket">
                    <div className="ticket_title">Tutor Mode</div>
                    <div className="ticket_description">
                      Alternative to Timed mode for QBank.
                    </div>
                  </div>
                  {/* <div className="ticket">
                    <div className="ticket_title">Onboarding Assessment</div>
                    <div className="ticket_description">
                      Jumpstart using Ora with a preliminary exam to test your
                      strengths and weaknesses.
                    </div>
                  </div> */}
                  <div className="ticket">
                    <div className="ticket_title">Ora Beta Launch</div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="roadmap_footer">
        <span>Want to voice your opinion on our roadmap?</span>
        <button
          className="button button--link--glass"
          onClick={() => {
            window.Intercom('showNewMessage');
            hapticsImpactLight();
          }}
        >
          Share Your Thoughts <ArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Roadmap;
