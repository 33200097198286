const EllipsisLoader: React.FC = () => {
  return (
    <div className="ellipsis-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default EllipsisLoader;
