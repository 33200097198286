import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import moment from 'moment';
import { debounce } from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface DataPoint {
  date: string;
  completedFlashcardCount: number;
  isDiagnostic: boolean;
}

interface FlashcardTrendProps {
  data: DataPoint[] | null;
}

const FlashcardTrend: React.FC<FlashcardTrendProps> = ({ data }) => {
  const chartRef = useRef<ChartJS<'line', number[], string> | null>(null);

  const sortedData = data ? [...data] : [];
  sortedData.sort((a, b) => {
    return moment(a.date).valueOf() - moment(b.date).valueOf();
  });

  let labels: string[] = [];
  let scores: number[] = [];
  let pointRadius: number[] = [];

  if (sortedData.length === 1) {
    const singleDate = moment(sortedData[0].date).format('MMMM D, YYYY');
    const label = sortedData[0].isDiagnostic ? 'Diagnostic' : singleDate;
    labels = ['', label, ''];
    scores = [
      sortedData[0].completedFlashcardCount,
      sortedData[0].completedFlashcardCount,
      sortedData[0].completedFlashcardCount,
    ];
    pointRadius = [0, 3, 0]; // Hide fake points, show actual point
  } else {
    labels = sortedData.map((dp) => {
      if (dp.isDiagnostic) {
        return 'Diagnostic';
      } else {
        return moment(dp.date).format('MMMM D, YYYY');
      }
    });
    scores = sortedData.map((dp) => dp.completedFlashcardCount);
    pointRadius = new Array(scores.length).fill(3); // Show all points
  }

  const diagnosticIndex = labels.indexOf('Diagnostic');
  if (diagnosticIndex !== -1 && sortedData.length > 1) {
    labels.unshift(labels.splice(diagnosticIndex, 1)[0]);
    scores.unshift(scores.splice(diagnosticIndex, 1)[0]);
    pointRadius.unshift(pointRadius.splice(diagnosticIndex, 1)[0]);
  }

  const lineData = {
    labels: labels,
    datasets: [
      {
        label: 'Completed Flashcards',
        data: scores,
        borderColor: '#FEFEFE',
        borderWidth: 1,
        pointRadius: pointRadius,
        backgroundColor: 'transparent', // This will be overridden in useEffect
        fill: true,
        tension: 0.4,
        pointBackgroundColor: '#FEFEFE',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: 'rgba(255,255,255,.3)',
        },
        ticks: {
          color: '#FEFEFE',
          font: {
            family: 'Poppins',
          },
        },
        border: {
          color: '#FEFEFE',
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        grid: {
          display: false,
          color: 'rgba(255,255,255,.3)',
        },
        ticks: {
          stepSize: 10,
          color: '#FEFEFE',
          font: {
            family: 'Poppins',
          },
        },
        border: {
          color: '#FEFEFE',
        },
      },
    },
    animation: {
      onComplete: () => {
        const chart = chartRef.current;
        if (chart) {
          const ctx = chart.ctx;
          const gradient = ctx.createLinearGradient(
            0,
            0,
            0,
            chart.chartArea.bottom
          );
          gradient.addColorStop(0, 'rgba(255, 255, 255, 0.5)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, .02)');
          chart.data.datasets[0].backgroundColor = gradient;
          chart.update();
        }
      },
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [data]);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (chartRef.current) {
        chartRef.current.resize();
      }
    }, 300);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="block block--dashboard qbank-performance">
      <div className="block_body">
        <h2 className="m-t-0">
          <span>Flashcard</span> Trend
        </h2>
        {data && data.length > 0 && (
          <div className="chart-container">
            <Line data={lineData} options={chartOptions} ref={chartRef} />
          </div>
        )}
        {data && data.length === 0 && (
          <div className="no-data">Flashcard trend data will appear here.</div>
        )}
      </div>
    </div>
  );
};

export default FlashcardTrend;
