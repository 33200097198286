import { ArrowRight } from 'react-feather';
import { hapticsImpactLight } from '../../../utils/haptics';
import QBankDonut from '../../Misc/QBankDonut';

interface PastQBankBlockProps {
  title: string;
  blockType: string;
  questionCount: number;
  correctCount: number;
  incorrectCount: number;
  omittedCount: number;
  reviewBlockFunction: () => void;
}

const PastQBankBlock: React.FC<PastQBankBlockProps> = ({
  title,
  blockType,
  questionCount,
  correctCount,
  incorrectCount,
  omittedCount,
  reviewBlockFunction,
}) => {
  return (
    <div className="past-qbank-block">
      <div className="past-qbank-block_body">
        <div className="left">
          <h3>{title}</h3>
          <p>
            <span>{blockType}</span> Mode | {questionCount} Questions
          </p>
          <button
            className="button button--link--glass p-0"
            onClick={() => {
              reviewBlockFunction();
              hapticsImpactLight();
            }}
          >
            Review Block
            <ArrowRight />
          </button>
        </div>
        <div className="right">
          <QBankDonut
            percentage={Math.round((correctCount / questionCount) * 100)}
            data={[
              {
                label: 'Correct',
                value: (correctCount / questionCount) * 100,
              },
              {
                label: 'Incorrect',
                value: (incorrectCount / questionCount) * 100,
              },
              {
                label: 'Omitted',
                value: (omittedCount / questionCount) * 100,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default PastQBankBlock;
