import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Canvas, useFrame, useThree, extend } from '@react-three/fiber';
import * as THREE from 'three';
import { SphereGeometry } from 'three';

extend({ InstancedMesh: THREE.InstancedMesh });

interface ParticleProps {
  count: number;
  radius: number;
  orbit: boolean;
  mousePosition: {
    x: number;
    y: number;
  };
  showKnowledgePlan: boolean;
}

const getColorForParticle = (
  index: number,
  totalCount: number,
  showKnowledgePlan: boolean
): THREE.Color => {
  if (showKnowledgePlan && index < totalCount / 5) {
    return new THREE.Color('#6CC2B2');
  } else {
    return new THREE.Color('white');
  }
};

const Particles: React.FC<
  ParticleProps & {
    gyroRotation: { alpha: number; beta: number; gamma: number };
    hasGyroscope: boolean;
  }
> = ({
  count,
  radius,
  orbit,
  mousePosition,
  gyroRotation,
  hasGyroscope,
  showKnowledgePlan,
}) => {
  const meshRef = useRef<THREE.InstancedMesh>(null);
  const tempObject = new THREE.Object3D();

  const [initialPositions, orbitVelocities, scatterVelocities] = useMemo(() => {
    const positions = [];
    const orbitVels = [];
    const scatterVels = [];

    for (let i = 0; i < count; i++) {
      // Position logic
      const phi = Math.acos(2 * Math.random() - 1) - Math.PI / 2;
      const theta = 2 * Math.PI * Math.random();
      const x = radius * Math.cos(phi) * Math.cos(theta);
      const y = radius * Math.cos(phi) * Math.sin(theta);
      const z = radius * Math.sin(phi);
      positions.push(new THREE.Vector3(x, y, z));

      // Orbit velocity
      orbitVels.push(
        new THREE.Vector3(
          (Math.random() - 0.5) * 0.25,
          (Math.random() - 0.5) * 0.25,
          (Math.random() - 0.5) * 0.25
        )
      );

      // Scatter velocity
      scatterVels.push(
        new THREE.Vector3(
          (Math.random() - 0.5) * 5,
          (Math.random() - 0.5) * 5,
          (Math.random() - 0.5) * 5
        )
      );
    }

    return [positions, orbitVels, scatterVels];
  }, [count, radius]);

  useFrame(() => {
    if (!meshRef.current) return;
    for (let i = 0; i < count; i++) {
      const position = initialPositions[i];
      const orbitVelocity = orbitVelocities[i];
      const scatterVelocity = scatterVelocities[i];
      if (orbit) {
        const distance = position.length();
        if (distance > radius) {
          const normal = position.clone().normalize();
          orbitVelocity.reflect(normal).multiplyScalar(0.95);
          position.setLength(radius);
        }
        position.add(orbitVelocity);
      } else {
        position.add(scatterVelocity);
      }
      tempObject.position.copy(position);
      tempObject.updateMatrix();
      meshRef.current.setMatrixAt(i, tempObject.matrix);
      meshRef.current.setColorAt(
        i,
        getColorForParticle(i, count, showKnowledgePlan)
      );
    }

    if (orbit) {
      const rotationSpeed = 0.0375;
      if (hasGyroscope) {
        meshRef.current.rotation.x =
          THREE.MathUtils.mapLinear(gyroRotation.beta, -180, 180, -1, 1) *
          rotationSpeed;
        meshRef.current.rotation.y =
          THREE.MathUtils.mapLinear(gyroRotation.gamma, -180, 180, -1, 1) *
          rotationSpeed;
        meshRef.current.rotation.z =
          THREE.MathUtils.mapLinear(gyroRotation.alpha, -180, 180, -1, 1) *
          rotationSpeed;
      } else {
        meshRef.current.rotation.x = mousePosition.y * -0.75 * rotationSpeed;
        meshRef.current.rotation.y = mousePosition.x * rotationSpeed;
      }
    }
    meshRef.current.instanceMatrix.needsUpdate = true;
    if (meshRef.current.instanceColor) {
      meshRef.current.instanceColor.needsUpdate = true;
    }
  });

  return (
    <instancedMesh ref={meshRef} args={[undefined, undefined, count]}>
      <primitive attach="geometry" object={new SphereGeometry(2, 16, 16)} />
      <meshBasicMaterial attach="material" />
    </instancedMesh>
  );
};

interface CameraControllerProps {
  isMobile: boolean;
  isScreensaver: boolean;
}

const CameraController: React.FC<CameraControllerProps> = ({
  isMobile,
  isScreensaver,
}) => {
  const { camera } = useThree();
  useEffect(() => {
    if (isMobile) {
      const z = isScreensaver ? 1200 : 600;
      camera.position.set(0, 0, z);
    } else {
      camera.position.set(0, 0, 600);
    }
    return () => {};
  }, [camera, isMobile]);
  return null;
};

interface ThreeOrbProps {
  isScreensaver: boolean;
  showKnowledgePlan?: boolean;
}

const ThreeOrb: React.FC<ThreeOrbProps> = ({
  isScreensaver,
  showKnowledgePlan = false,
}) => {
  const [showMotion, setShowMotion] = useState(true);
  const [orbit] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [hasGyroscope, setHasGyroscope] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [gyroRotation, setGyroRotation] = useState({
    alpha: 0,
    beta: 0,
    gamma: 0,
  });
  const particleCount = 10000;
  const particleRadius = 350;

  const handleMouseMove = (event: MouseEvent) => {
    setMousePosition({
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 2 + 1,
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 900);
      const handleOrientation = (event: DeviceOrientationEvent) => {
        if (event.alpha !== null) {
          setHasGyroscope(true);
        }
        const { alpha, beta, gamma } = event;
        setGyroRotation({
          alpha: alpha || 0,
          beta: beta || 0,
          gamma: gamma || 0,
        });
      };
      const handleResize = () => {
        setIsMobile(window.innerWidth < 900);
      };
      window.addEventListener('resize', handleResize);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('deviceorientation', handleOrientation, true);
      if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        setShowMotion(false);
      }
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <>
      <Canvas style={{ width: '100%', height: '100%' }}>
        <CameraController isMobile={isMobile} isScreensaver={isScreensaver} />
        <ambientLight />
        {showMotion && (
          <Particles
            count={particleCount}
            radius={particleRadius}
            orbit={orbit}
            mousePosition={mousePosition}
            gyroRotation={gyroRotation}
            hasGyroscope={hasGyroscope}
            showKnowledgePlan={showKnowledgePlan}
          />
        )}
      </Canvas>
    </>
  );
};

export default ThreeOrb;
