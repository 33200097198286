import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/User';

interface AuthState {
  userInfo: User | null;
  authToken: string | null;
  refreshToken: string | null;
}

// Initialize tokens from local storage
const authToken = localStorage.getItem('authToken')
  ? localStorage.getItem('authToken')
  : null;
const refreshToken = localStorage.getItem('refreshToken')
  ? localStorage.getItem('refreshToken')
  : null;

const initialState: AuthState = {
  userInfo: null,
  authToken,
  refreshToken,
};

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<User>) => {
      state.userInfo = action.payload;
    },
    clearUserInfo: (state) => {
      state.userInfo = null;
    },
    updateAuthToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem('authToken', action.payload);
      state.authToken = action.payload;
    },
    updateRefreshToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem('refreshToken', action.payload);
      state.refreshToken = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('persist:root');
      state.authToken = null;
      state.refreshToken = null;
      state.userInfo = null;
    },
  },
});

// Exporting the action creators
export const {
  setUserInfo,
  clearUserInfo,
  updateAuthToken,
  updateRefreshToken,
  logout,
} = authSlice.actions;

// Exporting the reducer
export default authSlice.reducer;
