export type RGBColor = {
  r: number;
  g: number;
  b: number;
};

export const interpolateColor = (
  startColor: RGBColor,
  endColor: RGBColor,
  progress: number
): RGBColor => {
  return {
    r: Math.floor(startColor.r + (endColor.r - startColor.r) * progress),
    g: Math.floor(startColor.g + (endColor.g - startColor.g) * progress),
    b: Math.floor(startColor.b + (endColor.b - startColor.b) * progress),
  };
};

export const interpolateGradient = (scrollPercent: number) => {
  // Define your gradient start and end values for both sides
  const startGradient1 = { r: 79, g: 91, b: 158 }; // Color for #4F5B9E
  const endGradient1 = { r: 27, g: 41, b: 52 }; // Color for #1B2934

  const startGradient2 = { r: 223, g: 143, b: 143 }; // Color for #DF8F8F
  const endGradient2 = { r: 5, g: 9, b: 14 }; // Color for #05090E

  // Interpolate the RGB values for both sides of the gradient
  const color1 = interpolateColor(startGradient1, endGradient1, scrollPercent);
  const color2 = interpolateColor(startGradient2, endGradient2, scrollPercent);

  // Return the interpolated gradient string
  return `linear-gradient(67.73deg, rgb(${color1.r}, ${color1.g}, ${color1.b}) 5.33%, rgb(${color2.r}, ${color2.g}, ${color2.b}) 117.24%)`;
};
