import moment from 'moment';
import { Moment } from 'moment';

export const daysUntilExam = (examDate: Moment | string | null): number => {
  if (!examDate) return 0;
  if (typeof examDate === 'string') {
    examDate = moment(examDate);
  }
  const today = moment();
  const examDateEnd = examDate.clone().endOf('day');
  const todayEnd = today.clone().endOf('day');
  let daysRemaining = examDateEnd.diff(todayEnd, 'days');
  if (daysRemaining <= 0) {
    daysRemaining = 1;
  }
  return daysRemaining;
};
