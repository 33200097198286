import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

interface ArticleMenuProps {
  articleId: string;
  headings: { title: string; id: string }[];
  onClick: (id: string) => void;
  loading: boolean;
}

const ArticleMenu: React.FC<ArticleMenuProps> = ({
  articleId,
  headings,
  onClick,
  loading,
}) => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section[id^="spy-"]');
      let currentSection: string | null = null;

      sections.forEach((section) => {
        const sectionElement = section as HTMLElement;
        const sectionTop =
          sectionElement.getBoundingClientRect().top + window.scrollY;
        const sectionHeight = sectionElement.offsetHeight;
        if (window.scrollY >= sectionTop - sectionHeight) {
          currentSection = sectionElement.getAttribute('id');
        }
      });
      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [articleId]);

  return (
    <nav className="article-menu">
      <div className="top">
        <h3>Jump To</h3>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <ul>
          {headings.map((heading, index) => (
            <li
              key={index}
              data-scrollspy-id={'spy-' + index}
              className={activeSection === 'spy-' + index ? 'is-active' : ''}
            >
              <button onClick={() => onClick(heading.id)}>
                {heading.title}
              </button>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

export default ArticleMenu;
