import axiosInstance from './axiosCreator';

// Fetch exams
export const fetchExams = async () => {
  try {
    const response = await axiosInstance.get('/exams');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
