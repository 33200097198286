import axiosInstance from './axiosCreator';
import { User } from '../types/User';
import { StudySession } from '../types/Study';

// Add exam to user
export const addOrUpdateUserExam = async (
  userId: string,
  examId: string,
  examDate: string | null,
  selectedFlashcardCount: number,
  selectedVignetteCount: number,
  isUsingFlashcards: boolean,
  isUsingQbank: boolean,
  hasCompletedRealExam: boolean,
  realExamScore: number | null,
  ignoredExamDate: boolean,
  clearTodaySession?: boolean
) => {
  try {
    const response = await axiosInstance.post(`/users/${userId}/exams`, {
      examId,
      examDate,
      selectedFlashcardCount,
      selectedVignetteCount,
      isUsingFlashcards,
      isUsingQbank,
      hasCompletedRealExam,
      realExamScore,
      ignoredExamDate,
      clearTodaySession,
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

export const changePrimaryExam = async (userId: string, examId: string) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/changePrimaryUserExam`,
      {
        examId,
      }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Get all users
export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get('/users');
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Get a single user by ID
export const getUserById = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Update a user
export const updateUser = async (id: string, userData: Partial<User>) => {
  try {
    const response = await axiosInstance.put(`/users/${id}`, userData);
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Get last study session
export const getLastSession = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/users/${id}/last-session`);
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Create a new study session
export const createNewSession = async (
  userId: string,
  sessionData: StudySession
) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/create-session`,
      sessionData
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Delete a user
export const deleteUser = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/users/${id}`);
    return response;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};
