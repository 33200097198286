import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily:
      '"Noto Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ',
  },
  palette: {
    primary: {
      main: '#111215',
    },
    text: {
      primary: '#545968',
      secondary: '#545968',
    },
    success: {
      main: '#6CC2B2',
    },
    error: {
      main: '#EF3B2F',
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderColor: '#E3E4E8',
          '&.Mui-selected': {
            color: '#FEFEFE',
            backgroundColor: '#6CC2B2',
            '&:hover': {
              color: '#FEFEFE',
              backgroundColor: '#6CC2B2',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'var(--type-body-inverse)',
          backgroundColor: 'var(--surface-glass)',
          borderRadius: '.5rem',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--type-body-inverse)',
          },
        },
        notchedOutline: {
          borderColor: 'transparent',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: '#FEFEFE',
        },
      },
    },
    MuiSpeedDial: {
      styleOverrides: {
        root: {
          zIndex: 1,
        },
        fab: {
          color: '#111215',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:focus': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: '#111215',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:focus': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#6CC2B2',
        },
        thumb: {
          color: '#6CC2B2',
        },
        track: {
          color: '#6CC2B2',
        },
        rail: {
          color: '#E3E4E8',
        },
        mark: {
          color: 'rgba(255,255,255,.3)',
          height: 8,
          width: 2,
          top: '1.5rem',
        },
        markLabel: {
          color: '#FEFEFE',
          fontSize: '0.75rem',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#6CC2B2',
            '& + .MuiSwitch-track': {
              backgroundColor: '#6CC2B2',
              opacity: 1,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            color: 'var(--type-body-inverse)',
            opacity: 0.5,
            WebkitTextFillColor: 'var(--type-body-inverse)',
          },
        },
        icon: {
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  typography: {
    fontFamily:
      '"Noto Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ',
  },
  palette: {
    primary: {
      main: '#111215',
    },
    text: {
      primary: '#FEFEFE',
      secondary: '#FEFEFE',
    },
    success: {
      main: '#6CC2B2',
    },
    error: {
      main: '#EF3B2F',
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: '#FEFEFE',
          borderColor: 'rgba(255,255,255,.1)',
          '&.Mui-selected': {
            color: '#FEFEFE',
            backgroundColor: '#6CC2B2',
            '&:hover': {
              color: '#FEFEFE',
              backgroundColor: '#6CC2B2',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--surface-glass)',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--type-body-inverse)',
          },
        },
        notchedOutline: {
          borderColor: 'transparent',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#FEFEFE',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            color: 'var(--type-body-inverse)',
            opacity: 0.5,
            WebkitTextFillColor: 'var(--type-body-inverse)',
          },
        },
        icon: {
          color: 'var(--type-body-inverse)',
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#2A333B',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,.2)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(255,255,255,.3)',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,.2)',
            },
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: '#FEFEFE',
        },
      },
    },
    MuiSpeedDial: {
      styleOverrides: {
        root: {
          zIndex: 1,
        },
        fab: {
          color: '#FEFEFE',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:focus': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: '#FEFEFE',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:focus': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#6CC2B2',
        },
        thumb: {
          color: '#6CC2B2',
        },
        track: {
          color: '#6CC2B2',
        },
        rail: {
          color: '#E3E4E8',
        },
        mark: {
          color: 'rgba(255,255,255,.3)',
          height: 8,
          width: 2,
          top: '1.5rem',
        },
        markLabel: {
          color: '#FEFEFE',
          fontSize: '0.75rem',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#6CC2B2',
            '& + .MuiSwitch-track': {
              backgroundColor: '#6CC2B2',
              opacity: 1,
            },
          },
        },
        thumb: {
          backgroundColor: '#FEFEFE !important',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        backdrop: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
      },
    },
  },
});
