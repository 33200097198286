import axiosInstance from './axiosCreator';

// Get flashcard explanation from fact
export const fetchFlashcardExplanation = async (factId: string) => {
  try {
    const response = await axiosInstance.get(
      `/facts/${factId}/flashcardExplanation`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
