import React, { useEffect, useState, useCallback } from 'react';
import { TopicTreeNode } from '../../types/TopicCustomization';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { hapticsImpactLight } from '../../utils/haptics';
import { fetchLibraryTopicTree } from '../../services/library';
import { fetchArticle } from '../../services/library';
import { Article } from '../../types/Article';
import { Menu, X, Search as SearchIcon } from 'react-feather';
import { TriangleRight } from '../../assets/svgs/TriangleRight';
import { TriangleDown } from '../../assets/svgs/TriangleDown';
import useWindowWidth from '../../hooks/useWindowWidth';
import Search from '../../components/Library/Search';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ArticlePage from '../ArticlePage';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import MSFooter from '../../components/NonAuth/MSFooter';
import MSHeader from '../../components/NonAuth/MSHeader';
import ThreeWave from '../../components/Home/ThreeWave';
import { updateCurrentUserInfo } from '../../services/auth';
import { setUserInfo } from '../../slices/auth/authSlice';
import { CollapseArrow } from '../../assets/svgs/CollapseArrow';
import Tooltip from '@mui/material/Tooltip';

interface LibraryProps {
  isPanel: boolean;
  passedArticleId?: string | null;
  expandStudyPanel?: (expand: boolean) => void;
}

const Library: React.FC<LibraryProps> = ({
  isPanel,
  passedArticleId,
  expandStudyPanel,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const [activeArticle, setActiveArticle] = useState<Article | null>(null);
  const [topics, setTopics] = useState<TopicTreeNode[]>([]);
  const [particleTopics, setParticleTopics] = useState<
    { name: string; id: string }[]
  >([]);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [searchFlyoutIsOpen, setSearchFlyoutIsOpen] = useState(false);
  const [articleLoading, setArticleLoading] = useState<boolean>(false);
  const [expandedPanels, setExpandedPanels] = useState<{
    [key: string]: string | null;
  }>({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const width = useWindowWidth();

  const { articleId } = useParams<{ articleId: string }>();

  useHotkeys(
    '/',
    (e) => {
      e.preventDefault();
      if (activeArticle) {
        setSearchFlyoutIsOpen(true);
      }
    },
    { keyup: true }
  );
  useHotkeys(
    't',
    () => {
      if (currentUser) {
        updateTheme(!currentUser?.darkMode);
      }
    },
    { keyup: true }
  );

  useEffect(() => {
    loadTopics();
  }, []);

  useEffect(() => {
    if (!isPanel) {
      const handleLinkClick = (e: MouseEvent) => {
        const target = e.target as HTMLAnchorElement;
        if (target.tagName === 'A' && target.href.includes('/library/')) {
          const urlParts = target.href.split('/library/');
          if (urlParts.length === 2) {
            const articleId = urlParts[1];
            e.preventDefault();
            loadArticleById(articleId);
          }
        }
      };
      document.addEventListener('click', handleLinkClick);
      return () => {
        document.removeEventListener('click', handleLinkClick);
      };
    }
  }, [isPanel]);

  // Load Article by Passed ID
  useEffect(() => {
    if (passedArticleId) {
      loadArticleById(passedArticleId);
    }
  }, [passedArticleId]);

  // Load Article by URL
  useEffect(() => {
    if (articleId) {
      loadArticleById(articleId);
    }
  }, [articleId]);

  useEffect(() => {
    if (mobileMenuIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [mobileMenuIsOpen]);

  useEffect(() => {
    if (searchFlyoutIsOpen) {
      setTimeout(() => {
        const input = document.getElementById('library-search-input');
        if (input) {
          input.focus();
        }
      }, 50);
    }
  }, [searchFlyoutIsOpen]);

  useEffect(() => {
    setMobileMenuIsOpen(false);
  }, [width]);

  const loadTopics = async () => {
    const response = await fetchLibraryTopicTree();
    if (response) {
      setTopics(response);
      const leafTopics: { name: string; id: string }[] = [];
      const getLeafTopics = (topics: TopicTreeNode[]) => {
        topics.forEach((topic) => {
          if (topic.children && topic.children.length > 0) {
            getLeafTopics(topic.children);
          } else {
            if (topic.articleId) {
              leafTopics.push({ name: topic.name, id: topic.articleId });
            }
          }
        });
      };
      getLeafTopics(response);
      const randomTopics = leafTopics
        .sort(() => 0.5 - Math.random())
        .slice(0, 100);
      setParticleTopics(randomTopics);
    }
  };

  const handleAccordionChange = useCallback(
    (level: number, panel: string) => () => {
      setExpandedPanels((prev) => ({
        ...prev,
        [`${level}`]: prev[`${level}`] === panel ? null : panel,
      }));
      hapticsImpactLight();
    },
    []
  );

  const updateTheme = async (darkMode: boolean) => {
    const updateUser = await updateCurrentUserInfo({ darkMode: darkMode });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const loadArticleById = async (id: string | null) => {
    if (!id) {
      alert('Article not found');
      setActiveArticle(null);
      return;
    }
    setArticleLoading(true);
    const article = await fetchArticle(id);
    if (article) {
      setActiveArticle(article);
    } else {
      alert('Article not found');
      setActiveArticle(null);
    }
    setArticleLoading(false);
    window.scrollTo(0, 0);
  };

  const handleClickTopic = (articleId: string | null) => {
    if (!articleId) {
      alert('Article not found');
      setActiveArticle(null);
      return;
    }
    setMobileMenuIsOpen(false);
    navigate(`/library/${articleId}`);
  };

  const renderTopicTree = useCallback(
    (topics: TopicTreeNode[], level: number) => {
      if (!topics || !topics.length) return null;

      return topics.map((t, i) => {
        const panelId = `p${level}${i}-${t.name.replace(/\s+/g, '').toLowerCase()}`;
        const isExpanded = expandedPanels[`${level}`] === panelId;

        if (t.children && t.children.length > 0) {
          return (
            <Accordion
              key={i}
              expanded={isExpanded}
              onChange={handleAccordionChange(level, panelId)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                sx={{
                  padding: 0,
                }}
                aria-controls={`${panelId}-content`}
                id={`${panelId}-header`}
              >
                {t.children && t.children.length > 0 && (
                  <>
                    {!isExpanded && <TriangleRight classes="expand-icon" />}
                    {isExpanded && <TriangleDown classes="expand-icon" />}
                  </>
                )}
                <div className="row">
                  <div>{t.name}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={`level-${level}`}>
                  {renderTopicTree(t.children, level + 1)}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        } else {
          return (
            <div
              className={`article-row ${t.articleId === activeArticle?.id ? 'is-active' : ''}`}
              key={i}
              id={`${panelId}-header`}
            >
              <button
                onClick={() => {
                  if (isPanel) {
                    loadArticleById(t.articleId);
                  } else {
                    handleClickTopic(t.articleId);
                  }
                }}
              >
                {t.name}
              </button>
            </div>
          );
        }
      });
    },
    [expandedPanels, handleAccordionChange, activeArticle]
  );

  const topicTree = renderTopicTree(topics, 0);

  return (
    <div className={`library ${isPanel ? 'is-panel' : ''}`}>
      {currentUser && !isPanel && (
        <div
          className={`library_header ${activeArticle ? 'library_header--article' : 'library_header--library'}`}
        >
          <div className="library_header_left">
            <button
              className="button button--open-menu button--icon-only button--glass--link"
              onClick={() => setMobileMenuIsOpen(true)}
            >
              <Menu />
            </button>
            <button
              className="button button--library-home"
              onClick={() => setActiveArticle(null)}
            >
              <h1>Library</h1>
            </button>
          </div>
          {currentUser && (
            <button
              onClick={() => {
                navigate('/');
                hapticsImpactLight();
              }}
              className="button button--close button--icon-only button--glass--link"
            >
              <X />
            </button>
          )}
        </div>
      )}
      {currentUser && isPanel && activeArticle && (
        <div className="library_panel-header">
          <div style={{ display: 'flex', alignItems: 'center', gap: '.75rem' }}>
            <Tooltip title="Close - Esc" enterTouchDelay={0}>
              <button
                className="button button--collapse"
                onClick={() => {
                  if (expandStudyPanel) {
                    expandStudyPanel(false);
                  }
                  hapticsImpactLight();
                }}
              >
                <CollapseArrow />
              </button>
            </Tooltip>
            <h3>Library</h3>
          </div>
          <button
            className="button button--fake-search"
            onClick={() => {
              setSearchFlyoutIsOpen(true);
              hapticsImpactLight();
            }}
          >
            <SearchIcon />
            <span>Search for something...</span>
            <div className="hint">/</div>
          </button>
        </div>
      )}
      {!currentUser && (
        <MSHeader
          articlePage={!!activeArticle}
          setMobileMenuIsOpen={setMobileMenuIsOpen}
        />
      )}
      <div className="library_body">
        {!activeArticle && (
          <div className="library_body_landing">
            <h1 className="m-t-0">Library</h1>
            <div className="topic-list">
              <h2>Topics</h2>
              <Search
                setMobileMenuIsOpen={setMobileMenuIsOpen}
                setSearchFlyoutIsOpen={setSearchFlyoutIsOpen}
                loadArticleById={loadArticleById}
                isPanel={isPanel}
              />
              {topicTree ? (
                <div className="topic-accordion">{topicTree}</div>
              ) : (
                <CircularProgress
                  sx={{
                    display: 'block',
                    margin: '1rem auto 0 auto',
                    color: 'var(--type-body-inverse)',
                    height: '1rem !important',
                    width: '1rem !important',
                  }}
                />
              )}
            </div>
            {particleTopics.length > 0 && !isPanel && (
              <ThreeWave
                showLabels={true}
                transitionStarted={false}
                topics={particleTopics}
              />
            )}
          </div>
        )}
        {activeArticle && (
          <div className="library_body_article">
            <div
              className={`library_body_article_left ${mobileMenuIsOpen ? 'mobile-menu-is-open' : ''}`}
            >
              <div className="topic-list">
                <h2>Topics</h2>
                <button
                  onClick={() => {
                    setMobileMenuIsOpen(false);
                    hapticsImpactLight();
                  }}
                  className="button button--close"
                >
                  <X />
                </button>
                <button
                  className="button button--fake-search"
                  onClick={() => {
                    setSearchFlyoutIsOpen(true);
                    hapticsImpactLight();
                  }}
                >
                  <SearchIcon />
                  <span>Search for something...</span>
                  <div className="hint">/</div>
                </button>
                {topicTree ? (
                  <div className="topic-accordion">{topicTree}</div>
                ) : (
                  <CircularProgress
                    sx={{
                      display: 'block',
                      margin: '1rem auto 0 auto',
                      color: 'var(--type-body-inverse)',
                      height: '1rem !important',
                      width: '1rem !important',
                    }}
                  />
                )}
              </div>
            </div>
            <div className="library_body_article_main">
              <ArticlePage
                activeArticle={activeArticle}
                articleLoading={articleLoading}
              />
            </div>
          </div>
        )}
      </div>
      {!currentUser && <MSFooter />}
      <Modal
        open={searchFlyoutIsOpen}
        onClose={() => setSearchFlyoutIsOpen(false)}
      >
        <div className="modal modal--glass modal--library-search">
          <Search
            setMobileMenuIsOpen={setMobileMenuIsOpen}
            setSearchFlyoutIsOpen={setSearchFlyoutIsOpen}
            loadArticleById={loadArticleById}
            isPanel={isPanel}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Library;
