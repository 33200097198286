import axios from 'axios';
import { refreshToken } from './auth';
import { jwtDecode } from 'jwt-decode';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to check if the token is expired
const isTokenExpired = (token: string) => {
  if (!token) return true;
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  // @ts-expect-error TODO: fix
  return decodedToken?.exp < currentTime;
};

axiosInstance.interceptors.request.use(async (config) => {
  let token = localStorage.getItem('authToken');

  // If the token is expired, refresh it
  if (token && isTokenExpired(token)) {
    console.log('Token expired, refreshing...');
    const currentRefreshToken =
      localStorage.getItem('refreshToken') || 'placeholder';

    try {
      const data = await refreshToken(currentRefreshToken);
      const newAuthToken = data?.token;
      const newRefreshToken = data?.refreshToken;

      if (newAuthToken && newRefreshToken) {
        console.log('Refreshed tokens');
        localStorage.setItem('authToken', newAuthToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        token = newAuthToken;
      } else {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/auth';
        return Promise.reject(new Error('Failed to refresh token'));
      }
    } catch (error) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      window.location.href = '/auth';
      return Promise.reject(error);
    }
  }

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalRequest._retry &&
      originalRequest.url !== 'auth/refresh'
    ) {
      console.log('TRYING TO REFRESH TOKENS');
      originalRequest._retry = true;
      const currentRefreshToken =
        localStorage.getItem('refreshToken') || 'placeholder';

      const data = await refreshToken(currentRefreshToken);

      const newAuthToken = data?.token;
      const newRefreshToken = data?.refreshToken;

      if (newAuthToken && newRefreshToken) {
        console.log('REFRESHED TOKENS');
        localStorage.setItem('authToken', newAuthToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        axiosInstance.defaults.headers.common['Authorization'] =
          `Bearer ${newAuthToken}`;
        return axiosInstance(originalRequest);
      } else {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/auth';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
