import { Capacitor } from '@capacitor/core';
import { enqueueSnackbar } from 'notistack';

export const renderHint = (key: string, message: string, duration = 3000) => {
  if (
    !localStorage.getItem(`show-${key}-hint`) &&
    !Capacitor.isNativePlatform() &&
    window.innerWidth > 560
  ) {
    enqueueSnackbar(message, {
      autoHideDuration: duration,
    });
    localStorage.setItem(`show-${key}-hint`, 'true');
  }
};
