import { useState, useEffect, useCallback, useRef } from 'react';

const useIdle = (idleTime: number = 5000, enabled: boolean = true) => {
  const [isIdle, setIsIdle] = useState(false);
  const idleTimer = useRef<NodeJS.Timeout | null>(null);

  const resetIdleTimer = useCallback(() => {
    if (idleTimer.current) {
      clearTimeout(idleTimer.current);
    }
    setIsIdle(false);
    idleTimer.current = setTimeout(() => {
      setIsIdle(true);
    }, idleTime);
  }, [idleTime]);

  const handleActivity = useCallback(() => {
    if (!enabled) return;
    resetIdleTimer();
  }, [enabled, resetIdleTimer]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      if (idleTimer.current) {
        clearTimeout(idleTimer.current);
      }
      setIsIdle(false);
    } else if (document.visibilityState === 'visible') {
      resetIdleTimer();
    }
  }, [resetIdleTimer]);

  useEffect(() => {
    if (!enabled) {
      if (idleTimer.current) {
        clearTimeout(idleTimer.current);
      }
      setIsIdle(false);
      return;
    }

    const events = [
      'mousemove',
      'mousedown',
      'keypress',
      'scroll',
      'touchstart',
    ];

    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    document.addEventListener('visibilitychange', handleVisibilityChange);

    resetIdleTimer(); // Start the initial idle timer

    return () => {
      if (idleTimer.current) {
        clearTimeout(idleTimer.current);
      }
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [enabled, handleActivity, handleVisibilityChange, resetIdleTimer]);

  return { isIdle, resetIdleTimer };
};

export default useIdle;
