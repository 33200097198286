import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SubPage from '../../components/SubPage';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import moment from 'moment';
import PastQBankBlock from '../../components/Global/PastQBankBlock';
import { Block, StudySessionWithBlocks } from '../../types/Study';
import { fetchAllExamSessionsAndBlocks } from '../../services/studySession';
import { useRefreshStudySessionAndContent } from '../../utils/refreshStudySession';

const PastBlocks: React.FC = () => {
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const [pastSessionsWithBlocks, setPastSessionsWithBlocks] = useState([]);

  const { loadStudySessionAndContentBySessionId } =
    useRefreshStudySessionAndContent();

  const navigate = useNavigate();

  useEffect(() => {
    fetchPastStudySessionsWithBlocks();
  }, [primaryExam]);

  const fetchPastStudySessionsWithBlocks = async () => {
    if (primaryExam) {
      fetchAllExamSessionsAndBlocks(primaryExam.id).then((response) => {
        setPastSessionsWithBlocks(response);
      });
    }
  };

  const loadPreviousStudySession = async (
    sessionId: string,
    blockId: string
  ) => {
    loadStudySessionAndContentBySessionId(sessionId, true, blockId).then(() => {
      navigate('/study');
    });
  };

  const renderPastSessions = () => {
    const filteredSessions = pastSessionsWithBlocks.filter(
      (session: StudySessionWithBlocks) => session.blocks.length > 0
    );

    if (filteredSessions.length === 0) {
      return (
        <div style={{ color: 'var(--type-body-inverse)' }}>
          No past study sessions available.
        </div>
      );
    } else {
      return filteredSessions.map((session: StudySessionWithBlocks) => {
        return (
          <>
            <h2>{moment(session.date).format('MMMM DD, YYYY')}</h2>
            {session.blocks
              .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0))
              .map((block: Block) => {
                return (
                  <PastQBankBlock
                    key={block.id}
                    title={`Block ${(block.ord ?? 0) + 1}`}
                    blockType={block.type}
                    questionCount={block.vignetteCount}
                    correctCount={block.correctCount}
                    incorrectCount={block.incorrectCount}
                    omittedCount={block.omittedCount}
                    reviewBlockFunction={() => {
                      //   fadeInFlashLoader(flashLoaderRef);
                      if (moment(session.date).isSame(moment(), 'day')) {
                        localStorage.setItem('ora-today-block-id', block.id);
                        navigate('/study');
                      } else {
                        loadPreviousStudySession(session.id, block.id);
                      }
                    }}
                  />
                );
              })}
          </>
        );
      });
    }
  };

  return (
    <div className="past-blocks">
      <SubPage
        title="Previous blocks"
        examName={primaryExam?.name || ''}
        close={() => navigate('/performance')}
      >
        <>{renderPastSessions()}</>
      </SubPage>
    </div>
  );
};

export default PastBlocks;
