import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TopicTreeNode } from '../../types/TopicCustomization';

interface ActiveTopicsState {
  allTopicsActive: boolean;
  allTopicsInactive: boolean;
  topicTree: TopicTreeNode[];
}

const initialState: ActiveTopicsState = {
  allTopicsActive: true,
  allTopicsInactive: false,
  topicTree: [],
};

const activeTopicsSlice = createSlice({
  name: 'activeTopics',
  initialState,
  reducers: {
    setActiveFilters: (_state, action: PayloadAction<ActiveTopicsState>) => {
      return action.payload;
    },
  },
});

export const { setActiveFilters } = activeTopicsSlice.actions;

export default activeTopicsSlice.reducer;
