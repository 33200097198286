import React from 'react';
import { Menu } from 'react-feather';
import OraLogo from '../../Global/OraLogo';

interface MSHeaderProps {
  articlePage: boolean;
  setMobileMenuIsOpen: (isOpen: boolean) => void;
}

const MSHeader: React.FC<MSHeaderProps> = ({
  articlePage,
  setMobileMenuIsOpen,
}) => {
  return (
    <header className={`ms-header ${articlePage ? 'is-article' : ''}`}>
      <div className="ms-header_left">
        {articlePage && (
          <button
            className="button button--open-menu button--icon-only button--glass--link"
            onClick={() => setMobileMenuIsOpen(true)}
          >
            <Menu />
          </button>
        )}
        <OraLogo type="link" url={'https://www.oraai.com/'} />
      </div>
      <div className="links">
        <a
          className="button button--link"
          href="https://app.oraai.com/"
          type="button"
        >
          Sign In
        </a>
        <a
          className="button button--glass"
          href="https://app.oraai.com/auth?signup=true"
          type="button"
        >
          Get Started
        </a>
      </div>
    </header>
  );
};

export default MSHeader;
