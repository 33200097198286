export const AlarmClock = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4517_1400)">
        <path
          d="M12 6V12L16 14"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.26473 5.04318L3.91121 2.17208C4.28552 1.76599 4.91816 1.74023 5.32425 2.11455L6.18535 2.90828C4.50687 4.04144 3.17633 5.48492 2.18335 7.24995L1.32225 6.45623C0.916166 6.08191 0.89041 5.44927 1.26473 5.04318ZM6.00201 1.37926L7.06626 2.36026L7.32277 2.5967C8.73118 1.89479 10.3195 1.5 12 1.5C13.6805 1.5 15.2689 1.89481 16.6773 2.59674L16.9339 2.36026L17.9981 1.37926C18.8103 0.630632 20.0756 0.682144 20.8242 1.49432L23.4707 4.36543C24.2193 5.1776 24.1678 6.44288 23.3556 7.19151L22.2914 8.1725L21.9108 8.52335C22.2924 9.61147 22.5 10.7815 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 10.7815 1.70757 9.61141 2.08928 8.52326L1.70875 8.1725L0.644494 7.19151C-0.167679 6.44288 -0.21919 5.1776 0.529441 4.36543L3.17592 1.49432C3.92455 0.682144 5.18983 0.630632 6.00201 1.37926ZM22.7354 5.04318L20.0889 2.17208C19.7146 1.76599 19.082 1.74023 18.6759 2.11455L17.8148 2.90828C19.4933 4.04144 20.8238 5.48492 21.8168 7.24995L22.6779 6.45623C23.084 6.08191 23.1097 5.44927 22.7354 5.04318ZM12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4517_1400">
          <rect width="24" height="24" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
};
