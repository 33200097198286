export const Intercom = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.42857 13.1111C7.42857 13.1111 8.85432 14.7778 12 14.7778C15.1457 14.7778 16.5714 13.1111 16.5714 13.1111M16.5714 18.6667H6.28571C5.02335 18.6667 4 17.6717 4 16.4444V4.22222C4 2.99492 5.02335 2 6.28571 2H17.7143C18.9767 2 20 2.99492 20 4.22222V21L16.5714 18.6667Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
