import SessionOverview from './SessionOverview';
import Ring from '../../../assets/images/ring.svg';
import {
  hapticsImpactLight,
  hapticsImpactMedium,
} from '../../../utils/haptics';
import { StartType } from '../../../types/Study';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateStudySession } from '../../../services/studySession';
import { setTodaySession } from '../../../slices/todaySession/todaySessionSlice';
import { AlarmClock } from '../../../assets/svgs/AlarmClock';
import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { posthog } from 'posthog-js';

interface StartProps {
  startTransition?: () => void;
  isStarted: boolean;
  type: StartType;
  action?: () => void;
  isSwitchingExam?: boolean;
  isLoadedStudySessionAndContent: boolean;
  isLoadingStudySessionAndContent: boolean;
}

const Start: React.FC<StartProps> = ({
  startTransition,
  isStarted,
  type,
  action,
  isSwitchingExam,
  isLoadedStudySessionAndContent,
  isLoadingStudySessionAndContent,
}) => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const [hasClickedSnoozed, setHasClickedSnoozed] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const clickedSnooze = localStorage.getItem('ora-clickedSnooze');
    if (clickedSnooze === 'true') {
      setHasClickedSnoozed(true);
    }
  }, []);

  const handleSnoozeSession = async () => {
    const updatedSession = { ...todaySession, isSnoozed: true };
    const response = await updateStudySession(todaySession.id, updatedSession);
    posthog?.capture('study_session_snooze');
    dispatch(setTodaySession(response));
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className="start">
        <div className="start_ring">
          <button
            onClick={() => {
              if (type === StartType.Home) {
                hapticsImpactMedium();
                if (startTransition) {
                  startTransition();
                }
              } else {
                if (action) {
                  action();
                }
              }
            }}
            disabled={
              !isLoadedStudySessionAndContent ||
              isLoadingStudySessionAndContent ||
              isSwitchingExam
            }
            className="start_ring_inner"
          >
            <h1 className="start_ring_inner_content">
              <span>{isStarted ? 'Resume' : 'Start'}</span>
            </h1>
            <div className="start_ring_inner_wrapper">
              <img src={Ring} alt="" />
            </div>
          </button>
          <Tooltip
            title={hasClickedSnoozed ? 'Snooze Session' : ''}
            enterTouchDelay={0}
          >
            <button
              className={`button button--snooze ${hasClickedSnoozed ? 'truncated' : ''}`}
              onClick={() => {
                handleSnoozeSession();
                hapticsImpactLight();
                localStorage.setItem('ora-clickedSnooze', 'true');
              }}
            >
              <AlarmClock />
              <span>Snooze Session</span>
            </button>
          </Tooltip>
        </div>
        {type === StartType.Home && (
          <SessionOverview isSwitchingExam={isSwitchingExam} />
        )}
      </div>
    </div>
  );
};

export default Start;
