interface KeybindTooltipProps {
  name: string;
  keyLetter?: string;
  description?: string;
}

const KeybindTooltip: React.FC<KeybindTooltipProps> = ({
  name,
  keyLetter,
  description,
}) => {
  return (
    <div
      className={`keybind-tooltip ${keyLetter && keyLetter.length > 1 ? 'long-key' : ''}`}
    >
      <div className="keybind-tooltip_header">
        <span>{name}</span>
        {keyLetter && (
          <span className="key">
            <span>{keyLetter}</span>
          </span>
        )}
      </div>
      {description && (
        <div className="keybind-tooltip_description">{description}</div>
      )}
    </div>
  );
};

export default KeybindTooltip;
