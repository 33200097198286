import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { Vignette, VignetteContent } from '../../../../types/Vignette';
import { enqueueSnackbar } from 'notistack';
import { updateVignetteVariant } from '../../../../services/vignettes';
import { replaceVignetteVariantContentById } from '../../../../slices/vignettes/vignettesSlice';

interface EditVignetteProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  activeVignette: Vignette;
  activeVignetteVariant: VignetteContent;
  setKeybindsDisabled: (disabled: boolean) => void;
}

const EditVignette: React.FC<EditVignetteProps> = ({
  isEditing,
  setIsEditing,
  activeVignette,
  activeVignetteVariant,
  setKeybindsDisabled,
}) => {
  const [currentVignetteQuestion, setCurrentVignetteQuestion] = useState('');
  const [currentVignetteExplanation, setCurrentVignetteExplanation] =
    useState('');
  const [currentVignetteExhibit, setCurrentVignetteExhibit] = useState('');
  const [
    currentVignetteLearningObjective,
    setCurrentVignetteLearningObjective,
  ] = useState('');
  const [currentAnswerOptions, setCurrentAnswerOptions] = useState(
    activeVignetteVariant.answerOptions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && activeVignetteVariant) {
      setCurrentVignetteQuestion(activeVignetteVariant.question);
      setCurrentVignetteExplanation(activeVignetteVariant.explanation);
      setCurrentVignetteExhibit(activeVignetteVariant?.exhibit || '');
      setCurrentVignetteLearningObjective(
        activeVignetteVariant.learningObjective
      );
      setCurrentAnswerOptions(activeVignetteVariant.answerOptions);
    }
  }, [isEditing, activeVignetteVariant]);

  const handleQuestionTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentVignetteQuestion(event.target.value);
  };

  const handleExplanationTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentVignetteExplanation(event.target.value);
  };

  const handleExhibitTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentVignetteExhibit(event.target.value);
  };

  const handleLearningObjectiveTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentVignetteLearningObjective(event.target.value);
  };

  const handleAnswerOptionChange = (index: number, value: string) => {
    setCurrentAnswerOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = {
        ...updatedOptions[index],
        optionText: value,
      };
      return updatedOptions;
    });
  };

  const submitVariantUpdate = async () => {
    if (!activeVignetteVariant) return;
    const updatedVariant = {
      question: currentVignetteQuestion,
      explanation: currentVignetteExplanation,
      exhibit: currentVignetteExhibit,
      learningObjective: currentVignetteLearningObjective,
      answerOptions: currentAnswerOptions,
      isQAed: true,
    };
    const updatedVignette = await updateVignetteVariant(
      activeVignetteVariant.id,
      updatedVariant
    );
    if (updatedVignette) {
      dispatch(
        replaceVignetteVariantContentById({
          vignetteId: activeVignette.id,
          variantId: updatedVignette.id,
          newQuestion: updatedVignette.question,
          newExplanation: updatedVignette.explanation,
          newExhibit: updatedVignette.exhibit,
          newLearningObjective: updatedVignette.learningObjective,
          newAnswerOptions: updatedVignette.answerOptions,
          newIsQAed: updatedVignette.isQAed,
        })
      );
    }

    enqueueSnackbar('Vignette updated successfully.', {
      autoHideDuration: 3000,
    });
    setIsEditing(false);
    setKeybindsDisabled(false);
  };

  return (
    <Modal
      open={isEditing}
      onClose={() => {
        setIsEditing(false);
        setKeybindsDisabled(false);
      }}
    >
      <div className="modal modal--glass modal--edit-vignette">
        <div className="modal_header">
          <h2>Edit Vignette</h2>
        </div>
        <div className="modal_content">
          <form>
            <div className="left">
              <div className="editor">
                <label htmlFor="editor--vignette-question">Question</label>
                <textarea
                  id="editor--vignette-question"
                  value={currentVignetteQuestion}
                  onChange={handleQuestionTextareaChange}
                ></textarea>
              </div>
              <div className="answer-choices">
                <label>Answers</label>
                {currentAnswerOptions.map((answerOption, index) => (
                  <div
                    key={index}
                    className={`answer-choice ${answerOption.optionLetter === activeVignetteVariant.correctAnswer ? 'correct' : ''}`}
                  >
                    <label htmlFor={`answer-choice-${index}`}>
                      {answerOption.optionLetter}.
                    </label>
                    <input
                      type="text"
                      id={`answer-choice-${index}`}
                      value={answerOption.optionText}
                      onChange={(e) =>
                        handleAnswerOptionChange(index, e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="right">
              <div className="editor">
                <label htmlFor="editor--vignette-explanation">
                  Explanation
                </label>
                <textarea
                  id="editor--vignette-explanation"
                  value={currentVignetteExplanation}
                  onChange={handleExplanationTextareaChange}
                ></textarea>
              </div>
              <div className="editor m-t-0-75">
                <label htmlFor="editor--vignette-exhibit">Exhibit</label>
                <textarea
                  id="editor--vignette-exhibit"
                  value={currentVignetteExhibit}
                  onChange={handleExhibitTextareaChange}
                ></textarea>
              </div>
              <div className="editor m-t-0-75">
                <label htmlFor="editor--vignette-lo">Learning Objective</label>
                <textarea
                  id="editor--vignette-lo"
                  value={currentVignetteLearningObjective}
                  onChange={handleLearningObjectiveTextareaChange}
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div className="modal_actions">
          <button
            className="button button--glass button--back"
            onClick={() => {
              setIsEditing(false);
              setKeybindsDisabled(false);
              hapticsImpactLight();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button button--glass button--submit"
            onClick={() => {
              hapticsImpactLight();
              submitVariantUpdate();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditVignette;
