import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vignette, VignetteAnswerOption } from '../../types/Vignette';

interface VignetteState {
  vignettes: Vignette[];
}

const initialState: VignetteState = {
  vignettes: [],
};

export const vignettesSlice = createSlice({
  name: 'vignettes',
  initialState: initialState,
  reducers: {
    setVignettes: (state, action: PayloadAction<Vignette[]>) => {
      state.vignettes = action.payload;
    },
    shiftVignette: (state) => {
      state.vignettes.shift();
    },
    clearVignettes: () => {
      return initialState;
    },
    replaceVignetteVariantContentById: (
      state,
      action: PayloadAction<{
        vignetteId: string;
        variantId: string;
        newQuestion: string;
        newExplanation: string;
        newExhibit: string;
        newLearningObjective: string;
        newAnswerOptions: VignetteAnswerOption[];
        newIsQAed: boolean;
      }>
    ) => {
      const {
        vignetteId,
        variantId,
        newQuestion,
        newExplanation,
        newExhibit,
        newLearningObjective,
        newAnswerOptions,
        newIsQAed,
      } = action.payload;

      const vignetteIndex = state.vignettes.findIndex(
        (vignette) => vignette.id === vignetteId
      );
      if (vignetteIndex !== -1) {
        const vignette = state.vignettes[vignetteIndex];
        const contentIndex = vignette.contents.findIndex(
          (content) => content.id === variantId
        );
        if (contentIndex !== -1) {
          const updatedContent = {
            ...vignette.contents[contentIndex],
            question: newQuestion,
            explanation: newExplanation,
            exhibit: newExhibit,
            learningObjective: newLearningObjective,
            answerOptions: newAnswerOptions,
            isQAed: newIsQAed,
          };
          const updatedContents = [...vignette.contents];
          updatedContents[contentIndex] = updatedContent;
          const updatedVignette = {
            ...vignette,
            contents: updatedContents,
          };
          state.vignettes[vignetteIndex] = updatedVignette;
        }
      }
    },
  },
});

export const {
  setVignettes,
  shiftVignette,
  clearVignettes,
  replaceVignetteVariantContentById,
} = vignettesSlice.actions;

export default vignettesSlice.reducer;
