import axiosInstance from './axiosCreator';

interface TopicObject {
  id: string;
  name: string;
  path: string;
  level: number;
  active: boolean;
}

// Fetch topic tree
export const fetchTopicTree = async () => {
  try {
    const response = await axiosInstance.get('/topicCustomization/');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Update topic tree
export const updateTopicTree = async (
  topicArray: TopicObject[],
  clearTodaySession?: boolean
) => {
  try {
    const response = await axiosInstance.put('/topicCustomization/', {
      topicArray,
      clearTodaySession,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
