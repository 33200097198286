export const CollapseArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <path
        d="M17 20H7C5.34315 20 4 18.6569 4 17V7C4 5.34315 5.34315 4 7 4H17M17 20C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4M17 20H14V4H17M7.5 12H11.5M11.5 12L9.5 10M11.5 12L9.5 14"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
