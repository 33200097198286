import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Flashcard, FlashcardsState } from '../../types/Flashcard';

const initialState: FlashcardsState = {
  new: [],
  due: [],
};

export const flashcardsSlice = createSlice({
  name: 'flashcards',
  initialState,
  reducers: {
    setNew: (state, action: PayloadAction<Flashcard[]>) => {
      state.new = action.payload;
    },
    setDue: (state, action: PayloadAction<Flashcard[]>) => {
      state.due = action.payload;
      // Order due flashcards by due date
      state.due.sort((a, b) => {
        const dateA = new Date(a.scheduling.due);
        const dateB = new Date(b.scheduling.due);
        return dateA.getTime() - dateB.getTime();
      });
    },
    setNewAndDue: (
      state,
      action: PayloadAction<{ new: Flashcard[]; due: Flashcard[] }>
    ) => {
      state.new = action.payload.new;
      state.due = action.payload.due;
      // Order due flashcards by due date
      state.due.sort((a, b) => {
        const dateA = new Date(a.scheduling.due);
        const dateB = new Date(b.scheduling.due);
        return dateA.getTime() - dateB.getTime();
      });
    },
    shiftNew: (state) => {
      state.new.shift();
    },
    shiftDue: (state) => {
      state.due.shift();
    },
    addDue: (state, action: PayloadAction<Flashcard>) => {
      insertSorted(state.due, action.payload);
    },
    shiftNewAndAddDue: (state, action: PayloadAction<Flashcard>) => {
      state.new.shift();
      insertSorted(state.due, action.payload);
    },
    shiftDueAndAddDue: (state, action: PayloadAction<Flashcard>) => {
      state.due.shift();
      insertSorted(state.due, action.payload);
    },
    unshiftNew: (state, action: PayloadAction<Flashcard>) => {
      state.new.unshift(action.payload);
    },
    unshiftDue: (state, action: PayloadAction<Flashcard>) => {
      state.due.unshift(action.payload);
      // Remove duplicates from due flashcards by ID
      state.due = state.due.filter(
        (dueFlashcard, index, self) =>
          index === self.findIndex((t) => t.id === dueFlashcard.id)
      );
    },
    removeFromNew: (state, action: PayloadAction<string>) => {
      state.new = state.new.filter(
        (flashcard) => flashcard.id !== action.payload
      );
    },
    removeFromDue: (state, action: PayloadAction<string>) => {
      state.due = state.due.filter(
        (flashcard) => flashcard.id !== action.payload
      );
    },
    clearFlashcards: () => {
      return initialState;
    },
    replaceFlashcardVariantContentById: (
      state,
      action: PayloadAction<{
        flashcardId: string;
        variantId: string;
        newFront: string;
        newBack: string;
        newExplanation: string;
        newIsQAed: boolean;
      }>
    ) => {
      const {
        flashcardId,
        variantId,
        newFront,
        newBack,
        newExplanation,
        newIsQAed,
      } = action.payload;
      const updateFlashcardContent = (flashcardsArray: Flashcard[]) => {
        const flashcard = flashcardsArray.find(
          (flashcard) => flashcard.id === flashcardId
        );
        if (flashcard) {
          const content = flashcard.contents.find(
            // @ts-expect-error - TS doesn't know that the content exists
            (content) => content.id === variantId
          );
          if (content) {
            content.front = newFront;
            content.back = newBack;
            content.explanation = newExplanation;
            content.isQAed = newIsQAed;
          }
        }
      };
      updateFlashcardContent(state.due);
      updateFlashcardContent(state.new);
    },
  },
});

const insertSorted = (array: Flashcard[], item: Flashcard) => {
  let low = 0;
  let high = array.length;

  while (low < high) {
    const mid = (low + high) >>> 1;
    if (new Date(array[mid].scheduling.due) < new Date(item.scheduling.due)) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  array.splice(low, 0, item);
};

export const {
  setNew,
  setDue,
  setNewAndDue,
  shiftNew,
  shiftDue,
  addDue,
  shiftNewAndAddDue,
  shiftDueAndAddDue,
  unshiftNew,
  unshiftDue,
  removeFromNew,
  removeFromDue,
  clearFlashcards,
  replaceFlashcardVariantContentById,
} = flashcardsSlice.actions;

export default flashcardsSlice.reducer;
