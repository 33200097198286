import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';
import { updateBlockFE } from '../../../../slices/blocks/blocksSlice';
import { updateBlockHighlights } from '../../../../services/blocks';
import { Block, StudyPhase, StudySession } from '../../../../types/Study';
import { VignetteContent } from '../../../../types/Vignette';
import _ from 'lodash';

const useHighlighting = (
  isStart: boolean,
  activeBlock: Block,
  activeVignetteVariant: VignetteContent,
  todaySession: StudySession
) => {
  const dispatch = useDispatch();
  const highlightTarget = useRef<HTMLDivElement>(null);
  const [highlightsChanged, setHighlightsChanged] = useState(false);

  useEffect(() => {
    // Dispatch block update to force trigger re-render
    // This solves the bug where you can't highlight when the exam first loads
    const clonedActiveBlock = _.cloneDeep(activeBlock);
    dispatch(updateBlockFE(clonedActiveBlock));

    document.addEventListener('mouseup', mouseUpHighlightHandler);
    document.addEventListener('mouseup', mouseUpRemoveHighlightHandler);
    return () => {
      document.removeEventListener('mouseup', mouseUpHighlightHandler);
      document.removeEventListener('mouseup', mouseUpRemoveHighlightHandler);
    };
  }, []);

  useEffect(() => {
    const updateHighlights = async () => {
      if (activeVignetteVariant && activeBlock && highlightTarget.current) {
        const newContent = highlightTarget.current.innerHTML;
        const response = await updateBlockHighlights(
          activeBlock.id,
          activeVignetteVariant.id,
          newContent
        );
        if (response) dispatch(updateBlockFE(response));
      }
    };

    if (highlightsChanged) {
      updateHighlights();
      setHighlightsChanged(false);
    }
  }, [highlightsChanged, activeVignetteVariant, activeBlock]);

  useEffect(() => {
    if (!isStart && highlightTarget.current) {
      const highlightedContent =
        activeBlock.questionHighlights[activeVignetteVariant.id];
      if (highlightedContent) {
        highlightTarget.current.innerHTML = highlightedContent;
      } else {
        highlightTarget.current.innerHTML = activeVignetteVariant.question;
      }
    }
  }, [activeVignetteVariant, activeBlock?.questionHighlights, isStart]);

  const mouseUpHighlightHandler = () => {
    if (todaySession.phase === StudyPhase.qbankReview || todaySession.isReview)
      return;
    if (activeBlock.isSubmitted) return;
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      applyHighlightingToDOM();
    }
  };

  const applyHighlightingToDOM = () => {
    const selection = rangy.getSelection();
    if (!selection.rangeCount || selection.isCollapsed) {
      return;
    }

    let range = selection.getRangeAt(0);
    if (!range.toString().trim().length) {
      return;
    }

    // Check if the selection is within the highlight target
    if (!highlightTarget.current?.contains(range.commonAncestorContainer)) {
      return;
    }

    // Adjust range to contain only text nodes by expanding to nearest text node boundaries
    // @ts-expect-error - TS doesn't recognize expandRangeToNearestTextNodes
    range = expandRangeToNearestTextNodes(range);

    // Check if the range is now properly adjusted
    if (
      range.startContainer.nodeType !== Node.TEXT_NODE ||
      range.endContainer.nodeType !== Node.TEXT_NODE
    ) {
      console.error('Range still includes non-text node elements.');
      return;
    }

    // @ts-expect-error - TS doesn't recognize createClassApplier
    const cssClassApplier = rangy.createClassApplier('highlight', {
      elementTagName: 'span',
      normalize: true,
    });

    cssClassApplier.applyToRange(range);

    // Clear the selection
    rangy.getSelection().removeAllRanges();

    // Update the highlightsChanged state to trigger saving highlights to activeBlock
    setHighlightsChanged(true);
  };

  const mouseUpRemoveHighlightHandler = (event: MouseEvent) => {
    // Check if the current phase is qbankReview; if so, do nothing
    if (todaySession.phase === StudyPhase.qbankReview || todaySession.isReview)
      return;

    // Check if the event target is an HTMLElement and contains the 'highlight' class
    if (
      event.target instanceof HTMLElement &&
      event.target.classList.contains('highlight')
    ) {
      const selection = window.getSelection();
      if (selection && selection.toString().length === 0) {
        // Initialize Rangy's CSS class applier for the 'highlight' class
        // @ts-expect-error - TS doesn't recognize createClassApplier
        const cssClassApplier = rangy.createClassApplier('highlight', {
          elementTagName: 'span',
          normalize: true,
        });

        // Create a selection and range to encompass the clicked element
        const selection = rangy.getSelection();
        const range = rangy.createRange();

        range.selectNodeContents(event.target);
        selection.setSingleRange(range);

        // Use the cssClassApplier to undo the highlight for the selected range
        cssClassApplier.undoToRange(range);

        // Clear the selection
        selection.removeAllRanges();

        // Update the highlightsChanged state to trigger saving highlights to activeBlock
        setHighlightsChanged(true);
      }
    }
  };

  const expandRangeToNearestTextNodes = (range: Range) => {
    if (range.startContainer.nodeType !== Node.TEXT_NODE) {
      let startNode = range.startContainer.childNodes[range.startOffset];
      while (startNode && startNode.hasChildNodes()) {
        // @ts-expect-error - TS doesn't recognize hasChildNodes
        startNode = startNode.firstChild;
      }
      range.setStartBefore(startNode || range.startContainer);
    }

    if (range.endContainer.nodeType !== Node.TEXT_NODE) {
      let endNode = range.endContainer.childNodes[range.endOffset];
      while (endNode && endNode.hasChildNodes()) {
        // @ts-expect-error - TS doesn't recognize hasChildNodes
        endNode = endNode.lastChild;
      }
      range.setEndAfter(endNode || range.endContainer);
    }

    return range;
  };

  return { highlightTarget };
};

export default useHighlighting;
