import { ArrowRight } from 'react-feather';
import QBankDonut from '../../../Misc/QBankDonut';
import { hapticsImpactLight } from '../../../../utils/haptics';

interface StatBlockProps {
  header?: string;
  percentage: number;
  blockId?: string;
  reviewQbankBlock?: (blockId: string) => void;
  stats: {
    value: number | string;
    label: string;
  }[];
  fadeInOutFlashLoader: () => void;
}

const StatBlock: React.FC<StatBlockProps> = ({
  header,
  percentage,
  blockId,
  reviewQbankBlock,
  stats,
  fadeInOutFlashLoader,
}) => {
  const donutData = stats
    .filter((stat) =>
      ['Right', 'Wrong', 'Omitted', 'Correct', 'Incorrect'].includes(stat.label)
    )
    .map((stat) => ({
      ...stat,
      value: typeof stat.value === 'string' ? parseInt(stat.value) : stat.value,
    }));

  return (
    <div className="block block--stats">
      {header && (
        <div className="block--stats_header">
          <h4>{header}</h4>
        </div>
      )}
      <div className="block--stats_main">
        <QBankDonut percentage={percentage} data={donutData} />
        <div className="block--stats_main_breakdown">
          {stats.map((stat, i) => (
            <div className="stat-row" key={i}>
              <div className="stat-row_value">{stat.value}</div>
              <div className="stat-row_label">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
      {blockId !== undefined && reviewQbankBlock && (
        <div className="block--stats_footer">
          <button
            className="button button--link button--link--glass"
            onClick={() => {
              hapticsImpactLight();
              fadeInOutFlashLoader();
              setTimeout(() => {
                reviewQbankBlock(blockId);
              }, 250);
            }}
          >
            Review Block
            <ArrowRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default StatBlock;
