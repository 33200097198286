import axiosInstance from './axiosCreator';
import { Block } from '../types/Study';

export const getBlockById = async (blockId: string) => {
  try {
    const response = await axiosInstance.get(`/blocks/${blockId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateBlockBE = async (blockId: string, block: Block) => {
  try {
    const response = await axiosInstance.put(`/blocks/${blockId}`, block);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addBlockResponse = async (
  blockId: string,
  vignetteId: string,
  answer: string,
  correctAnswer: string,
  submitted: boolean
) => {
  try {
    const response = await axiosInstance.put(`/blocks/${blockId}/response`, {
      vignetteId,
      answer,
      correctAnswer,
      submitted,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const toggleBlockFlag = async (blockId: string, vignetteId: string) => {
  try {
    const response = await axiosInstance.put(`/blocks/${blockId}/flags`, {
      vignetteId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateBlockNotes = async (
  blockId: string,
  vignetteId: string,
  notes: string
) => {
  try {
    const response = await axiosInstance.put(`/blocks/${blockId}/notes`, {
      vignetteId,
      notes,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

interface SubmitBlockScoreObject {
  correctCount: number;
  incorrectCount: number;
  omittedCount: number;
}

export const submitBlock = async (
  blockId: string,
  score: SubmitBlockScoreObject,
  isTimedMode: boolean
) => {
  try {
    const response = await axiosInstance.post(`/blocks/${blockId}/submit`, {
      score,
      isTimedMode,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateBlockCrossouts = async (
  blockId: string,
  vignetteId: string,
  crossouts: boolean[]
) => {
  try {
    const response = await axiosInstance.put(`/blocks/${blockId}/crossouts`, {
      vignetteId,
      crossouts,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateBlockHighlights = async (
  blockId: string,
  vignetteId: string,
  highlights: string
) => {
  try {
    const response = await axiosInstance.put(`/blocks/${blockId}/highlights`, {
      vignetteId,
      highlights,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
