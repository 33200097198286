import { useState } from 'react';
import Header from '../../../components/Global/Header';
import TextField from '@mui/material/TextField';
import { resetPassword } from '../../../services/auth';
import { Link, useNavigate } from 'react-router-dom';
import { isValidPassword } from '../../../utils/validEmail';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import { Check } from 'react-feather';
import Snackbar from '@mui/material/Snackbar';
import { HeaderType } from '../../../types/Header';
import { hapticsImpactLight } from '../../../utils/haptics';
import ThreeWave from '../../../components/Home/ThreeWave';
import IntercomButton from '../../../components/Global/IntercomButton';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [resetError, setResetError] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !newPassword ||
      !newPasswordConfirm ||
      newPassword !== newPasswordConfirm
    ) {
      setResetError(true);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (!token) {
      setResetError(true);
      return;
    }

    try {
      const response = await resetPassword(token, newPassword);
      if (response.success) {
        setResetSuccess(true);
        setResetError(false);
        navigate('/auth');
      } else {
        setResetError(true);
      }
    } catch (error) {
      setResetError(true);
    }
  };

  return (
    <div className="auth">
      <Header type={HeaderType.generic} isDiagnostic={false} />
      <div className="auth_body">
        <div className="auth-panel auth-panel--reset">
          <h2>Reset password</h2>
          {resetError && <div className="error">{resetError}</div>}
          {resetSuccess && (
            <>
              <div className="success">Password reset successfully.</div>
              <Link
                className="button button--submit m-t-1-50"
                to="/auth"
                onClick={hapticsImpactLight}
              >
                Return to Login
              </Link>
            </>
          )}
          {!resetSuccess && (
            <form onSubmit={handleSubmit}>
              <div className="field visible m-t-0">
                <TextField
                  id="password"
                  fullWidth
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={newPassword}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewPassword(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: isValidPassword(newPassword) ? (
                      <>
                        <Tooltip
                          title="Password is at least 8 characters."
                          enterTouchDelay={0}
                        >
                          <div className="field-validator field-validator--pass">
                            <InputAdornment position="end">
                              <Check />
                            </InputAdornment>
                          </div>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        {newPassword.length > 0 && (
                          <Tooltip
                            title="Password must be at least 8 characters."
                            enterTouchDelay={0}
                          >
                            <div className="field-validator"></div>
                          </Tooltip>
                        )}
                      </>
                    ),
                  }}
                />
              </div>
              <div className="field visible">
                <TextField
                  id="password-confirm"
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  value={newPasswordConfirm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewPasswordConfirm(event.target.value);
                  }}
                  InputProps={{
                    endAdornment:
                      isValidPassword(newPassword) &&
                      newPassword === newPasswordConfirm ? (
                        <Tooltip title="Passwords match." enterTouchDelay={0}>
                          <div className="field-validator field-validator--pass">
                            <InputAdornment position="end">
                              <Check />
                            </InputAdornment>
                          </div>
                        </Tooltip>
                      ) : (
                        <>
                          {newPasswordConfirm.length > 0 && (
                            <Tooltip
                              title="Passwords must match."
                              enterTouchDelay={0}
                            >
                              <div className="field-validator"></div>
                            </Tooltip>
                          )}
                        </>
                      ),
                  }}
                />
              </div>
              <div className="field m-t-1">
                <button
                  className="button button--glass button--submit"
                  type="submit"
                  onClick={hapticsImpactLight}
                  disabled={
                    !isValidPassword(newPassword) ||
                    newPassword !== newPasswordConfirm
                  }
                >
                  Reset
                </button>
              </div>
            </form>
          )}
        </div>
        <Snackbar
          open={resetError}
          autoHideDuration={3000}
          onClose={() => setResetError(false)}
          message="An error has occurred. Please try again."
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
      </div>
      <ThreeWave showLabels={true} transitionStarted={false} />
      <IntercomButton />
    </div>
  );
};

export default ResetPassword;
