import React, { useEffect, useRef, useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { MeiliSearch } from 'meilisearch';
import { useNavigate } from 'react-router-dom';
import { hapticsImpactLight } from '../../../utils/haptics';
import { useHotkeys } from 'react-hotkeys-hook';

const client = new MeiliSearch({
  host: 'https://ms-2894cc38327f-12358.sfo.meilisearch.io',
  apiKey: '8322acd1ebf9ad9a8ff4420430a4074ab437b6e0880fb932a2da4488885e9c1c',
});

const index = client.index('articles');

interface ArticleResult {
  articleUUID: string;
  articleTitle: string;
}

interface SearchProps {
  setMobileMenuIsOpen: (isOpen: boolean) => void;
  setSearchFlyoutIsOpen: (isOpen: boolean) => void;
  loadArticleById: (articleId: string) => void;
  isPanel: boolean;
}

const Search: React.FC<SearchProps> = ({
  setMobileMenuIsOpen,
  setSearchFlyoutIsOpen,
  loadArticleById,
  isPanel,
}) => {
  const [searchedWord, setSearch] = useState('');
  const [resultSearch, setResults] = useState<ArticleResult[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useHotkeys('/', (e) => {
    e.preventDefault();
    const input = document.getElementById('library-search-input');
    if (input) {
      input.focus();
    }
  });

  // Handle Arrow Key Navigation and Scrolling
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!resultSearch.length) return;
      if (event.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) =>
          prevIndex === null || prevIndex === resultSearch.length - 1
            ? 0
            : prevIndex + 1
        );
      } else if (event.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) =>
          prevIndex === null || prevIndex === 0
            ? resultSearch.length - 1
            : prevIndex - 1
        );
      } else if (event.key === 'Enter' && selectedIndex !== null) {
        const selectedArticle = resultSearch[selectedIndex];
        navigate(`/library/${selectedArticle.articleUUID}`);
        setMobileMenuIsOpen(false);
        setSearchFlyoutIsOpen(false);
        hapticsImpactLight();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    resultSearch,
    selectedIndex,
    isPanel,
    loadArticleById,
    navigate,
    setMobileMenuIsOpen,
    setSearchFlyoutIsOpen,
  ]);

  useEffect(() => {
    if (selectedIndex !== null && itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  useEffect(() => {
    async function searchWithMeili() {
      const search = await index.search(searchedWord);
      if (search && search.hits) {
        // @ts-expect-error - TS doesn't know about the hits property
        setResults(search.hits);
        setSelectedIndex(null);
      }
    }
    searchWithMeili();
  }, [searchedWord]);

  return (
    <div className={`search ${searchedWord ? 'has-query' : ''}`}>
      <div className="search_input-wrapper">
        <SearchIcon />
        <input
          id="library-search-input"
          type="text"
          value={searchedWord}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search for something..."
        />
        <div className="hint">/</div>
      </div>
      {searchedWord && (
        <div className="flyout">
          {resultSearch?.length === 0 && (
            <div
              style={{ color: 'var(--type-body-inverse', padding: '.5rem 0' }}
            >
              No results found
            </div>
          )}
          {resultSearch?.map((result: ArticleResult, index: number) => (
            <button
              key={result.articleUUID}
              ref={(el) => (itemRefs.current[index] = el)}
              onClick={() => {
                if (isPanel) {
                  loadArticleById(result.articleUUID);
                } else {
                  navigate(`/library/${result.articleUUID}`);
                }
                setMobileMenuIsOpen(false);
                setSearchFlyoutIsOpen(false);
                hapticsImpactLight();
              }}
              className={index === selectedIndex ? 'selected' : ''}
              style={{
                backgroundColor:
                  index === selectedIndex
                    ? 'var(--surface-glass-light)'
                    : 'transparent',
              }}
            >
              <h2>{result.articleTitle}</h2>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Search;
