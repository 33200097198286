// import { useSelector } from "react-redux";
// import { RootState } from "../../../store/store";
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import StatBlock from './StatBlock';
import { Layers, List } from 'react-feather';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { Block, StudyPhase, StudySession } from '../../../types/Study';
import { useEffect, useState } from 'react';
import { fetchFlashcardResponses } from '../../../services/studySession';
import moment from 'moment';
import { hapticsImpactLight } from '../../../utils/haptics';

interface SessionReviewProps {
  todaySession: StudySession;
  reviewQbankBlock: (blockId: string) => void;
  revealReview: boolean;
  fadeInOutFlashLoader: () => void;
  changePhase: (phase: StudyPhase, options?: object) => void;
}

interface FlashcardResponse {
  flashcardId: string;
  id: string;
  lapse: boolean;
  response: number;
  studySessionId: string;
  updatedAt: string;
  userId: string;
}

interface Stats {
  value: number | string;
  label: string;
}

interface ResponsePercentage {
  percentage: number;
  count: number;
}

interface ResponsePercentages {
  [key: string]: ResponsePercentage;
}

const SessionReview: React.FC<SessionReviewProps> = ({
  todaySession,
  reviewQbankBlock,
  revealReview,
  fadeInOutFlashLoader,
  changePhase,
}) => {
  const blocks = useSelector((state: RootState) => state.blocks);
  const [flashcardPercentage, setFlashcardPercentage] = useState(0);
  const [flashcardStats, setFlashcardStats] = useState<Stats[]>([]);
  const [responsePercentages, setResponsePercentages] =
    useState<ResponsePercentages>({});

  const navigate = useNavigate();

  useEffect(() => {
    getFlashcardResponses(todaySession.id);
  }, []);

  // Protection: If Session Review Loads but there are incomplete blocks, redirect to QBank
  useEffect(() => {
    const allBlocksComplete = blocks.every((block) => block.isSubmitted);
    if (!allBlocksComplete) {
      console.log(
        'Session Review Loaded but Not All Blocks Complete, Redirecting to QBank'
      );
      changePhase(StudyPhase.qbank);
    }
  }, [blocks, todaySession]);

  const getFlashcardResponses = async (sessionId: string) => {
    const res = await fetchFlashcardResponses(sessionId);
    calculateFlashcardStats(res);
  };

  const calculateFlashcardStats = (responses: FlashcardResponse[]) => {
    if (responses.length === 0) {
      return;
    }
    let again = 0;
    let wrong = 0;
    let right = 0;
    let easy = 0;

    let correct = 0;
    let incorrect = 0;
    // let lapses = 0;
    const total = responses.length;

    responses.forEach((response) => {
      if (response.response === 0) {
        again++;
        incorrect++;
      } else if (response.response === 1) {
        wrong++;
        incorrect++;
      } else if (response.response === 2) {
        right++;
        correct++;
      } else if (response.response === 3) {
        easy++;
        correct++;
      }
      // if (response.lapse) {
      //   lapses++;
      // }
    });
    setResponsePercentages({
      again: {
        percentage: Math.round((again / total) * 100),
        count: again,
      },
      wrong: {
        percentage: Math.round((wrong / total) * 100),
        count: wrong,
      },
      right: {
        percentage: Math.round((right / total) * 100),
        count: right,
      },
      easy: {
        percentage: Math.round((easy / total) * 100),
        count: easy,
      },
    });
    setFlashcardPercentage(Math.round((correct / total) * 100));
    setFlashcardStats([
      { value: total, label: 'Responses' },
      { value: correct, label: 'Correct' },
      { value: incorrect, label: 'Incorrect' },
      // { value: lapses, label: 'Lapses' },
    ]);
  };

  const calculateBlockPercentage = (block: Block) => {
    const correct = block.correctCount;
    return Math.round((correct / block.vignetteCount) * 100);
  };

  const calculateBlockStats = (block: Block) => {
    const correct = block.correctCount;
    const incorrect = block.incorrectCount;
    const omitted = block.omittedCount;
    return [
      { value: correct, label: 'Correct' },
      { value: incorrect, label: 'Incorrect' },
      { value: omitted, label: 'Omitted' },
    ];
  };

  const calculateStudySessionStats = () => {
    const correct = todaySession.vignetteCorrectCount;
    const incorrect = todaySession.vignetteIncorrectCount;
    const omitted = todaySession.vignetteOmittedCount;
    return [
      { value: correct, label: 'Correct' },
      { value: incorrect, label: 'Incorrect' },
      { value: omitted, label: 'Omitted' },
    ];
  };

  const renderOverallQBankStats = () => {
    if (blocks.length <= 1) return null;
    const stats = calculateStudySessionStats();
    const percentage = stats.find((stat) => stat.label === 'Correct')?.value;
    return (
      <>
        <h3>Overall</h3>
        <StatBlock
          percentage={percentage ? Math.round(percentage) : 0}
          stats={stats}
          fadeInOutFlashLoader={fadeInOutFlashLoader}
        />
      </>
    );
  };

  return (
    <div className={`session-review ${revealReview ? 'is-loaded' : ''}`}>
      <div className="session-review_content">
        <h1
          style={
            flashcardPercentage > 0 && blocks.length > 0
              ? {}
              : { margin: '0 auto', maxWidth: '32rem' }
          }
        >
          {todaySession.isReview
            ? moment(todaySession.date).format('MMMM DD, YYYY') + ' Session'
            : 'Good Work'}
        </h1>
        <div
          className={`session-review_content_stats ${flashcardPercentage > 0 && blocks.length > 0 ? 'two-col' : 'one-col'}`}
        >
          {/* Flashcard Percentage can't be zero if they studied any flashcards this session */}
          {flashcardPercentage > 0 && (
            <div className="session-review_content_stats_flashcards">
              <h2>
                <Layers />
                Flashcard Performance
              </h2>
              <h3>Key Stats</h3>
              <StatBlock
                percentage={flashcardPercentage}
                stats={flashcardStats}
                fadeInOutFlashLoader={fadeInOutFlashLoader}
              />
              <h3>Responses</h3>
              <div className="block">
                <div className="card-performance">
                  <div className="card-performance_chart">
                    {Object.keys(responsePercentages).map((key) => (
                      <Tooltip
                        key={key}
                        title={`${responsePercentages[key].percentage}%`}
                        enterTouchDelay={0}
                      >
                        <div
                          className="card-performance_chart_bar"
                          style={{
                            height: `${responsePercentages[key].percentage}%`,
                            backgroundColor: `rgba(255, 255, 255, 0.2)`,
                          }}
                        >
                          <div className="card-performance_chart_bar_label">
                            {responsePercentages[key].count}
                          </div>
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                  <div className="card-performance_labels">
                    <div className="card-performance_labels_label">
                      <div className="card-performance_labels_label_main">
                        Again
                      </div>
                    </div>
                    <div className="card-performance_labels_label">
                      <div className="card-performance_labels_label_main">
                        Wrong
                      </div>
                    </div>
                    <div className="card-performance_labels_label">
                      <div className="card-performance_labels_label_main">
                        Right
                      </div>
                    </div>
                    <div className="card-performance_labels_label">
                      <div className="card-performance_labels_label_main">
                        Easy
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* There must be at least one block if they studied qbank this session */}
          {blocks.length > 0 && (
            <div className="session-review_content_stats_qbank">
              <h2>
                <List />
                QBank Performance
              </h2>
              {renderOverallQBankStats()}
              <h3>Block Breakdown</h3>
              {[...blocks]
                .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0))
                .map((block, i) => (
                  <StatBlock
                    key={block.id}
                    header={`Block ${i + 1}`}
                    percentage={calculateBlockPercentage(block)}
                    blockId={block.id}
                    reviewQbankBlock={reviewQbankBlock}
                    stats={calculateBlockStats(block)}
                    fadeInOutFlashLoader={fadeInOutFlashLoader}
                  />
                ))}
            </div>
          )}
        </div>
        <div className="session-review_content_footer">
          <button
            role="link"
            className="button button--reveal button--glass"
            onClick={() => {
              navigate('/');
              hapticsImpactLight();
            }}
          >
            {todaySession.isReview ? 'End Review' : 'End Session'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionReview;
