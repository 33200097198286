import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import OraSwitch from '../../CustomMUI/Switch';
import Slider from '@mui/material/Slider';
import moment from 'moment';
import {
  getSliderValue,
  maxFlashcards,
  maxVignettes,
  minFlashcards,
  minVignettes,
  setFlashcardMarks,
  setFlashcardStep,
  setVignetteMarks,
  setVignetteStep,
} from '../../../utils/contentQuantityUtils';
import { daysUntilExam } from '../../../utils/daysUntilExam';
import { fetchStudiedContentCounts } from '../../../services/study';

interface StudySettingsProps {
  primaryExamName: string;
  primaryExamDate: string;
  isUsingFlashcards: boolean;
  setIsUsingFlashcards: (value: boolean) => void;
  totalUserSelectedFlashcards: number;
  setTotalUserSelectedFlashcards: (value: number) => void;
  isUsingQbank: boolean;
  setIsUsingQbank: (value: boolean) => void;
  totalUserSelectedVignettes: number;
  setTotalUserSelectedVignettes: (value: number) => void;
}

const ContentSelection: React.FC<StudySettingsProps> = ({
  primaryExamName,
  primaryExamDate,
  isUsingFlashcards,
  setIsUsingFlashcards,
  totalUserSelectedFlashcards,
  setTotalUserSelectedFlashcards,
  isUsingQbank,
  setIsUsingQbank,
  totalUserSelectedVignettes,
  setTotalUserSelectedVignettes,
}) => {
  const [studiedFlashcards, setStudiedFlashcards] = useState(0);
  const [studiedVignettes, setStudiedVignettes] = useState(0);
  const [showFlashcardDaily, setShowFlashcardDaily] = useState(false);
  const [showQBankDaily, setShowQbankDaily] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    loadStudiedContentCounts();
  }, []);

  const loadStudiedContentCounts = async () => {
    const res = await fetchStudiedContentCounts();
    let studiedFlashcards = 0;
    let studiedVignettes = 0;
    if (res) {
      studiedFlashcards = res.studiedFlashcards;
      studiedVignettes = res.studiedVignettes;
    }
    setStudiedFlashcards(studiedFlashcards);
    setStudiedVignettes(studiedVignettes);
  };

  const calcFlashcardsPerDay = () => {
    const remainingFlashcards = Math.max(
      0,
      totalUserSelectedFlashcards - studiedFlashcards
    );
    const daysRemaining = daysUntilExam(moment(primaryExamDate));
    let flashcardsPerDay = Math.max(
      2,
      Math.floor(remainingFlashcards / daysRemaining)
    );
    // Ensure the flashcardsPerDay is within the acceptable range
    if (flashcardsPerDay > 300) {
      flashcardsPerDay = 300;
    }
    return flashcardsPerDay;
  };

  const calcVignettesPerDay = () => {
    const remainingVignettes = Math.max(
      0,
      totalUserSelectedVignettes - studiedVignettes
    );
    const daysRemaining = daysUntilExam(moment(primaryExamDate));
    let vignettesPerDay = Math.max(
      2,
      Math.floor(remainingVignettes / daysRemaining)
    );
    // Ensure the vignettesPerDay is within the acceptable range
    if (vignettesPerDay > 160) {
      vignettesPerDay = 160;
    }
    return vignettesPerDay;
  };

  return (
    <div className="content-selection">
      <section>
        <div className="slider-head">
          <h4>QBank</h4>
          <OraSwitch
            checked={isUsingQbank}
            onChange={(e) => {
              setIsUsingQbank(e.target.checked);
              if (!isUsingFlashcards) {
                setIsUsingFlashcards(true);
                enqueueSnackbar(
                  'You must have at least one content type enabled.',
                  {
                    autoHideDuration: 3000,
                    style: { width: 'fit-content' },
                  }
                );
              }
            }}
          />
        </div>
        <div
          className="slider-container"
          style={!isUsingQbank ? { display: 'none' } : {}}
        >
          <div className="slider-container_header">
            <h5>Choose Total Questions</h5>
            <div className={`daily-counts ${showQBankDaily ? 'reveal' : ''}`}>
              <div className="count">{calcVignettesPerDay()}</div> New Questions{' '}
              <span className="slash">/</span> Day
            </div>
          </div>
          <div className="slider-wrapper">
            <div className="top-labels">
              <div>High Yield</div>
              <div className="middle">Standard</div>
              <div>Comprehensive</div>
            </div>
            <Slider
              aria-label="Number of Vignettes"
              defaultValue={totalUserSelectedVignettes}
              value={totalUserSelectedVignettes}
              min={minVignettes(primaryExamName)}
              max={maxVignettes(primaryExamName)}
              getAriaValueText={getSliderValue}
              step={setVignetteStep(primaryExamName)}
              valueLabelDisplay="auto"
              marks={setVignetteMarks(primaryExamName)}
              // @ts-expect-error - TS doesn't like the value having type any
              onChange={(_, value: number) => {
                setTotalUserSelectedVignettes(value as number);
                setShowQbankDaily(true);
              }}
              onChangeCommitted={() => setShowQbankDaily(false)}
            />
          </div>
        </div>
      </section>
      <section>
        <div className="slider-head">
          <h4>Flashcards</h4>
          <OraSwitch
            checked={isUsingFlashcards}
            onChange={(e) => {
              setIsUsingFlashcards(e.target.checked);
              if (!isUsingQbank) {
                setIsUsingQbank(true);
                enqueueSnackbar(
                  'You must have at least one content type enabled.',
                  {
                    autoHideDuration: 3000,
                    style: { width: 'fit-content' },
                  }
                );
              }
            }}
          />
        </div>
        <div
          className="slider-container"
          style={!isUsingFlashcards ? { display: 'none' } : {}}
        >
          <div className="slider-container_header">
            <h5>Choose Total Flashcards</h5>
            <div
              className={`daily-counts ${showFlashcardDaily ? 'reveal' : ''}`}
            >
              <div className="count">{calcFlashcardsPerDay()}</div> New
              Flashcards <span className="slash">/</span> Day
            </div>
          </div>
          <div className="slider-wrapper">
            <div className="top-labels">
              <div>High Yield</div>
              <div className="middle">Standard</div>
              <div>Comprehensive</div>
            </div>
            <Slider
              aria-label="Number of Flashcards"
              defaultValue={totalUserSelectedFlashcards}
              value={totalUserSelectedFlashcards}
              min={minFlashcards(primaryExamName)}
              max={maxFlashcards(primaryExamName)}
              getAriaValueText={getSliderValue}
              step={setFlashcardStep(primaryExamName)}
              valueLabelDisplay="auto"
              marks={setFlashcardMarks(primaryExamName)}
              // @ts-expect-error - TS doesn't like the value having type any
              onChange={(_, value: number) => {
                setTotalUserSelectedFlashcards(value as number);
                setShowFlashcardDaily(true);
              }}
              onChangeCommitted={() => setShowFlashcardDaily(false)}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContentSelection;
