import { useEffect, useState } from 'react';
import { fetchStudyStreak } from '../../../services/studySession';
import { StudySession } from '../../../types/Study';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';
import { AlarmClock } from '../../../assets/svgs/AlarmClock';

interface HeatmapDay {
  date: string;
  value: 0 | 1 | 2;
  isSnoozed: boolean;
}

interface HeatmapProps {
  examId: string;
}

const Heatmap: React.FC<HeatmapProps> = ({ examId }) => {
  const WEEKS = 9;
  const DAY_LABELS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const [heatmapData, setHeatmapData] = useState<HeatmapDay[]>([]);
  const [showMonthDisplay, setShowMonthDisplay] = useState<boolean>(false);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [longestStreak, setLongestStreak] = useState(0);

  useEffect(() => {
    loadSessions(examId);
  }, [examId]);

  const loadSessions = async (examId: string) => {
    const heatmapData = await fetchStudyStreak(examId);
    if (!heatmapData) return;
    const sessions = [...heatmapData.sessions];

    // Create a map of session data for quick lookup
    const sessionMap = sessions.reduce(
      (
        map: Record<
          string,
          { isStarted: boolean; isComplete: boolean; isSnoozed: boolean }
        >,
        session: StudySession
      ) => {
        const dateString = session.date.split('T')[0];
        map[dateString] = {
          isStarted: session.isStarted,
          isComplete: session.isComplete,
          isSnoozed: session.isSnoozed,
        };
        return map;
      },
      {}
    );

    calculateStreaks(sessionMap);

    const today = new Date();
    const day = today.getDay();
    const date = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();
    const diff = day === 0 ? -6 : 1 - day;
    const startDate = new Date(year, month, date + diff - 7 * (WEEKS - 1));

    // Choose display type
    const currentWeekStart = new Date(year, month, date + diff);
    const showMonthDisplay = sessions.some(
      (session: { date: string | number | Date }) =>
        new Date(session.date) < currentWeekStart
    );
    setShowMonthDisplay(showMonthDisplay);

    const heatmap: HeatmapDay[] = Array.from({ length: 7 * WEEKS }, (_, i) => {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      const currentDateString = currentDate.toISOString().split('T')[0];

      const session = sessionMap[currentDateString];

      let value: 0 | 1 | 2 = 0;
      if (session) {
        if (session.isComplete || session.isSnoozed) {
          value = 2;
        } else if (session.isStarted) {
          value = 1;
        }
      }

      return {
        date: currentDateString,
        value,
        isSnoozed: session?.isSnoozed || false,
      };
    });

    setHeatmapData(heatmap);
  };

  const renderCellLabel = (value: 0 | 1 | 2) => {
    switch (value) {
      case 0:
        return '';
      case 1:
        return 'Partially Completed Session | ';
      case 2:
        return 'Completed Session | ';
      default:
        return '';
    }
  };

  interface ReducedSession {
    isStarted: boolean;
    isComplete: boolean;
    isSnoozed: boolean;
  }

  interface ReducedSessions {
    [date: string]: ReducedSession;
  }

  const calculateStreaks = (sessions: ReducedSessions) => {
    const dates = Object.keys(sessions).sort();
    let currentStreak = 0;
    let longestStreak = 0;
    let streak = 0;

    const today = moment().format('YYYY-MM-DD');
    let previousDate = moment(dates[0]);

    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      const session = sessions[date];
      const currentDate = moment(date);

      if (i > 0 && !currentDate.isSame(previousDate.add(1, 'days'), 'day')) {
        streak = 0; // Reset streak if dates are not consecutive
      }

      if (session.isStarted || session.isComplete || session.isSnoozed) {
        streak++;
        if (date === today) {
          currentStreak = streak;
        }
      } else {
        streak = 0;
      }

      if (streak > longestStreak) {
        longestStreak = streak;
      }

      previousDate = currentDate.clone();
    }

    // If today is not present or not started/completed but there is a current streak from yesterday
    if (
      !sessions[today] ||
      (!sessions[today].isStarted &&
        !sessions[today].isComplete &&
        !sessions[today].isSnoozed)
    ) {
      const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');

      if (
        sessions[yesterday] &&
        (sessions[yesterday].isStarted ||
          sessions[yesterday].isComplete ||
          sessions[yesterday].isSnoozed)
      ) {
        currentStreak++;
      }
    }

    setCurrentStreak(currentStreak);
    setLongestStreak(longestStreak);
  };

  const renderWeekDisplay = () => {
    if (heatmapData.length === 0) return null;
    return (
      <div className="grid-week">
        {heatmapData.slice(-7).map((day: HeatmapDay, i: number) => (
          <Tooltip
            enterTouchDelay={0}
            title={`${day.isSnoozed ? 'Snoozed Session | ' : renderCellLabel(day.value)}${moment(day.date).format('MMMM D, YYYY')}`}
            key={i}
          >
            <div className="day">
              <div className={`cell val-${day.value}`}>
                {day.isSnoozed && <AlarmClock />}
              </div>
              <div>{DAY_LABELS[i]}</div>
            </div>
          </Tooltip>
        ))}
      </div>
    );
  };

  const areAllValuesDefined = (
    ...values: (number | null | undefined | HeatmapDay[])[]
  ) => values.every((value) => value !== undefined && value !== null);

  const renderMonthDisplay = () => {
    if (heatmapData.length === 0) return null;
    const weekChunks = Array.from({ length: WEEKS }, (_, i) =>
      heatmapData.slice(i * 7, i * 7 + 7)
    );
    return (
      <div className="grid-month">
        <div className="labels">
          {DAY_LABELS.map((label, i) => (
            <div key={i} className="day">
              {label}
            </div>
          ))}
        </div>
        {weekChunks.map((week, weekIndex) => (
          <div key={weekIndex} className="week">
            {week.map((day, dayIndex) => {
              let future = false;
              if (moment(day.date).isAfter(moment(), 'day')) {
                future = true;
              }
              return (
                <Tooltip
                  enterTouchDelay={0}
                  title={`${day.isSnoozed ? 'Snoozed Session | ' : renderCellLabel(day.value)}${moment(day.date).format('MMMM D, YYYY')}`}
                  key={dayIndex}
                >
                  <div
                    className={`day val-${day.value}${future ? ' future' : ''}`}
                  >
                    {day.isSnoozed && <AlarmClock />}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="heatmap">
      {areAllValuesDefined(currentStreak, longestStreak, heatmapData) && (
        <div className="heatmap_wrapper">
          <div className="heatmap_wrapper_streaks">
            <div className="streak">
              <div className="streak_value">{currentStreak}</div>
              <div className="streak_label">Current Streak</div>
            </div>
            <div className="streak">
              <div className="streak_value">{longestStreak}</div>
              <div className="streak_label">Longest Streak</div>
            </div>
          </div>
          {showMonthDisplay ? renderMonthDisplay() : renderWeekDisplay()}
        </div>
      )}
      {!areAllValuesDefined(currentStreak, longestStreak, heatmapData) && (
        <Skeleton
          sx={{
            bgcolor: 'rgba(255,255,255,.05)',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={105}
        />
      )}
    </div>
  );
};

export default Heatmap;
