type TimezoneMap = { [key: string]: string };

export const supportedTimezones: TimezoneMap = {
  'America/Los_Angeles': 'Los Angeles',
  'America/Denver': 'Denver',
  'America/Chicago': 'Chicago',
  'America/New_York': 'New York',
  'Europe/London': 'London',
  'Europe/Berlin': 'Berlin',
  'Europe/Moscow': 'Moscow',
  'Asia/Calcutta': 'Calcutta',
  'Asia/Shanghai': 'Shanghai',
  'Asia/Tokyo': 'Tokyo',
  'Australia/Sydney': 'Sydney',
  UTC: 'UTC',
};
