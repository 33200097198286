import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import 'normalize.css';
import './sass/styles.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ResponsiveSnackbarProvider } from './components/Global/ResponsiveSnackbarProvider';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

// Initialize PostHog
const posthogApiKey = import.meta.env.VITE_PUBLIC_POSTHOG_KEY;
const posthogApiHost = import.meta.env.VITE_PUBLIC_POSTHOG_HOST;

if (process.env.NODE_ENV === 'production') {
  posthog.init(posthogApiKey, {
    api_host: posthogApiHost,
    autocapture: false,
    disable_session_recording: true,
  });
  posthog.debug(false);
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f21b1d1c5696794bb5582a3f585b1dd8@o1186798.ingest.us.sentry.io/4507020246450176',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ResponsiveSnackbarProvider>
        <PostHogProvider client={posthog}>
          <App />
        </PostHogProvider>
      </ResponsiveSnackbarProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);
