import axiosInstance from './axiosCreator';

export const createFlashcardResponseBE = async (
  flashcardId: string,
  responseNum: number,
  studySessionId: string
) => {
  try {
    const response = await axiosInstance.post('/flashcardResponses', {
      flashcardId,
      response: responseNum,
      studySessionId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const undoFlashcardResponseBE = async (
  flashcardId: string,
  studySessionId: string,
  shouldDecrementCompleted: boolean
) => {
  try {
    const response = await axiosInstance.delete('/flashcardResponses', {
      data: {
        flashcardId,
        studySessionId,
        shouldDecrementCompleted,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
