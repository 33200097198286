import { FlashcardResponse, VignetteResponses } from '../types/DiagnosticData';
import { cacheFlashcardImages, cacheVignetteImages } from '../utils/imgCaching';
import axiosInstance from './axiosCreator';

interface DiagnosticDataBody {
  flashcardResponses?: FlashcardResponse[];
  vignetteResponses?: VignetteResponses;
}

export const fetchOnboardingStudySession = async () => {
  try {
    const response = await axiosInstance.get('/diagnostic/session');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchDiagnosticData = async () => {
  try {
    const response = await axiosInstance.get('/diagnostic/diagnosticData');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchOnboardingBlock = async () => {
  try {
    const response = await axiosInstance.get('/diagnostic/block');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchOnboardingFlashcards = async () => {
  try {
    const response = await axiosInstance.get('/diagnostic/flashcards');
    if (response.data.new) {
      cacheFlashcardImages(response.data.new);
    }
    if (response.data.due) {
      cacheFlashcardImages(response.data.due);
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchOnboardingVignettes = async () => {
  try {
    const response = await axiosInstance.get('/diagnostic/vignettes');
    if (response.data && response.data.length > 0) {
      cacheVignetteImages(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateDiagnosticData = async (
  diagnosticData: DiagnosticDataBody
) => {
  try {
    const response = await axiosInstance.put(
      `/diagnostic/updateDiagnosticData`,
      diagnosticData
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
