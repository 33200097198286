import axios from 'axios';
import axiosInstance from './axiosCreator';

// Send notification to all users
export const sendNotificationToAllUsers = async (message: string) => {
  try {
    const response = await axiosInstance.post(
      `/knock/sendNotificationToAllUsers`,
      {
        message,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Send notification to single user
export const sendNotificationToUser = async (
  userId: string,
  message: string,
  link?: string
) => {
  try {
    const response = await axiosInstance.post(`/knock/sendNotificationToUser`, {
      userId,
      message,
      link,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUnreadNotificationCount = async (userId: string) => {
  const baseUrl = 'https://api.knock.app/v1';
  const authToken = 'Bearer ' + import.meta.env.VITE_KNOCK_PUBLIC_API_KEY;
  const feedChannelId = import.meta.env.VITE_KNOCK_FEED_CHANNEL_ID;
  try {
    const response = await axios.get(
      `${baseUrl}/users/${userId}/feeds/${feedChannelId}`,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    return response.data.meta.unread_count;
  } catch (error) {
    console.error(error);
    return 0;
  }
};
