export const Lightbulb = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8 21.25H10.3M18 8.75C18 7.96207 17.8448 7.18185 17.5433 6.4539C17.2417 5.72595 16.7998 5.06451 16.2426 4.50736C15.6855 3.95021 15.0241 3.50825 14.2961 3.20672C13.5681 2.90519 12.7879 2.75 12 2.75C11.2121 2.75 10.4319 2.90519 9.7039 3.20672C8.97595 3.50825 8.31451 3.95021 7.75736 4.50736C7.20021 5.06451 6.75825 5.72595 6.45672 6.4539C6.15519 7.18185 6 7.96207 6 8.75C6 11.05 8.4 13.15 9 14.55C10 16.35 10 17.75 10 17.75H14C14 17.75 14 16.35 15 14.55C15.7 13.15 18 11.35 18 8.75Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
