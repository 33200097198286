import TriangleRight from '../../assets/icons/triangle-right.svg';
import TriangleDown from '../../assets/icons/triangle-down.svg';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@mui/material';
import OraSwitch from '../../components/CustomMUI/Switch';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { TopicTreeNode } from '../../types/TopicCustomization';
import { setActiveFilters } from '../../slices/activeTopics/activeTopicsSlice';
import { cloneDeep } from 'lodash';
import { ChevronsDown, ChevronsUp } from 'react-feather';
import { hapticsImpactLight } from '../../utils/haptics';

interface TopicFiltersProps {
  setActiveTopicsChanged: (activeTopicsChanged: boolean) => void;
}

const TopicFilters: React.FC<TopicFiltersProps> = ({
  setActiveTopicsChanged,
}) => {
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const topicFilters =
    useSelector((state: RootState) => state.activeTopics.topicTree) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);

  const dispatch = useDispatch();

  const handleToggleChange = (topicId: string, active: boolean) => {
    const updateTopicFilters = (
      topics: TopicTreeNode[],
      parentActive: boolean = true,
      markChildrenAsChanged: boolean = false
    ): TopicTreeNode[] => {
      return topics.map((topic) => {
        const updatedTopic = { ...topic };
        if (updatedTopic.id === topicId) {
          updatedTopic.active = active;
          updatedTopic.changed = true;
          markChildrenAsChanged = true;
          if (active && !parentActive) {
            parentActive = true;
          }
          if (
            active &&
            updatedTopic.children &&
            updatedTopic.children.every((child) => !child.active)
          ) {
            updatedTopic.children = updatedTopic.children.map((child) => ({
              ...child,
              active: true,
              changed: true,
            }));
          }
        }
        if (updatedTopic.children) {
          updatedTopic.children = updateTopicFilters(
            updatedTopic.children,
            updatedTopic.active && parentActive,
            markChildrenAsChanged
          );
        }
        if (!parentActive) {
          updatedTopic.active = false;
          updatedTopic.changed = true;
        }
        if (markChildrenAsChanged) {
          updatedTopic.changed = true;
        }
        return updatedTopic;
      });
    };

    const activateParent = (
      topics: TopicTreeNode[],
      topicId: string
    ): TopicTreeNode[] => {
      return topics.map((topic) => {
        const updatedTopic = { ...topic };
        if (updatedTopic.children) {
          updatedTopic.children = activateParent(
            updatedTopic.children,
            topicId
          );
          if (
            updatedTopic.children.some(
              (child) => child.id === topicId && child.active
            )
          ) {
            updatedTopic.active = true;
            updatedTopic.changed = true;
          }
        }
        return updatedTopic;
      });
    };

    let updatedTopics = updateTopicFilters(cloneDeep(topicFilters));
    if (active) {
      updatedTopics = activateParent(updatedTopics, topicId);
    }

    const allTopicsActive = updatedTopics.every((topic) => {
      const checkActive = (t: TopicTreeNode): boolean => {
        if (!t.active) return false;
        return t.children ? t.children.every(checkActive) : true;
      };
      return checkActive(topic);
    });

    const allTopicsInactive = updatedTopics.every((topic) => {
      const checkInactive = (t: TopicTreeNode): boolean => {
        if (t.active) return false;
        return t.children ? t.children.every(checkInactive) : true;
      };
      return checkInactive(topic);
    });

    dispatch(
      setActiveFilters({
        allTopicsActive: allTopicsActive,
        allTopicsInactive: allTopicsInactive,
        topicTree: updatedTopics,
      })
    );

    setActiveTopicsChanged(true);
  };

  const renderTopicFilters = (topics: TopicTreeNode[]) => {
    if (!topics || !topics.length) return null;
    return topics.map((t, i) => {
      return (
        <Accordion key={i} className={`topic-accordion`}>
          <AccordionSummary
            sx={
              i !== topics.length - 1
                ? { borderBottom: '1px solid var(--border-glass)', padding: 0 }
                : { padding: 0 }
            }
            aria-controls={`p${1}${i}-content`}
            id={`p${1}${i}-header`}
            onClick={(e) => {
              e.stopPropagation();
              hapticsImpactLight();
            }}
          >
            {t.children && t.children.length > 0 && (
              <>
                <img
                  className="expand-icon expand-icon--closed"
                  src={TriangleRight}
                  alt=""
                />
                <img
                  className="expand-icon expand-icon--open"
                  src={TriangleDown}
                  alt=""
                />
              </>
            )}
            <div className="row">
              <div>{t.name}</div>
              <OraSwitch
                checked={t.active}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  handleToggleChange(t.id, e.target.checked);
                }}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {t.children && t.children.length > 0 && (
              <div className="level-2">{renderLevel2Topics(t.children)}</div>
            )}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const renderLevel2Topics = (topics: TopicTreeNode[]) => {
    return topics.map((t, i) => {
      return (
        <div key={'level-2-' + i} className="row">
          <div>{t.name}</div>
          <OraSwitch
            checked={t.active}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              handleToggleChange(t.id, e.target.checked);
            }}
          />
        </div>
      );
    });
  };

  const bulkChangeTopicFilters = (
    topicFilters: TopicTreeNode[],
    enable: boolean
  ): TopicTreeNode[] => {
    setActiveTopicsChanged(true);
    return topicFilters.map((topicFilter) => {
      return changeTopicFilter({ ...topicFilter }, enable);
    });
  };

  const changeTopicFilter = (
    topicFilter: TopicTreeNode,
    enable: boolean
  ): TopicTreeNode => {
    topicFilter.active = enable;
    topicFilter.changed = true;
    if (topicFilter.children) {
      topicFilter.children = topicFilter.children.map((child) =>
        changeTopicFilter({ ...child }, enable)
      );
    }
    return topicFilter;
  };

  const handleDisableFilters = () => {
    const updatedFilters = bulkChangeTopicFilters(
      cloneDeep(topicFilters),
      false
    );
    const allTopicsActive = updatedFilters.every((topic) => {
      const checkActive = (t: TopicTreeNode): boolean => {
        if (!t.active) return false;
        return t.children ? t.children.every(checkActive) : true;
      };
      return checkActive(topic);
    });
    const allTopicsInactive = updatedFilters.every((topic) => {
      const checkInactive = (t: TopicTreeNode): boolean => {
        if (t.active) return false;
        return t.children ? t.children.every(checkInactive) : true;
      };
      return checkInactive(topic);
    });
    dispatch(
      setActiveFilters({
        allTopicsActive: allTopicsActive,
        allTopicsInactive: allTopicsInactive,
        topicTree: updatedFilters,
      })
    );
  };

  const handleEnableFilters = () => {
    const updatedFilters = bulkChangeTopicFilters(
      cloneDeep(topicFilters),
      true
    );
    const allTopicsActive = updatedFilters.every((topic) => {
      const checkActive = (t: TopicTreeNode): boolean => {
        if (!t.active) return false;
        return t.children ? t.children.every(checkActive) : true;
      };
      return checkActive(topic);
    });
    const allTopicsInactive = updatedFilters.every((topic) => {
      const checkInactive = (t: TopicTreeNode): boolean => {
        if (t.active) return false;
        return t.children ? t.children.every(checkInactive) : true;
      };
      return checkInactive(topic);
    });
    dispatch(
      setActiveFilters({
        allTopicsActive: allTopicsActive,
        allTopicsInactive: allTopicsInactive,
        topicTree: updatedFilters,
      })
    );
  };

  const expandAllAccordions = () => {
    const accordions = document.querySelectorAll('.topic-accordion');
    accordions.forEach((accordion) => {
      const summary = accordion.querySelector('.MuiAccordionSummary-root');
      if (summary) {
        (summary as HTMLElement).click();
      }
    });
  };

  const collapseAllAccordions = () => {
    const accordions = document.querySelectorAll('.topic-accordion');
    accordions.forEach((accordion) => {
      const summary = accordion.querySelector('.MuiAccordionSummary-root');
      if (summary && accordion.classList.contains('Mui-expanded')) {
        (summary as HTMLElement).click();
      }
    });
  };

  return (
    <div className="topic-filters">
      <div className="exam-title">
        <span>{primaryExam?.name}</span>
        <h1>Active topics</h1>
      </div>
      <div className="actions">
        <div>
          <Tooltip title="Enable all topics." enterTouchDelay={0}>
            <button
              className="button button--icon-only custom-svg"
              onClick={handleEnableFilters}
            >
              <svg
                width="48"
                height="32"
                viewBox="0 0 48 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="8" width="40" height="24" rx="12" fill="#6CC2B2" />
                <rect
                  x="26"
                  y="2"
                  width="20"
                  height="20"
                  rx="10"
                  fill="#FEFEFE"
                />
                <rect y="4" width="44" height="28" rx="14" fill="#796A99" />
                <rect
                  x="2"
                  y="6"
                  width="40"
                  height="24"
                  rx="12"
                  fill="#6CC2B2"
                />
                <rect
                  x="20"
                  y="8"
                  width="20"
                  height="20"
                  rx="10"
                  fill="#FEFEFE"
                />
              </svg>
            </button>
          </Tooltip>
          <Tooltip title="Disable all topics." enterTouchDelay={0}>
            <button
              className="button button--icon-only custom-svg"
              onClick={handleDisableFilters}
            >
              <svg
                width="48"
                height="32"
                viewBox="0 0 48 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="8" width="40" height="24" rx="12" fill="#E9E9EA" />
                <rect
                  x="10"
                  y="2"
                  width="20"
                  height="20"
                  rx="10"
                  fill="#FEFEFE"
                />
                <rect y="4" width="44" height="28" rx="14" fill="#7D6B9A" />
                <rect
                  x="2"
                  y="6"
                  width="40"
                  height="24"
                  rx="12"
                  fill="#E9E9EA"
                />
                <rect
                  x="4"
                  y="8"
                  width="20"
                  height="20"
                  rx="10"
                  fill="#FEFEFE"
                />
              </svg>
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Collapse all topics." enterTouchDelay={0}>
            <button
              className="button button--icon-only"
              onClick={collapseAllAccordions}
            >
              <ChevronsUp />
            </button>
          </Tooltip>
          <Tooltip title="Expand all topics." enterTouchDelay={0}>
            <button
              className="button button--icon-only"
              onClick={expandAllAccordions}
            >
              <ChevronsDown />
            </button>
          </Tooltip>
        </div>
      </div>
      <div>{renderTopicFilters(topicFilters)}</div>
    </div>
  );
};

export default TopicFilters;
