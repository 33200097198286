import { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { hapticsImpactLight } from './utils/haptics';

function ErrorPage() {
  const error: unknown = useRouteError();
  console.error(error);

  const navigate = useNavigate();

  function isErrorWithStatus(
    error: unknown
  ): error is { status: number; statusText?: string; message?: string } {
    return typeof error === 'object' && error !== null && 'status' in error;
  }

  useEffect(() => {
    if (isErrorWithStatus(error) && error.status !== 404) {
      const hasRecoveryRefreshed = localStorage.getItem('hasRecoveryRefreshed');
      if (!hasRecoveryRefreshed) {
        localStorage.setItem('hasRecoveryRefreshed', 'true');
        window.location.reload();
      } else {
        localStorage.removeItem('hasRecoveryRefreshed');
      }
    }
  }, [error]);

  const renderErrorPage = (error: unknown) => {
    if (isErrorWithStatus(error) && error.status === 404) {
      return (
        <div className="page-not-found">
          <h1>Page not found</h1>
          <p>Unfortunately, the page you are looking for cannot be found.</p>
          <button
            className="button button--glass"
            onClick={() => {
              navigate('/');
              hapticsImpactLight();
            }}
          >
            Return Home
          </button>
        </div>
      );
    } else {
      return (
        <div className="unexpected-error">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <button
            className="button button--glass"
            onClick={() => {
              navigate('/');
              hapticsImpactLight();
            }}
          >
            Refresh &amp; Return Home
          </button>
        </div>
      );
    }
  };

  return (
    <div id="error-page" className="error-page">
      {renderErrorPage(error)}
    </div>
  );
}

export default ErrorPage;
