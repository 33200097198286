import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { addOrUpdateUserExam } from '../../../services/users';
import { setUserInfo } from '../../../slices/auth/authSlice';
import { useSnackbar } from 'notistack';
import { useRefreshStudySessionAndContent } from '../../../utils/refreshStudySession';
import {
  hapticsImpactLight,
  hapticsImpactMedium,
} from '../../../utils/haptics';
import ContentSelection from '../ContentSelection';
import RegenerateModal from '../RegenerateModal';
import { posthog } from 'posthog-js';
import {
  getSuspendedFlashcards,
  unsuspendFlashcard,
} from '../../../services/flashcards';
import parse from 'html-react-parser';
import { Flashcard } from '../../../types/Flashcard';
import { ArrowRight, X } from 'react-feather';

interface StudySettingsProps {
  showSaveConfirmation: boolean;
  setShowSaveConfirmation: (value: boolean) => void;
  unsavedChanges: boolean;
  setUnsavedChanges: (value: boolean) => void;
  closeSecondaryScreen: () => void;
  setIsLoadingStudySessionAndContent: (value: boolean) => void;
  setIsLoadedStudySessionAndContent: (value: boolean) => void;
}

const StudySettings: React.FC<StudySettingsProps> = ({
  showSaveConfirmation,
  setShowSaveConfirmation,
  unsavedChanges,
  setUnsavedChanges,
  closeSecondaryScreen,
  setIsLoadingStudySessionAndContent,
  setIsLoadedStudySessionAndContent,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];

  const { refreshStudySessionAndContent } = useRefreshStudySessionAndContent();

  const primaryExam = currentUserExams.find((exam) => exam.primary) || null;
  const primaryExamId = primaryExam?.id || '';

  const [examDate, setExamDate] = useState<Moment | null>(
    moment(primaryExam?.examDate)
  );

  const [isUsingFlashcards, setIsUsingFlashcards] = useState<boolean>(
    primaryExam?.isUsingFlashcards || true
  );
  const [isUsingQbank, setIsUsingQbank] = useState<boolean>(
    primaryExam?.isUsingQbank || true
  );
  const [totalUserSelectedFlashcards, setTotalUserSelectedFlashcards] =
    useState(primaryExam?.selectedFlashcardCount || 0);
  const [totalUserSelectedVignettes, setTotalUserSelectedVignettes] = useState(
    primaryExam?.selectedVignetteCount || 0
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [showRegenModal, setShowRegenModal] = useState(false);
  const [showSuspendedCards, setShowSuspendedCards] = useState(false);
  const [suspendedCards, setSuspendedCards] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSuspendedFlashcards();
    const blocks = document.querySelectorAll('.block--settings');
    blocks.forEach((block, index) => {
      setTimeout(() => {
        block.classList.add('block--settings--visible');
      }, 250 * index);
    });
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      blocks.forEach((block) => {
        block.classList.remove('block--settings--visible');
      });
    };
  }, []);

  useEffect(() => {
    if (
      primaryExam?.selectedVignetteCount === totalUserSelectedVignettes &&
      primaryExam?.selectedFlashcardCount === totalUserSelectedFlashcards &&
      primaryExam?.isUsingFlashcards === isUsingFlashcards &&
      primaryExam?.isUsingQbank === isUsingQbank &&
      primaryExamId === currentUserExams.find((exam) => exam.primary)?.id &&
      examDate?.isSame(
        moment(currentUserExams.find((exam) => exam.primary)?.examDate)
      )
    ) {
      setUnsavedChanges(false);
    } else {
      setUnsavedChanges(true);
    }
  }, [
    totalUserSelectedVignettes,
    totalUserSelectedFlashcards,
    isUsingFlashcards,
    isUsingQbank,
    examDate,
  ]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    if (unsavedChanges) {
      setIsLoaded(true);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  useEffect(() => {
    if (primaryExam) {
      setExamDate(moment(primaryExam.examDate));
      setTotalUserSelectedFlashcards(primaryExam.selectedFlashcardCount);
      setTotalUserSelectedVignettes(primaryExam.selectedVignetteCount);
      setIsUsingFlashcards(primaryExam.isUsingFlashcards);
      setIsUsingQbank(primaryExam.isUsingQbank);
    }
  }, [primaryExamId]);

  const assignExamToUser = async (regenerate: boolean) => {
    if (!currentUser) return;
    setUnsavedChanges(false);
    if (!moment(examDate).isSame(moment(primaryExam?.examDate))) {
      console.log('Exam Date Changed');
      posthog?.capture('exam_date_change');
    }
    if (isUsingFlashcards !== primaryExam?.isUsingFlashcards) {
      console.log('Flashcards Turned ' + (isUsingFlashcards ? 'On' : 'Off'));
      posthog?.capture('flashcards_turn_' + (isUsingFlashcards ? 'on' : 'off'));
    }
    if (isUsingQbank !== primaryExam?.isUsingQbank) {
      console.log('Qbank Turned ' + (isUsingQbank ? 'On' : 'Off'));
      posthog?.capture('qbank_turn_' + (isUsingQbank ? 'on' : 'off'));
    }
    const response = await addOrUpdateUserExam(
      currentUser.id,
      primaryExamId,
      examDate ? examDate.toISOString() : null,
      totalUserSelectedFlashcards,
      totalUserSelectedVignettes,
      isUsingFlashcards,
      isUsingQbank,
      primaryExam?.hasCompletedRealExam || false,
      primaryExam?.realExamScore || null,
      false,
      regenerate
    );
    const updatedUser = { ...currentUser };
    updatedUser.exams = response;
    dispatch(setUserInfo(updatedUser));

    // Refresh study session and content
    if (regenerate) {
      await refreshStudySessionAndContent();
    }

    enqueueSnackbar('Exam updated successfully.', {
      autoHideDuration: 3000,
      style: { width: 'fit-content' },
    });

    if (document.activeElement && document.activeElement !== document.body) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  const fetchSuspendedFlashcards = async () => {
    const response = await getSuspendedFlashcards();
    if (response) {
      setSuspendedCards(response);
    }
  };

  const handleBeforeUnload = async (event: {
    preventDefault: () => void;
    returnValue: string;
  }) => {
    console.log('The page is being unloaded or refreshed.');

    if (!unsavedChanges) return;
    // Show a confirmation dialog
    event.preventDefault();
    event.returnValue = '';
  };

  const submitChanges = async (regenerate: boolean) => {
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);
    if (showSaveConfirmation) {
      setShowSaveConfirmation(false);
      setUnsavedChanges(false);
      closeSecondaryScreen();
    }
    await assignExamToUser(regenerate);
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);
  };

  const saveChangesOrShowConfirmation = async () => {
    if (todaySession.isStarted === false) {
      submitChanges(true);
    } else if (todaySession.isComplete) {
      submitChanges(false);
    } else {
      setShowRegenModal(true);
    }
  };

  const handleUnsuspendFlashcard = async (flashcardId: string) => {
    await unsuspendFlashcard(flashcardId);
    fetchSuspendedFlashcards();
    enqueueSnackbar('Flashcard unsuspended.', {
      autoHideDuration: 3000,
    });
  };

  return (
    <div
      className={`study-settings ${showSaveConfirmation ? 'confirmation' : ''} ${isLoaded ? 'is-loaded' : ''}`}
    >
      {!showSaveConfirmation && (
        <>
          <div className="exam-title">
            <span>{primaryExam?.name}</span>
            <h1>Exam Settings</h1>
          </div>
          <div className="block block--settings">
            <div className="block_body">
              <h3>Exam Date</h3>
              <div className="exam-selector">
                <div className="date-select-wrapper">
                  <DatePicker
                    value={examDate}
                    onChange={(newValue) => {
                      setExamDate(newValue);
                    }}
                    minDate={moment().startOf('day')}
                    maxDate={moment().add(7, 'years')}
                    openTo="month"
                    views={['year', 'month', 'day']}
                  />
                </div>
              </div>
            </div>
            <div className="block_footer">
              <div className="helper">
                Modifying this will update your active exam and content
                schedule.
              </div>
              {currentUserExams && currentUserExams.length > 0 && (
                <>
                  <button
                    className="button button--glass"
                    onClick={saveChangesOrShowConfirmation}
                    disabled={
                      (primaryExamId ===
                        currentUserExams.find((exam) => exam.primary)?.id &&
                        examDate?.isSame(
                          moment(
                            currentUserExams.find((exam) => exam.primary)
                              ?.examDate
                          )
                        )) ||
                      examDate?.isBefore(moment(), 'day')
                    }
                  >
                    Confirm
                  </button>
                </>
              )}
            </div>
          </div>
          {primaryExam && (
            <div className="block block--settings m-b-0">
              <div className="block_body">
                <h3 className="m-b-0">Exam Content</h3>
                <ContentSelection
                  primaryExamName={primaryExam.name}
                  primaryExamDate={moment(examDate).format('YYYY-MM-DD')}
                  isUsingFlashcards={isUsingFlashcards}
                  setIsUsingFlashcards={setIsUsingFlashcards}
                  totalUserSelectedFlashcards={totalUserSelectedFlashcards}
                  setTotalUserSelectedFlashcards={
                    setTotalUserSelectedFlashcards
                  }
                  isUsingQbank={isUsingQbank}
                  setIsUsingQbank={setIsUsingQbank}
                  totalUserSelectedVignettes={totalUserSelectedVignettes}
                  setTotalUserSelectedVignettes={setTotalUserSelectedVignettes}
                />
              </div>
              <div className="block_footer">
                <div className="helper">
                  Modifying this will regenerate today's study session.
                </div>
                <button
                  className="button button--glass"
                  onClick={saveChangesOrShowConfirmation}
                  disabled={
                    (primaryExam?.selectedVignetteCount ===
                      totalUserSelectedVignettes &&
                      primaryExam?.selectedFlashcardCount ===
                        totalUserSelectedFlashcards &&
                      primaryExam?.isUsingFlashcards === isUsingFlashcards &&
                      primaryExam?.isUsingQbank === isUsingQbank) ||
                    examDate?.isBefore(moment(), 'day')
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          )}
          {suspendedCards && suspendedCards.length > 0 && (
            <div
              className="block block--settings block--settings--suspend-cards m-b-0"
              style={{ opacity: 1 }}
            >
              <div className="block_body">
                <h3>
                  Suspended Flashcards
                  <span className="badge">{suspendedCards.length}</span>
                </h3>
                <p>
                  Suspended flashcards will not be shown in future study
                  sessions. You can unsuspend flashcards if you would like them
                  to return to your sessions.
                </p>
                <button
                  className="button button--link"
                  onClick={() => {
                    setShowSuspendedCards(true);
                    hapticsImpactLight();
                  }}
                >
                  Manage Suspended Flashcards
                  <ArrowRight />
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {showSaveConfirmation && (
        <div style={{ width: '24rem', maxWidth: '100%' }}>
          <h3>You have unsaved changes.</h3>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '3rem',
            }}
          >
            <button
              className="button button--glass"
              onClick={() => {
                setShowSaveConfirmation(false);
                hapticsImpactLight();
              }}
            >
              Back
            </button>
            <div>
              <button
                className="button button--link--glass"
                onClick={() => {
                  closeSecondaryScreen();
                  setShowSaveConfirmation(false);
                  setUnsavedChanges(false);
                  hapticsImpactLight();
                }}
              >
                Discard
              </button>
              <button
                className="button button--glass"
                onClick={() => {
                  setShowRegenModal(true);
                  hapticsImpactMedium();
                }}
              >
                Save &amp; Close
              </button>
            </div>
          </div>
        </div>
      )}
      <RegenerateModal
        showRegenModal={showRegenModal}
        setShowRegenModal={setShowRegenModal}
        submitChanges={submitChanges}
      />
      {showSuspendedCards && (
        <div className="suspended-cards-view">
          <div className="suspended-cards-view_body">
            <div className="suspended-cards-view_body_container">
              <div className="exam-title">
                <h1>
                  Suspended Flashcards
                  <span className="badge">{suspendedCards.length}</span>
                </h1>
                <button
                  className="button button--close"
                  onClick={() => {
                    setShowSuspendedCards(false);
                    hapticsImpactLight();
                  }}
                >
                  <X />
                </button>
                <div className="mobile-bg"></div>
              </div>
              <div className="flashcard-container">
                {suspendedCards.map((card: Flashcard, index: number) => (
                  <div key={index} className="suspended-card">
                    <div className="flashcard">
                      <div>{parse(card.contents[0].front)}</div>
                      <button
                        onClick={() => {
                          hapticsImpactLight();
                          handleUnsuspendFlashcard(card.id);
                        }}
                        className="button button--unsuspend"
                      >
                        Unsuspend
                      </button>
                    </div>
                  </div>
                ))}
                {suspendedCards.length === 0 && (
                  <div className="no-remaining">
                    No remaining suspended flashcards.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudySettings;
