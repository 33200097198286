import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Block } from '../../types/Study';

const initialState = [] as Block[];

export const blocksSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {
    setBlocks: (_state, action: PayloadAction<Block[]>) => {
      return action.payload;
    },
    updateBlockFE: (state, action: PayloadAction<Block>) => {
      const index = state.findIndex((block) => block.id === action.payload.id);
      state[index] = action.payload;
    },
    clearBlocks: () => {
      return initialState;
    },
  },
});

export const { setBlocks, updateBlockFE, clearBlocks } = blocksSlice.actions;

export default blocksSlice.reducer;
