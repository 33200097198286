import React, { useEffect, useState } from 'react';
import { Modal, TextareaAutosize } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useSnackbar } from 'notistack';
import { hapticsImpactLight } from '../../../utils/haptics';
import { Article } from '../../../types/Article';
import { reportArticle } from '../../../services/library';

interface ReportArticleProps {
  activeArticle: Article;
  reportArticleModalIsOpen: boolean;
  setReportArticleModalIsOpen: (isOpen: boolean) => void;
}

const ReportArticle: React.FC<ReportArticleProps> = ({
  activeArticle,
  reportArticleModalIsOpen,
  setReportArticleModalIsOpen,
}) => {
  const [value, setValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const textareaRef = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setValue('');
  }, [activeArticle]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleReportSubmit = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setReportArticleModalIsOpen(false);
    if (e) {
      e.preventDefault();
    }
    if (!currentUser || !currentUser.email || !activeArticle) return;
    try {
      const reportInfo = {
        articleTitle: activeArticle.title,
        articleId: activeArticle.id,
        email: currentUser.email,
        reason: value,
      };
      setValue('');
      await reportArticle(reportInfo);
      enqueueSnackbar('Thank you! A physician will review this shortly.', {
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleReportSubmit();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    }
  };

  return (
    <Modal
      open={reportArticleModalIsOpen}
      onClose={() => setReportArticleModalIsOpen(false)}
    >
      <div className="modal modal--glass modal--report-article">
        <div className="modal_header">
          <h2>Report Article</h2>
        </div>
        <div className="modal_content">
          <form>
            <TextareaAutosize
              ref={textareaRef}
              style={{
                resize: 'none',
                width: '100%',
                fontSize: '16px',
                minHeight: '4rem',
              }}
              aria-label="empty textarea"
              value={value}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              placeholder="Please write your feedback here..."
            />
          </form>
        </div>
        <div className="modal_actions">
          <button
            className="button button--glass button--back"
            onClick={() => {
              setReportArticleModalIsOpen(false);
              hapticsImpactLight();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button button--glass button--submit"
            onClick={(e) => {
              handleReportSubmit(e);
              hapticsImpactLight();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReportArticle;
