export const Ora = () => {
  return (
    <svg
      width="60"
      height="22"
      viewBox="0 0 60 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.92628 0C11.3427 0 12.6485 0.237655 13.8437 0.712964C15.0388 1.16564 16.068 1.85597 16.9311 2.78395C17.8164 3.6893 18.5025 4.83231 18.9894 6.21296C19.4763 7.57099 19.7198 9.16667 19.7198 11C19.7198 12.7881 19.4653 14.3724 18.9562 15.7531C18.4472 17.1111 17.75 18.2541 16.8647 19.1821C15.9794 20.1101 14.9282 20.8117 13.7109 21.287C12.5157 21.7623 11.21 22 9.79349 22C8.37703 22 7.07123 21.7737 5.87609 21.321C4.68096 20.8457 3.64074 20.1554 2.75546 19.25C1.8923 18.322 1.21727 17.179 0.730362 15.821C0.243454 14.4403 0 12.8333 0 11C0 9.21194 0.25452 7.63889 0.76356 6.28086C1.2726 4.90021 1.96976 3.74588 2.85505 2.8179C3.74034 1.88992 4.78055 1.18827 5.97569 0.712964C7.19296 0.237655 8.50982 0 9.92628 0ZM3.31983 11C3.31983 12.4486 3.48582 13.7047 3.8178 14.7685C4.17192 15.8323 4.64776 16.715 5.24533 17.4167C5.8429 18.1183 6.54006 18.6389 7.33682 18.9784C8.15571 19.3179 9.01886 19.4877 9.92628 19.4877C10.8337 19.4877 11.6747 19.3179 12.4494 18.9784C13.2461 18.6389 13.9322 18.1183 14.5076 17.4167C15.1052 16.715 15.57 15.8323 15.902 14.7685C16.234 13.7047 16.3999 12.4486 16.3999 11C16.3999 9.50617 16.2229 8.22737 15.8688 7.16358C15.5368 6.0998 15.072 5.2284 14.4744 4.54938C13.8769 3.84774 13.1686 3.33848 12.3498 3.02161C11.553 2.6821 10.7009 2.51235 9.79349 2.51235C8.88607 2.51235 8.03398 2.6821 7.23722 3.02161C6.44047 3.36111 5.75437 3.88169 5.17893 4.58333C4.60349 5.28498 4.14978 6.1677 3.8178 7.23148C3.48582 8.29527 3.31983 9.55144 3.31983 11Z"
        fill="currentColor"
      />
      <path
        d="M23.8877 0.305556H30.6934C31.7778 0.305556 32.7295 0.463992 33.5484 0.780864C34.3894 1.09774 35.0866 1.5391 35.6399 2.10494C36.1932 2.67078 36.6137 3.34979 36.9014 4.14198C37.1892 4.91152 37.333 5.76029 37.333 6.68827C37.333 7.50309 37.2113 8.23868 36.9678 8.89506C36.7244 9.55144 36.4035 10.1286 36.0051 10.6265C35.6067 11.1019 35.1419 11.5206 34.6108 11.8827C34.1017 12.2222 33.5816 12.5051 33.0504 12.7315L38.5614 21.6944H35.1419L30.1954 13.3086H28.9026V10.7623H29.299C29.9851 10.7623 30.6159 10.6944 31.1913 10.5586C31.7889 10.4002 32.2979 10.1739 32.7185 9.87963C33.1611 9.56276 33.5041 9.15535 33.7476 8.65741C34.0132 8.15947 34.146 7.55967 34.146 6.85803C34.146 5.47737 33.7697 4.45885 33.0172 3.80247C32.2869 3.14609 31.2135 2.8179 29.797 2.8179H26.776V10.7623V13.3086V21.6944H23.8877V0.305556Z"
        fill="currentColor"
      />
      <path
        d="M51.6672 0.305556H48.945L40.5126 21.6944H43.6664L46.7539 13.2747H53.6259L56.813 21.6944H60L51.6672 0.305556ZM53.6259 13.2747H46.7539L48.1814 9.57407C48.5577 8.55556 48.9118 7.60494 49.2438 6.72222C49.5757 5.83951 49.8967 4.92284 50.2065 3.97222H50.2729C50.5828 4.92284 50.8926 5.85082 51.2025 6.75617C51.5123 7.66152 51.8554 8.60082 52.2316 9.57407L53.6259 13.2747Z"
        fill="currentColor"
      />
      <path
        d="M52.0722 16.1827C52.0722 17.1593 51.2981 17.9509 50.3431 17.9509C49.3882 17.9509 48.6141 17.1593 48.6141 16.1827C48.6141 15.2061 49.3882 14.4144 50.3431 14.4144C51.2981 14.4144 52.0722 15.2061 52.0722 16.1827Z"
        fill="currentColor"
      />
    </svg>
  );
};
