import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useFloating, offset, flip, size } from '@floating-ui/react';

interface ActiveTopicFlyoutProps {
  topic: {
    id: string;
    name: string;
    activeChildren: {
      name: string;
    }[];
  };
}

const ActiveTopicFlyout: React.FC<ActiveTopicFlyoutProps> = ({ topic }) => {
  const { refs, floatingStyles, update } = useFloating({
    placement: 'bottom',
    middleware: [
      offset(10),
      flip(),
      size({
        apply({ availableWidth, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
          });
        },
      }),
    ],
  });
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      key={topic.id}
      className={`filter ${topic.activeChildren.length > 0 ? 'filter--sub' : ''}`}
    >
      {topic.name}
      {topic.activeChildren.length > 0 && (
        <div
          className="filter_sub"
          ref={refs.setReference}
          onMouseEnter={() => {
            setIsHovered(true);
            update();
          }}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div>{topic.activeChildren.length}</div>
          {isHovered &&
            createPortal(
              <div
                className="filter_sub_floating"
                ref={refs.setFloating}
                style={{
                  ...floatingStyles,
                  zIndex: 1000,
                  padding: '.75rem',
                  borderRadius: '.75rem',
                  width: 'fit-content',
                  maxWidth: 'calc(100vw - 6rem)',
                  WebkitBackdropFilter: 'blur(20px)',
                  backdropFilter: 'blur(20px)',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: 'var(--type-body-inverse)',
                }}
              >
                {topic.activeChildren.map((t: { name: string }, i: number) => (
                  <div
                    key={'l2-topic-' + i}
                    style={{
                      margin: '.5rem 0',
                      fontSize: '.75rem',
                    }}
                  >
                    {t.name}
                  </div>
                ))}
              </div>,
              document.body
            )}
        </div>
      )}
    </div>
  );
};

export default ActiveTopicFlyout;
